* {
  padding: unset;
  margin: unset;
}

:root {
  --theme-color: #0066ff;
  --secondary-color: #1450ec;
  --default-blue: #0049d7;
  --scrollbarBG: #cfd8dc;
  --thumbBG: #283593;
  --buttonBg: #1956f6;
  --new-buttonBg: #002890;
  --success-blue: #06f;
  --filter-bg-color: #0067de;
  --saphire-blue: #001b50;
  --light-gray: #e8eaed;
  --black-color: #000000;
  --thin-black-color: #323232;
  --invailid-color: #e60404;
  --reject-red: #f05050;
  --light-invailid: #f94859;
  --dark-reject-red: #d62727;
  --box-border-color: #e8e7e7;
  --mid-gray-color: #e9ebf2;
  --offWhite: #fafdff;
  --light-blue: #f5f8fd;
  --tangerin: #ffa800;
  --warn-orange: #ff902b;
  --theme-warning: #ffc107;
  --brightYellow: #fdcc01;
  --border-success-green: #c8ffd7;
  --light-success-green-bg: #f5fff0;
  --success-green-color: #44a860;
  --theme-success: #198754;
  --checked-green: #00934c;
  --success-green: #18b557;
  --theme-green-color: #20a154;
  --approve-green: #36b37e;
  --common-white: #ffffff;
}

body {
  font-family: "Roboto", sans-serif !important;
}

body ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
body ::-webkit-scrollbar-track {
  background: #f7f7fe;
}

/* Handle */
body ::-webkit-scrollbar-thumb {
  background: #b2b0bf;
  border-radius: 10px;
}

/* Handle on hover */
body ::-webkit-scrollbar-thumb:hover {
  background: #9c99aa;
}

#_mainBody {
  overflow: hidden !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:focus {
  box-shadow: none !important;
}

a {
  cursor: pointer;
}

.input_drop_error {
  color: var(--invailid-color);
  font-size: 13px !important;
  margin-left: 10px !important;
  margin-top: 3px !important;
  font-weight: 500;
}

.anime-btn {
  position: relative;
  overflow: hidden;
}

.anime-btn:after {
  background: #ffffff80;
  border-radius: 100%;
  content: "";
  height: 5px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: scale(1) translate(-50%);
  transform-origin: 50% 50%;
  width: 5px;
}

.anime-btn:not(:active):after {
  animation: ripple 1s ease-out;
}

@keyframes ripple {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  20% {
    opacity: 1;
    transform: scale(25);
  }

  to {
    opacity: 0;
    transform: scale(40);
  }
}

table {
  width: 100%;
}

/* Table View 1 */

.anvData_table.viewOne {
  width: 100%;
  height: calc(100vh - 155px);
}

.gridRoot {
  height: calc(100vh - 256px);
  overflow: auto;
}

input.search {
  height: 36px;
  padding: 0 8px 0 40px;
  background: #ededed url(../img/search-icon.svg) no-repeat scroll 10px 10px;
  background-size: 20px;
  width: 300px;
  border: none;
  border-radius: 8px;
}

input.search:focus {
  outline: 1px solid silver;
}

.filterOpen_btn {
  padding: 1px 5px;
  border-radius: 5px;
  background-color: #f7f8ff;
  border: 1px solid #819dff;
  color: #1976d2;
}

.filterOpen_btn svg {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: #1976d2;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.tabCst_btn {
  border: none;
  background: transparent;
  display: flex;
  gap: 4px;
  position: relative;
  align-items: center;
  /* max-width: 112px;
  width: 100%; */
  border-radius: 4px;
  padding: 0 10px;
}

.tabCst_btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 4px 4px 0px 0px;
  background-color: transparent;
}

.tabCst_btn._btnActive:after {
  background-color: var(--success-blue);
}

.tabCst_btn._btnActive span {
}

.tabCst_btn span {
  color: var(--black-color);
  opacity: 0.7;
  font-size: 14px;
  font-weight: 500;
}

._counts {
  color: #fff !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  width: auto;
  min-width: 18px;
  padding: 3px 3px 0;
  height: 18px;
  opacity: 1 !important;
  display: inline-flex;
  justify-content: center;
  border-radius: 3px;
  background: var(--light-invailid);
  align-items: center;
}

.add_nFilter_bx {
  display: flex;
  gap: 5px;
}

._addCs_btn {
  border-radius: 4px;
  border: 1px solid var(--light-gray);
  background: var(--success-blue);
  color: var(--common-white);
  font-size: 14px;
  font-weight: 400;
  padding: 2px 12px;
  min-height: 30px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

._selectorDropdown .dropdown-toggle {
  border: 1px dashed #e4e4e7;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  min-height: 36px;
}

._selectorDropdown .dropdown-toggle:hover {
  background-color: #f4f4f5;
  color: #1b1b1b;
}

._selectorDropdown .dropdown-toggle::after {
  display: none;
}

._selectorDropdown .dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem transparent;
}

/* Table View 1 */

.menuAnimate {
  animation-duration: 0.2s;
  -webkit-animation-duration: 0.2s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-name: zoomOut;
}

@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(40px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(40px);
  }

  0% {
    opacity: 0;
    transform: scale(0.8) translateY(40px);
  }
}

@-webkit-keyframes zoomOut {
  0% {
    -webkit-opacity: 0;
    transform: scale(0.8) translateY(40px);
  }

  100% {
    -webkit-opacity: 1;
    transform: scale(1) translateY(40px);
  }

  0% {
    -webkit-opacity: 0;
    transform: scale(0.8) translateY(40px);
  }
}

.btn.dropdown-toggle:focus {
  box-shadow: none;
}

/* Dashboard */

.mainContained_sec {
  display: flex;
  /* height: 100vh;
  overflow: auto; */
}

.stickyHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  border-bottom: 1px solid #dedede;
  background-color: var(--common-white);
  padding: 5px 0px 5px 12px;
  position: sticky;
  z-index: 99;
  top: 0;
}

.sideBar {
  width: 201px;
  height: 100vh;
  position: sticky;
  top: 0px;
  transition: all 0.25s linear;
  z-index: 999;
}

.anvToggled .sideBar {
  width: 53px;
}

._logoInx {
  min-height: 55px;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: all 0.25s linear;
}

._logoInx img {
  width: 100%;
  height: 24px;
  object-fit: cover;
  object-position: left;
  transition: all 0.25s linear;
}

.anvToggled ._logoInx {
  padding-left: 7px;
  position: sticky;
  top: 0;
  background-color: var(--common-white);
  z-index: 9;
}

.anvToggled ._logoInx img {
  width: 40px;
}

._rightSide_bx {
  width: calc(100% - 201px);
  transition: all 0.25s linear;
  height: 100vh;
  overflow: auto;
}

.anvToggled ._rightSide_bx {
  width: calc(100% - 53px);
}

.menuBx_otr {
  background: rgb(15 16 16);
  width: 100%;
  height: calc(100% - 55px);
  padding: 12px 0;
  overflow: auto;
}

.anvToggled .menuBx_otr::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.anvToggled .menuBx_otr {
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  scrollbar-width: none;
  /* Firefox */
}

._hamToggle,
._logIn_user {
  background: transparent;
  border: 0;
}

._crtComp_modBx2 {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

._rSide_navLs {
  display: flex;
  align-items: center;
}

._compList_modBtn {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 17px;
}

._compList_modBtn span {
  color: var(--black-color);
  opacity: 0.8;
  font-weight: 400;
  font-size: 14px;
}

._vertDivider {
  width: 1px;
  height: 26px;
  opacity: 0.2;
  background: var(--black-color);
}

._rsl_fx {
  display: flex;
  align-items: center;
}

._notifyBx {
  margin: 0 20px 0 16px;
  position: relative;
}

._notifyBx a {
  text-decoration: none;
  display: block;
}

._rSide_drp {
  text-align: right;
}

._rSide_drp .signDropdown:hover .dropdownContent {
  right: -3px;
}

._notifyBx span {
  display: block;
  border-radius: 50%;
  background: #23b7e5;
  width: 14px;
  height: 14.69px;
  color: var(--common-white);
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  top: -7px;
  right: -7px;
}

._helpDsk {
  margin-right: 18px;
}

._settingBx {
  margin-right: 22px;
}

._userLd_btn .dropdown-menu {
  border: none;
  padding: 11px 0 0;
  background: transparent;
  min-width: 395px;
  right: 2px;
  top: -6px;
}

._usrDetail_bx {
  width: 100%;
  background: #f9f9fb;
  box-shadow: 0px 0px 15.819px rgb(0 0 0 / 10%);
}

._userLd_btn ._logIn_user {
  padding-right: 12px;
}

._logIn_user img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

._usrDetail_head {
  padding: 18px 18px 12px;
  display: flex;
  gap: 10px;
  position: relative;
  align-items: flex-end;
}

._usrDetail_ins h4 {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  color: var(--black-color);
  opacity: 0.8;
}

._usrDetail_ins p {
  margin: 0;
  font-size: 13px;
  color: var(--black-color);
  line-height: 15px;
  opacity: 0.5;
}

._closeDrop {
  width: 14px;
  height: 15px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  top: 20px;
  background: transparent;
  border: none;
}

._closeDrop svg {
  fill: #ff3f49;
}

._usrDetail_btm {
  padding: 12px 0px 12px;
  border-top: 1px solid #ebeaf2;
  margin: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._usrDetail_btm a {
  font-size: 14px;
  text-decoration: none;
}

a._usrCh_btn {
  color: var(--black-color);
  opacity: 0.8;
}

._usrClose_btn {
  color: #ff3f49;
  font-size: 14px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 4px;
}

._usrClose_btn svg {
  font-size: 15px;
}

.contentHead {
  border: 1px solid var(--light-gray);
  min-height: 54px;
  padding: 0 15px 0 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  background: var(--common-white);
  z-index: 10;
  top: 55px;
}

.contentHead h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color);
  opacity: 0.8;
  /* padding: 0px 10px 10px; */
  position: relative;
}

/* .contentHead h4:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 4px 4px 0px 0px;
  background: var(--success-blue);
} */

._simpList {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

li._clk_li a._clk_a {
  display: flex;
  height: 100%;
  min-height: 35px;
  align-items: center;
  color: var(--common-white);
  padding: 0 12px 0 12px;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.25s linear;
  border-radius: 4px;
}

.anvToggled li._clk_li a._clk_a {
  padding: 0 5px;
  gap: 0;
  justify-content: center;
  width: 100%;
}

.anvToggled ._clk_li {
  display: flex;
  align-items: center;
}

.anvToggled ._clk_li:last-child,
.anvToggled ._clk_li:nth-last-child(3),
.anvToggled ._clk_li:nth-last-child(4) {
  align-items: end;
}

.anvToggled ._clk_li:last-child:hover .accordion-collapse {
  /* transform: translate(53px, -40px); */
}

li._clk_li a._clk_a:hover,
._clk_li .accordion-button.collapsed:hover {
  background: #11223f;
}

li._clk_li a._clk_a.active {
  /* background-color: #00153c; */
  background-color: var(--warn-orange);
}

li._clk_li a._clk_a span {
  transition: all 0.25s linear;
  width: calc(100% - 30px);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.anvToggled li._clk_li a._clk_a span {
  width: 0;
  overflow: hidden;
}

ul.submenuUl {
  list-style: none;
  padding-left: 0em;
}

ul.submenuUl li a {
  display: block;
  color: var(--common-white);
  padding: 5px 0px 5px 42px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  width: 100%;
}

ul.submenuUl li a.active {
  background-color: var(--warn-orange);
  border-radius: 20px !important;
  color: #fff !important;
  margin: 8px 8px 8px 27px;
  padding: 5px 15px;
  width: auto;
}

.anvToggled ul.submenuUl li a.active {
  margin: 4px 4px 0px 4px;
}

._clk_li .accordion-button {
  background-color: #00153c;
  /* background-color: var(--secondary-color); */
  min-height: 35px;
  align-items: center;
  color: var(--common-white);
  padding: 0 12px 0 12px;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.25s linear;
  border-radius: 4px;
}

._clk_li.anvAccordian_active .accordion-button {
  background-color: var(--warn-orange) !important;
}

._clk_li .accordion-button:focus {
  box-shadow: none;
}

._clk_li .accordion-button.collapsed {
  background-color: transparent;
}

._clk_li.anvAccordian_active .accordion-button.collapsed {
  background-color: var(--warn-orange);
}

._clk_li .accordion-button::after {
  display: none;
}

._clk_li .accordion-button ._clk_chevron {
  transition: all 0.25s linear;
  position: absolute;
  right: 12px;
}

._clk_li .accordion-button:not(.collapsed) ._clk_chevron {
  transform: rotate(-90deg);
}

._clk_li .accordion-button span {
  transition: all 0.25s linear;
  width: calc(100% - 30px);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.anvToggled ._clk_chevron {
  display: none;
}

.anvToggled ._clk_li .accordion-button span {
  width: 0;
  overflow: hidden;
}

.anvToggled ._clk_li .accordion-button {
  justify-content: center;
  padding: 0 5px;
  gap: 0;
}

.anvToggled ._clk_li .accordion-button:before {
  content: "";
  position: absolute;
  /* top: -10px; */
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid var(--common-white);
  right: 0;
  opacity: 0;
  transition: all 0.23s linear;
}

.anvToggled ._clk_li:hover .accordion-button:before {
  opacity: 1;
}

.anvToggled ._clk_li .accordion-collapse {
  /* display: none; */
  /* transform: translate(-100%, 0px); */
  position: absolute;
  left: -150px;
  background: #000d24;
  transition: all 0.23s linear;
  opacity: 0;
  width: max-content;
  z-index: -1;
  border-radius: 5px;
  height: 0px;
  overflow: hidden;
}

.anvToggled ._clk_li .accordion-collapse.collapsing {
  height: auto;
  overflow: visible;
  transition: all 0s;
}

.anvToggled ._clk_li .accordion-collapse.collapse:not(.show) {
  display: block;
}

.anvToggled ul.submenuUl {
  padding-left: 0;
}

.anvToggled ._clk_li:hover .accordion-collapse {
  opacity: 1;
  /* transform: translate(53px, 0px); */
  height: auto;
  left: 53px;
}

._clsk_head {
  display: none;
  color: var(--common-white);
  font-size: 14px;
  margin: 0;
  padding: 8px 10px;
  border-bottom: 1px solid #ffffff5e;
}

.anvToggled ._clsk_head {
  display: block;
}

.anvToggled ._clk_li .accordion-body {
  padding: 5px 0px;
}

.anvToggled ul.submenuUl li a {
  font-size: 13px;
  padding: 5px 18px;
}

/* .anvToggled ._modD_flex {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: -1;
} */

/* .anvToggled li._clk_li:nth-child(7) ._modD_flex {
  align-items: end;
  top: 0;
  bottom: 0;
  max-height: 550px;
  height: auto;
} */

/* .anvToggled li._clk_li:nth-child(8) ._modD_flex {
  position: relative;
  align-items: center;
  top: auto;
  bottom: auto;
  max-height: 300px;
  height: auto;
} */

/* .anvToggled li._clk_li:nth-child(9) ._modD_flex {
  align-items: end;
  top: 0;
  bottom: 0;
  max-height: 550px;
  height: auto;
}

.anvToggled li._clk_li.accordion-item:last-child ._modD_flex {
  align-items: end;
  top: 0;
  bottom: 0;
  max-height: 600px;
  height: auto;
} */

.anvIcon_dashboard,
.anvIcon_lr,
.anvIcon_trip,
.anvIcon_accounting,
.anvIcon_account,
.anvIcon_setting,
.anvIcon_plan,
.anvIcon_report,
.anvIcon_quickupload,
.anvIcon_marketLoad,
.anvIcon_invitations,
.anvIcon_master,
.anvIcon_paymentApproval,
.anvIcon_invoicing,
.anvIcon_bids {
  /* -webkit-mask-size: contain;
  mask-size: contain; */
  -webkit-mask-repeat: no-repeat;
  background-color: var(--common-white);
  width: 21px;
  height: 22px;
  mask-size: contain;
}

.anvIcon_bids {
  mask-image: url(../img/Bid.svg);
  -webkit-mask-image: url(../img/Bid.svg);
}

.anvIcon_dashboard {
  mask-image: url(../img/Dashboard.svg);
  -webkit-mask-image: url(../img/Dashboard.svg);
}

.anvIcon_marketLoad {
  mask-image: url(../img/Market_Load.svg);
  -webkit-mask-image: url(../img/Market_Load.svg);
}

.anvIcon_invitations {
  mask-image: url(../img/My_Connection.svg);
  -webkit-mask-image: url(../img/My_Connection.svg);
}

.anvIcon_master {
  mask-image: url(../img/Master.svg);
  -webkit-mask-image: url(../img/Master.svg);
  mask-size: auto;
}

.anvIcon_paymentApproval {
  mask-image: url(../img/Payment_Approval.svg);
  -webkit-mask-image: url(../img/Payment_Approval.svg);
}

.anvIcon_invoicing {
  mask-image: url(../img/invoicing.svg);
  -webkit-mask-image: url(../img/invoicing.svg);
}

.anvIcon_lr {
  mask-image: url(../img/LR.svg);
  -webkit-mask-image: url(../img/LR.svg);
}

.anvIcon_trip {
  mask-image: url(../img/Truck.svg);
  -webkit-mask-image: url(../img/Truck.svg);
}

.anvIcon_accounting {
  mask-image: url(../img/accounting_icon.svg);
  -webkit-mask-image: url(../img/accounting_icon.svg);
}

.anvIcon_report {
  mask-image: url(../img/Report.svg);
  -webkit-mask-image: url(../img/Report.svg);
}

.anvIcon_quickupload {
  mask-image: url(../img/Quick_Upload.svg);
  -webkit-mask-image: url(../img/Quick_Upload.svg);
}

.anvIcon_setting {
  mask-image: url(../img/Setting.svg);
  -webkit-mask-image: url(../img/Setting.svg);
}

.anvIcon_account {
  mask-image: url(../img/Setting.svg);
  -webkit-mask-image: url(../img/Setting.svg);
}

.anvIcon_plan {
  mask-image: url(../img/planIcon.svg);
  -webkit-mask-image: url(../img/planIcon.svg);
}

.adminlist .sideContent {
  background-color: var(--common-white);
  margin-left: auto;
  min-height: 100vh;
  padding: 0 1.5em 1.5em;
  width: 400px;
  z-index: 99;
}

.upperSc_head {
  align-items: flex-end;
  border-bottom: 1px solid #ececf9;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
  padding-top: 0.5em;
}

.manageBox {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  gap: 2em;
  padding-right: 5px;
}

.upperSc_head h2 {
  color: var(--thin-black-color);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}

.settingsLink button.ofAdd_compBtn {
  background-color: var(--secondary-color);
  border-radius: 3px;
  color: var(--common-white);
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  transition: all 0.3s;
  border: 0;
}

.crossBtn {
  align-items: center;
  background: #e9ebf6;
  border-radius: 50px;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
}

.adminlist .btn-close {
  align-items: center;
  background-image: none;
  display: flex;
  height: 20px;
  justify-content: center;
  opacity: 0.8;
  padding: 0;
  width: 100%;
}

.msc_inputContain {
  margin-top: 1em;
  position: relative;
}

.msc_inputContain input {
  border: 2px solid #ececf9;
  border-radius: 4px;
  font-size: 0.8125rem;
  height: 3em;
  padding: 5px 10px;
  width: 100%;
}

.msc_inputContain input:focus {
  outline: 2px solid var(--theme-color);
  border-color: transparent;
}

.orgList_box {
  margin-top: 0.6em;
}

.orgList_box ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  overflow: auto;
  height: calc(100vh - 171px);
}

.orgList_box ul::-webkit-scrollbar {
  width: 11px;
}

.orgList_box ul {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.orgList_box ul::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.orgList_box ul::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.orgList_box ul li button.orgList_btn {
  align-items: center;
  background: #fff !important;
  border-left: 3px solid #22b378 !important;
  border: 1px solid #ececf9;
  border-radius: 4px;
  color: #2e3151;
  display: flex;
  font-size: 0.8125rem;
  margin: 2px 0 10px;
  padding: 11px;
  text-align: left;
  width: 100%;
}

.companyAndselectIcon {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.roleAndIcon {
  display: flex;
}

span.organization-thumbnail {
  align-items: center;
  border: 1px dashed #ececf9;
  display: inline-flex;
  height: 34px;
  margin-right: 1em;
  width: 34px;
}

.organization-icon {
  fill: currentColor;
  color: #9696b7 !important;
  margin: 8px;
}

.selected-org-icon {
  fill: currentColor;
  background: #22b378;
  height: 23px;
  padding: 2px 5px 5px;
  width: 23px;
}

.adminlist .btn-close svg {
  fill: black;
}

._rSideBtn {
  min-height: 41px;
  display: flex;
  align-items: center;
}

._listbtn {
  /* min-height: 41px; */
  display: flex;
  align-items: center;
}

.contentHead ._addComm_btn {
  font-weight: 500;
  font-size: 12px;
  min-height: 28px;
  padding: 3px 15px;
}

._addComm_btn {
  background-color: var(--warn-orange);
  border: none;
  min-height: 34px;
  color: var(--common-white);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 16px;
  border-radius: 4px;
}

._addComm_btn:disabled,
._stepSuccessBtn:disabled {
  background-color: var(--light-gray);
  color: #000000b5;
}

._addNewComm_btn {
  background-color: var(--new-buttonBg);
  border: none;
  min-height: 34px;
  color: var(--common-white);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 16px;
  cursor: pointer;
  border-radius: 4px;
}

._addNewComm_btn_disabled {
  display: none;
  background-color: #e8eaed;
  border: none;
  min-height: 34px;
  color: #000000b5;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 16px;
  cursor: pointer;
  border-radius: 4px;
}

._cancel_btn {
  background-color: var(--light-gray);
  border: none;
  min-height: 34px;
  color: var(--black-color);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 16px;
  border-radius: 4px;
}

.reset_btn_container {
  display: flex;
  flex: 1;
  justify-content: end;
  align-items: center;
}

._reset_btn {
  background-color: transparent;
  border: none;
  min-height: 34px;
  color: var(--black-color);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 16px;
  border-radius: 4px;
  text-decoration: underline;
}

._rlsInputs_bx {
  max-width: 370px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 2em;
}

.simpleInput,
.simpleTextarea {
  border: 1px solid #d3d3d3;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2.754px;
  width: 100%;
}

.simpleInput:hover,
.simpleTextarea:hover {
  border-color: var(--theme-color);
}

.simpleInput:focus,
.simpleTextarea:focus {
  box-shadow: none;
  border-color: transparent;
  outline: 2px solid var(--theme-color);
}

._addRl_bx {
  border: 1px solid var(--light-gray);
  background: var(--common-white);
  border-radius: 4px;
}

._addRlHead {
  font-size: 14px;
  color: var(--black-color);
  font-weight: 400;
  padding: 10px 14px;
  background: #f9fdff;
  border-bottom: 1px solid var(--light-gray);
  border-radius: 4px 4px 0px 0px;
}

._addContainer {
  padding: 11px 12px;
  height: calc(100vh - 165px);
  overflow: auto;
}

._addContainer2 {
  height: calc(100vh - 156px);
  overflow: auto;
}

._shrtY_height {
  height: calc(100vh - 212px);
}

._addRl_tableBx {
  padding: 26px 22px 26px;
}

.addRl_inputOtr {
  max-width: 370px;
  width: 100%;
}

._otrRl_table {
  max-width: 780px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

._addRl_headCheck {
  border: 1px solid var(--light-gray);
  border-bottom: 0;
  background: #f9fdff;
  padding: 11px 20px 3px 22px;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 0;
}

/* ._addRl_headCheck input {
  width: 22px;
  transform: scale(1.5) translate(-4px, 0px);
} */

input._checkTbl {
  width: 22px;
  transform: scale(1.5) translate(1px, 0px);
}

._addRl_headCheck label.anvLabel {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
}

._addRl_checkList .anvLable input {
  width: 22px;
  transform: scale(1.5) translate(-4px, 0px);
}

._mainRl_table ._addRl_checkList:last-child {
  border-radius: 0 0 4px 4px;
}

p.anvLabel {
  color: #070707;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

._mainRl_table table {
  width: 100%;
}

._mainRl_table table thead tr {
  background: #f9fdff;
}

._mainRl_table table th,
._mainRl_table table td {
  font-size: 14px;
  padding: 10px;
  font-weight: 400;
}

._mainRl_table table th:first-child,
._mainRl_table table td:first-child {
  padding-left: 28px;
  width: 24%;
}

._mainRl_table table tr {
  border: 1px solid var(--light-gray);
}

._mainRl_table .form-check-input {
  width: 20px;
  height: 20px;
  float: none;
}

._addRl_headCheck .form-check-input {
  margin-right: 10px;
  position: relative;
  bottom: 2px;
}

._allUrs_bx {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

._addRl_checkList {
  border: 1px solid var(--light-gray);
  padding: 14px 16px 18px 22px;
  /* border-radius: 0 0 4px 4px; */
  display: flex;
  flex-direction: column;
  gap: 16px;
}

._brxFlex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

._dLass {
  min-width: 180px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

._addRl_inrCheck .form-check-input {
  margin-right: 7px;
  position: relative;
  bottom: 3px;
}

._addRl_checkList .anvLable {
  color: #313131;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.bottomFix {
  position: sticky;
  bottom: 0;
  border-top: 1px solid var(--light-gray);
  padding: 10px 21px;
  background: var(--common-white);
  display: flex;
  gap: 10px;
  z-index: 9;
}

._permisionBx_ls {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

._headPermission {
  color: #070707;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

._cstNav_tabFix {
  position: sticky;
  top: 109px;
  z-index: 9;
}

ul._cstNav_tabUl {
  list-style: none;
  padding: 0 20px;
  gap: 18px;
  margin: 0;
  display: flex;
  min-height: 42px;
  border-bottom: 1px solid var(--light-gray);
  background-color: var(--common-white);
}

ul._cstNav_tabUl li._cstNavLi {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
}

ul._cstNav_tabUl li._cstNavLi::after {
  position: absolute;
  content: "";
  background-color: transparent;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: 0;
  border-radius: 4px 4px 0px 0px;
}

ul._cstNav_tabUl li._cstNavLi span {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}

ul._cstNav_tabUl li._cstNavLi a {
  text-decoration: none;
}

ul._cstNav_tabUl li._cstNavLi._activeNav span {
  opacity: 1;
}

ul._cstNav_tabUl li._cstNavLi._activeNav::after {
  background-color: var(--theme-color);
}

._ptyDetail_bx {
  padding: 23px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.inrPrt_bxs {
  border-radius: 4px;
  border: 1px solid var(--light-gray);
  background: var(--common-white);
  overflow: hidden;
}

._inrPrt_head {
  color: var(--black-color);
  background-color: #f9fdff;
  border-bottom: 1px solid var(--light-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  min-height: 44px;
  display: flex;
  align-items: center;
  padding-left: 22px;
}

._inrPrt_flx {
  min-height: 199px;
  display: flex;
  padding: 26px 22px;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 25px 12px;
}

._inrPrt_flx2 {
  display: flex;
  padding-bottom: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px 12px;
}

._divForth {
  flex: 0 0 calc(25% - 9px);
}

._maxWid-650 {
  max-width: 650px;
}

._min_h_auto {
  min-height: auto;
}

.form-check.form-switch .form-check-input {
  position: relative;
  cursor: pointer;
  left: 0;
}

.form-check.form-switch .form-check-label {
  padding: 0;
}

.form-check.form-switch .form-check-label::before,
.form-check.form-switch .form-check-label::after {
  display: none;
}

._rangeDate_bx {
  padding: 15px 26px;
  border-bottom: 1px solid var(--light-gray);
}

._ledgerOtr {
  height: calc(100vh - 232px);
  overflow: auto;
}

.ledger_details_main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  padding: 26px 15px 16px;
  width: 100%;
}

.ledger_details_div {
  border: 1px solid var(--light-gray);
  display: flex;
  min-height: 280px;
  justify-content: space-between;
}

.ledger_address {
  max-width: 225px;
  color: var(--black-color);
  font-size: 12px;
  font-weight: 400;
  padding: 43px;
}

.ledger_address,
.ledger_expense {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ledger_expense {
  max-width: 350px;
  width: 100%;
  padding: 15px;
}

.ledger_company {
  color: var(--success-blue);
  font-size: 12px;
  font-weight: 500;
}

.expense_date {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.expence_title {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 500;
}

.expense_date span {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 500;
}

.tableForTripdetails table {
  border-radius: 4px;
  border-right: none;
}

.tableForTripdetails thead {
  border-bottom: none !important;
  font-size: 12px;
  font-weight: 500;
}

.tableForTripdetails table thead tr th {
  border-right: none;
  border-bottom: none;
}

.tableForTripdetails table tr td {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 400;
  border-bottom: transparent;
}

._addComm_btn._builtyPrintBtn {
  display: flex;
  align-items: center;
  gap: 4px;
}

._headTr th {
  background: #f1f1f1;
}

.ledger_table_div {
  border: 1px solid var(--light-gray);
  min-height: 305px;
}

/* .tabMin_hgt {
  height: 304px;
} */

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.tabFix {
  margin-bottom: 0;
}

.container_table {
  display: flex;
  flex-direction: column;
  /* font-family: Inter, sans-serif; */
  justify-content: center;
  padding: 0;
}

table {
  border-bottom: 1px solid var(--light-gray);
  border-spacing: 0;
}

.tabFix thead.table_head_style {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
}

.table_head_style th:first-child {
  width: auto;
}

.dFsx {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.table_head_style th {
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding: 0.5rem 0.5rem 0.5rem 10px;
}

.tabFix tr td .dFsx2 {
  padding: 0.5rem 0.5rem 0.5rem 10px;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
}

.dFsx2 span {
  color: #616161;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.dFsx2 span.voucher {
  color: var(--success-blue);
}

.background tr th {
  background-color: #474747;
  border-right: none;
  color: var(--common-white);
}

.fw-400 {
  font-weight: 400;
}

.min-w-auto {
  min-width: auto;
}

.gap-x-4 {
  gap: 22px;
}

.gap-x-5 {
  gap: 28px;
}

._inpSelect {
  position: relative;
  width: 150px;
}

._selectInner_bx {
  position: absolute;
  right: 5px;
  top: 9px;
  z-index: 9;
  border-radius: 2px;
  border: 1px solid #dbe5f3;
  background: var(--offWhite);
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.28px;
  min-height: 26px;
  padding: 0 3px;
}

.form-check-input:checked ~ label {
  opacity: 1;
}

.form-check-input ~ label {
  /* opacity: 0.5; */
  cursor: pointer;
}

._addSingleBrr {
  border-radius: 3px 3px 0px 0px;
  border: 1px solid #d9e6ef;
  background: var(--offWhite);
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 1em;
  min-height: 71px;
}

.llt_dash_row_one {
  display: flex;
  gap: 18px;
}

.llt_dashboard_bx {
  background: var(--light-blue);
  padding: 22px 30px 18px 34px;
  height: calc(100vh - 101px);
  overflow: auto;
}

.llt_dash_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.llt_dash_branch_date {
  display: flex;
  align-items: center;
  gap: 10px;
}

.llt_dash_branch_date select {
  border-radius: 2px;
  border: 0.565px solid #c9c9c9;
  background: var(--common-white);
  width: 170px;
  height: 34px;
  flex-shrink: 0;
  padding: 8px 10px;
  color: var(--thin-black-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.llt_dash_lr_r1_last {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
}

.llt_dash_branch_date div {
  /* width: 125px;
  height: 34px;
  flex-shrink: 0;
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  border: 1px solid #c9c9bc;
  background: var(--common-white);
  color: var(--thin-black-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; */
}

.llt_dash_heading h5 {
  color: var(--thin-black-color);
  /* font-family: Inter; */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.llt_dash_lr_stats {
  border-radius: 4px;
  border: 1px solid var(--box-border-color);
  background: var(--common-white);
  padding: 15px 15px 20px;
  min-height: 100%;
}

.llt_dash_lr_r1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  border-bottom: 1px solid var(--box-border-color);
}

.llt_dash_lr_r1:last-child {
  border-bottom: transparent;
}

.llt_dash_lr_sts_one {
  display: flex;
  gap: 8px;
  align-items: center;
}

.llt_dash_span_one,
.llt_dash_span_two,
.llt_dash_span_three,
.llt_dash_span_four,
.llt_dash_span_five,
.llt_dash_span_six,
.llt_dash_span_seven,
.llt_dash_span_eight,
.llt_dash_span_cancel,
.llt_dash_span_nine {
  border-radius: 82px;
  width: 9px;
  height: 9px;
}

.llt_dash_span_one {
  background: var(--success-blue);
}

.llt_dash_span_two {
  background: #ffde57;
}

.llt_dash_span_three {
  background: #ff9a6b;
}

.llt_dash_span_four {
  background: #59d99d;
}

.llt_dash_span_five {
  background: #1f8e49;
}

.llt_dash_span_six {
  background: #f00;
}

.llt_dash_btn_one,
.llt_dash_btn_two,
.llt_dash_btn_three,
.llt_dash_btn_four,
.llt_dash_btn_five,
.llt_dash_btn_six {
  border-radius: 3px;
  border: none;
  padding: 5px 12px;
  text-align: center;
  /* font-family: Inter; */
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
}

.llt_dash_btn_one {
  color: var(--success-blue);
  background: #e5f0ff;
}

.llt_dash_btn_two {
  color: #ffde57;
  background: #fff8db;
}

.llt_dash_btn_three {
  color: #ff9a6b;
  background: #ffeae0;
}

.llt_dash_btn_four {
  color: #00c86a;
  background: #defeef;
}

.llt_dash_btn_five {
  color: #1f8e49;
  background: #d6f6e2;
}

.llt_dash_btn_six {
  color: #f00;
  background: #ffe3e3;
}

.dash_row {
  row-gap: 22px;
}

.llt_dash_lr_sts_one p {
  color: var(--thin-black-color);
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.llt_dash_lr_stats h6 {
  color: var(--thin-black-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px;
}

/* .llt_dash_dispatch img{
  width: 250px;
  height: 250px;
} */

.llt_dash_dispatch {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* gap: 30px; */
  justify-content: center;
}

.llt_dash_dispatch_btns {
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  justify-content: center;
  padding: 5px;
}

.llt_dash_span_seven {
  background: #36b37e;
}

.llt_dash_span_eight {
  background: #ffa800;
}

.llt_dash_span_nine {
  background: #23b7e5;
}

.llt_dash_span_cancel {
  background: #F05050;
}

.llt_dash_pod {
  border-radius: 4px;
  border: 1px solid var(--box-border-color);
  background: var(--common-white);
  padding: 15px;
  height: 100%;
}

.llt_dash_pod_data {
  display: flex;
  justify-content: space-between;
  padding: 25px 10px;
  max-width: 400px;
  margin: 0 auto;
}

.llt_dash_pod_d1 h5 {
  color: var(--thin-black-color);
  text-align: center;
  /* font-family: Inter; */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.llt_dash_pod_d1 p {
  color: var(--thin-black-color);
  text-align: center;
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
  margin-bottom: 0;
}

.llt_dash_pod_d12 h5 {
  color: #f64040;
  text-align: center;
  /* font-family: Inter; */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.llt_dash_pod_d12 p {
  color: var(--thin-black-color);
  text-align: center;
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
  margin-bottom: 0;
}

.llt_dash_pod_vehicle {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.llt_dash_vehicle_d1 p {
  color: var(--thin-black-color);
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding-top: 5px;
}

.llt_dash_vehicle_d1 h6 {
  color: var(--thin-black-color);
  /* font-family: Inter; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.llt_dash_payment {
  border-radius: 4px;
  border: 1px solid var(--box-border-color);
  background: var(--common-white);
  padding: 15px;
  height: 100%;
}

.llt_dash_payment_bx_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  width: calc(33.33% - 40px);
}

.llt_dash_payment_bx_1 h6 {
  color: var(--thin-black-color);
  text-align: center;
  /* font-family: Inter; */
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.llt_dash_payment_bx_1 p {
  color: var(--thin-black-color);
  text-align: center;
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
  margin-bottom: 0;
}

.llt_dash_payment_bx {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 60px;

  /* row-gap: 10px; */
  /* column-gap: 80px */
}

.llt_dash_eway_bx {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 20px;
  gap: 12px;
}

.llt_dash_eway_bx_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 15px;
  border-radius: 5px;
  width: calc(33.33% - 9px);
  border: 1px solid var(--success-blue);
  width: calc(33.33% - 9px);
}

.llt_dasheway_bill_1 {
  border: 1px solid var(--success-blue);
  background: #f0f6ff;
}

.llt_dash_eway_bx_1 h6 {
  color: var(--success-blue);
}

.llt_dasheway_bill_1 h6 {
  color: var(--success-blue);
}

.llt_dasheway_bill_2 {
  border: 1px solid #ff9a6b;
  background: #fff6f2;
}

.llt_dasheway_bill_2 h6 {
  color: #ff9a6b;
}

.llt_dasheway_bill_3 {
  border: 1px solid #f64040;
  background: #fff0f0;
}

.llt_dasheway_bill_3 h6 {
  color: #f64040;
}

.llt_dasheway_bill_4 {
  border: 1px solid #ffd837;
  background: #fffbec;
}

.llt_dasheway_bill_4 h6 {
  color: #ffcd00;
}

.llt_dasheway_bill_5 {
  border: 1px solid #c9c9c9;
  background: #f3f3f3;
}

.llt_dasheway_bill_5 h6 {
  color: #a5a5a5;
}

.llt_dash_eway_bx_1 h6 {
  text-align: center;
  /* font-family: Inter; */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.llt_dash_eway_bill_1 {
  border: 1px solid var(--success-blue);
  background: #f0f6ff;
}

.llt_dash_eway_bill_1 h6 {
  color: var(--success-blue);
}

.llt_dash_eway_bill_2 {
  border: 1px solid #ff9a6b;
  background: #fff6f2;
}

.llt_dash_eway_bill_2 h6 {
  color: #ff9a6b;
}

.llt_dash_eway_bill_3 {
  border: 1px solid #f64040;
  background: #fff0f0;
}

.llt_dash_eway_bill_3 h6 {
  color: #f64040;
}

.llt_dash_eway_bill_4 {
  border: 1px solid #ffd837;
  background: #fffbec;
}

.llt_dash_eway_bill_4 h6 {
  color: #ffcd00;
}

.llt_dash_eway_bill_5 {
  border: 1px solid #c9c9c9;
  background: #f3f3f3;
}

.llt_dash_eway_bill_5 h6 {
  color: #a5a5a5;
}

/* .llt_dash_eway_bx_1 p { */

.llt_dash_eway_bx_1 p {
  color: var(--thin-black-color);
  text-align: center;
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.llt_dash_Account_bx_1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.llt_dash_Account_bx {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
}

p {
  margin: 0;
}

.llt_dash_row_two {
  margin-top: 18px;
}

.llt_dash_Account_bx_1 a {
  color: var(--success-blue);
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.llt_dash_Account_bx_1 p {
  color: var(--thin-black-color);
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.llt_dash_Account_bx_1 a {
  text-decoration: none;
}

/* Dashboard */

/* Vehicle Dashboard */

._vehiCle_dashUl {
  padding: 0 25px !important;
  background: var(--light-blue);
  padding-top: 10px !important;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  list-style: none;
  margin: 0;
  display: flex;
}

._vehiCle_dashUl li {
  width: 8%;
  text-align: center;
  margin-bottom: -1px;
}

.VehicleDashboard_width li.active {
  background: #efefef !important;
}

._vehiCle_dashUl li a {
  font-weight: 500;
  font-size: 12px;
  padding: 10px 0px 15px;
  display: block;
  text-decoration: none;
  color: var(--thin-black-color);
}

._vehiCle_dashUl li a:hover {
  background: #dce5e7;
}

._vehiCle_dashUl li.active a {
  border-left: 1px solid #dedede !important;
  border-top: 1px solid #dedede !important;
  border-right: 1px solid #dedede !important;
  border-bottom: 0px !important;
  background-color: var(--common-white);
}

._vehiCle_dashUl li a span {
  font-size: 25px;
  display: block;
  text-align: center;
  color: var(--black-color);
  font-weight: bold;
  line-height: 25px;
  position: relative;
}

._vehiCle_dashUl li a span::after {
  position: absolute;
  right: -2px;
  height: 39px;
  width: 1px;
  content: "";
  border-right: 1px solid #ccc;
}

._vehiCle_dashUl li.active a span::after {
  display: none;
}

.tab_vehicleDashbord {
  padding: 25px;
}

.tab_vehicleDashbord ul {
  display: flex;
  gap: 12px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.tab_vehicleDashbord li > a {
  text-decoration: none;
  width: 140px;
  text-align: center;
  border-radius: 50px;
  padding: 2px 10px;
  font-size: 15px;
  font-weight: 400;
  display: block;
}

.tab_vehicleDashbord li > a._btnEdv {
  border: 1px solid #1eb290;
  color: #1eb290;
}

.tab_vehicleDashbord li._tbv_active > a._btnEdv {
  background: #1eb290;
  color: var(--common-white);
  border: 1px solid #1eb290;
}

._btnEdv:hover {
  background: rgb(30, 178, 144);
  color: var(--common-white) !important;
}

.tab_vehicleDashbord li > a._btnEred {
  border: 1px solid #fd7873;
  color: #fd7873;
}

.tab_vehicleDashbord li._tbv_active > a._btnEred {
  background: #fd7873;
  border: 1px solid #fd7873;
  color: var(--common-white);
}

._btnEred:hover {
  background: #fd7873;
  color: #fff !important;
}

.tab_vehicleDashbord li > a._btnEgray {
  border: 1px solid #747474;
  color: #747474;
}

.tab_vehicleDashbord li._tbv_active > a._btnEgray {
  background: #747474;
  border: 1px solid #747474 !important;
  color: #fff !important;
}

._btnEgray:hover {
  background: #747474;
  color: #fff !important;
}

.tab_vehicleDashbord li > a._btnE_idel {
  border: 1px solid var(--theme-warning);
  color: var(--theme-warning);
}

.tab_vehicleDashbord li._tbv_active > a._btnE_idel {
  background: var(--theme-warning);
  border: 1px solid var(--theme-warning) !important;
  color: #fff !important;
}

._btnE_idel:hover {
  background: var(--theme-warning);
  color: #fff !important;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.reactour__searchBar {
  display: flex;
}

._vehCle_search {
  width: 100%;
  height: 100%;
  padding: 5px 12px 5px 30px;
  background: #fff;
  max-width: 100%;
  font-size: 13.4px;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  min-height: 36px;
}

._vehCle_search:hover {
  border-color: var(--secondary-color);
}

._vehCle_search:focus {
  box-shadow: none;
  border-color: transparent;
  outline: 2px solid var(--secondary-color);
}

._serIcon {
  margin-left: 8px;
  margin-top: 6px;
  color: #9e9e9e;
  left: 0;
}

.toggle-btn-wrapper {
  border-radius: 5px;
  border: 0px solid rgb(255, 255, 255);
  background-color: rgb(245, 245, 245);
  color: rgb(21, 27, 30);
  font-weight: 500;
  font-size: 0.8rem;
  cursor: pointer;
}

.active-toggle-btn {
  border-radius: 5px;
  box-shadow: #6a6a6a80 0px 1px 6px 0px;
  border: 0px solid #dddddd;
  background-color: var(--common-white);
}

.toggle-btn {
  padding: 4px 8px;
}

.pre-scrollable {
  height: 100%;
  min-height: calc(100vh - 296px);
  max-height: calc(100vh - 296px);
  overflow: auto;
}

._realTime_bx {
  margin-top: 0.5rem;
  -webkit-box-shadow: 2px 0 6px 0 #0000000d;
  box-shadow: 2px 0 6px 0 #0000000d;
  border: 1px solid #e3e8ed;
  border-radius: 3px;
  padding: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  cursor: pointer;
  font-size: 14px;
}

.ant-tag {
  font-size: 11px;
  border-radius: 20px;
  margin-left: 5px;
  line-height: 22px;
  display: inline-block;
  padding: 0 8px;
}

.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}

._idle ._movingImg {
  display: none;
}

._moving ._idelImg {
  display: none;
}

._idle ._tmtTxt {
  color: var(--theme-warning);
}

._moving ._tmtTxt {
  color: var(--theme-success);
}

.text-md {
  font-size: 16px;
}

._bm_contIcon {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background: #999;
  border-radius: 50%;
}

._bm_contIcon svg {
  padding: 2px;
}

._bm_contIcon svg path {
  fill: var(--common-white);
}

.control-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
}

.row-control-button {
  width: auto;
  height: auto;
  padding: 3px 12px;
  border-radius: 11.5px;
  background-color: #f5f5f5;
  font-stretch: normal;
  font-style: normal;
  color: #151b1e;
  font-size: 90%;
  font-weight: 400;
  margin-top: 2px;
}

.row-control-button:hover {
  color: #fff;
  background-color: #b2b0b0;
}

.realtime-row-more-info {
  color: #0078d4;
  border: none;
  background: transparent;
  margin-left: 0.25rem !important;
}

/* Vehicle Dashboard */

/* 404 Page */

.page_404 {
  background: var(--common-white);
  font-family: Arvo, serif;
  height: 100vh;
}

.containerError_page {
  align-items: center;
  display: grid;
  height: 100vh;
  transition: all 0.5s;
}

.four_zero_four_bg {
  background-image: url(../img/notFound.gif);
  background-position: 50%;
  background-repeat: no-repeat;
  height: 400px;
}

.contant_box_404 {
  margin-top: -50px;
}

.four_zero_four_bg h1,
.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  background: var(--secondary-color);
  color: #fff !important;
  display: inline-block;
  text-decoration: none;
  margin: 20px 0;
  padding: 10px 20px;
}

/* 404 Page */

/* Loader Page*/

.loaderBg {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.loaderBg img {
  max-width: 180px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

/* Loader Page*/

/* Add Party Off Canvas */

.offcanvas.offcanvas-end.common_offCanvas.xxlOffcanvas {
  width: calc(100% - 70px);
  max-width: 1230px;
  /* max-width: calc(100% - 203px); */
}

.offcanvas.offcanvas-end.common_offCanvas.mdOffcanvas {
  width: calc(100% - 70px);
  max-width: 610px;
  /* max-width: calc(100% - 203px); */
}

.offcanvas.offcanvas-end.common_offCanvas {
  width: 490px;
  border-left: var(--light-gray);
}

.common_offCanvas .offcanvas-title {
  color: var(--black-color);
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.8;
}

.common_offCanvas .offcanvas-header {
  border-bottom: 1px solid var(--light-gray);
}

.common_offCanvas .offcanvas-footer {
  padding: 10px 22px;
  display: flex;
  gap: 10px;
  border-top: 1px solid var(--light-gray);
}

.common_offCanvas .offcanvas-body {
  background-color: #fff;
  padding: 0;
  height: calc(100vh - 109px);
  overflow: auto;
}

.common_offCanvas .offcanvas-body::-webkit-scrollbar {
  width: 11px;
}

.common_offCanvas .offcanvas-body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.common_offCanvas .offcanvas-body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.common_offCanvas .offcanvas-body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 1px solid var(--scrollbarBG);
}

.offBack_drop {
  background: #000000b3;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

.pr_procssBx {
  background-color: var(--common-white);
  /* border-bottom: 1px solid var(--light-gray); */
  /* margin-bottom: 6px; */
  padding: 20px 19px 0px 18px;
}

.pr_procssBx_grid {
  grid-row-gap: 20px;
}

.flxInr {
  grid-row-gap: 21px;
}

._divFix_wid {
  flex: 0 0 225px;
}

.pr_procssBx_grid,
.flxInr {
  display: flex;
  grid-column-gap: 16px;
  flex-wrap: wrap;
}

.singleDiv {
  flex: 0 0 100%;
}

.halfDiv {
  flex: 0 0 calc(50% - 8px);
}

.anvSwitch_frm.form-check {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 12px;
  align-items: center;
}

.anvSwitch_frm .form-check-input {
  cursor: pointer !important;
  width: 36px !important;
  height: 18px !important;
  display: block !important;
  position: relative !important;
  left: 0 !important;
  float: none !important;
  margin: 0 !important;
}

.anvSwitch_frm .form-check-input:focus {
  border-color: #dee2e6;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
}

.anvSwitch_frm .form-check-input:checked {
  background-color: #1976d233;
  border-color: #1976d233;
  background-image: url(../img/checkEllipse.svg);
}

.anvSwitch_frm.form-check label {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  cursor: pointer;
}

.checkBx_lv {
  display: flex;
  padding-top: 16px;
  gap: 13px;
}

.addPr_flBx .cstNew_tabs {
  border-bottom: 1.5px solid #dbe5ff;
  padding: 0 16px;
  background: var(--common-white);
}

.addPr_flBx .cstNew_tabs li.nav-item .nav-link {
  margin: 0;
  border: none;
  min-height: 45px;
  padding: 5px 12px;
  position: relative;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.5; */
}

.addPr_flBx .cstNew_tabs li.nav-item .nav-link.active {
  color: var(--tangerin);
  font-weight: 500;
  opacity: 1;
}

.addPr_flBx .cstNew_tabs li.nav-item .nav-link::after {
  content: "";
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 3px;
  border-radius: 4px 4px 0px 0px;
  left: 0;
  bottom: 0;
}

.addPr_flBx .cstNew_tabs li.nav-item .nav-link.active::after {
  background-color: var(--tangerin);
}

.h4Head {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.dropdown_select ._cstDropdown__control {
  min-height: 45px;
  border-color: var(--box-border-color);
  border-radius: 3px;
}

.dropdown_select ._cstDropdown__control:hover {
  border-color: var(--secondary-color);
}

.dropdown_select ._cstDropdown__control._cstDropdown__control--is-focused {
  box-shadow: none;
  outline: 2px solid var(--secondary-color) !important;
}

.dropdown_select
  ._cstDropdown__control._cstDropdown__control--is-focused:hover {
  border-color: transparent;
}

.dropdown_select
  ._cstDropdown__control._cstDropdown__control--is-focused
  ._cstDropdown__placeholder {
  color: var(--secondary-color);
}

.dropdown_select ._cstDropdown__control ._cstDropdown__value-container {
  padding: 0 13px;
}

._usrDetails {
  max-width: 895px;
  width: 100%;
}

._tierDivs {
  flex: 0 0 calc(33.33% - 11px);
}

.anvCustom_floatInput .form-control,
.anvCustom_floatInput .form-select {
  border-color: var(--box-border-color);
  font-size: 15px;
  border-radius: 3px !important;
  margin-bottom: 0 !important;
}

.anvCustom_floatInput .form-control:disabled,
.anvCustom_floatInput .form-select:disabled {
  background-color: #fcfcfc;
}

.anvCustom_floatInput.anvInputError .form-control,
.anvCustom_floatInput.anvInputError .form-control:hover {
  border-color: var(--invailid-color) !important;
}

.anvCustom_floatInput input.form-control,
.anvCustom_floatInput .form-select {
  min-height: 60px !important;
  height: 60px !important;
  padding: 5px 15px !important;
}

.anvCustom_floatInput .form-control:disabled:hover,
.anvCustom_floatInput .form-select:disabled:hover {
  border-color: var(--box-border-color) !important;
}

.anvCustom_floatInput input.form-control:disabled ~ label,
.anvCustom_floatInput .form-select:disabled ~ label {
  background-color: transparent;
}

.anvCustom_floatInput textarea.form-control {
  padding: 11px 15px 5px !important;
}

.anvCustom_floatInput .form-control:hover,
.anvCustom_floatInput .form-select:hover {
  border-color: var(--secondary-color) !important;
}

.anvCustom_floatInput .form-control:focus,
.anvCustom_floatInput .form-select:focus {
  box-shadow: none;
  border-color: transparent;
  /* border-width: 2px; */
  outline: 2px solid var(--secondary-color);
}

.anvCustom_floatInput.anvInputError .form-control:focus,
.anvCustom_floatInput.anvInputError .form-select:focus {
  outline-color: var(--invailid-color);
}

.anvCustom_floatInput .form-control:focus:hover,
.anvCustom_floatInput .form-select:focus:hover {
  border-color: transparent !important;
}

::placeholder {
  color: #353434 !important;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  color: #353434 !important;
}

.anvCustom_floatInput .form-control:disabled::placeholder,
.anvCustom_floatInput .form-select:disabled::placeholder {
  color: #fff !important;
  opacity: 1;
}

.anvCustom_floatInput .form-control:disabled::-ms-input-placeholder,
.anvCustom_floatInput .form-select:disabled::-ms-input-placeholder {
  color: #fff !important;
  opacity: 1;
}

.anvCustom_floatInput.form-floating > .form-control-plaintext ~ label,
.anvCustom_floatInput.form-floating > .form-select-plaintext ~ label,
.anvCustom_floatInput.form-floating > .form-control:focus ~ label,
.anvCustom_floatInput.form-floating > .form-select:focus ~ label,
.anvCustom_floatInput.form-floating
  > .form-control:not(:placeholder-shown)
  ~ label,
.anvCustom_floatInput.form-floating
  > .form-select:not(:placeholder-shown)
  ~ label,
.anvCustom_floatInput.form-floating > .form-select ~ label {
  /* color: rgba(var(--bs-body-color-rgb),.65); */
  /* transform: scale(.85) translateY(-0.5rem) translateX(0.15rem); */
  transform: scale(1) translateY(0) translateX(0);
  top: -8px;
  left: 8px;
  width: auto;
}

.anvCustom_floatInput.form-floating > .form-control-plaintext ~ label span,
.anvCustom_floatInput.form-floating > .form-select-plaintext ~ label span,
.anvCustom_floatInput.form-floating
  > .form-control:not(:placeholder-shown)
  ~ label
  span,
.anvCustom_floatInput.form-floating
  > .form-select:not(:placeholder-shown)
  ~ label
  span,
.anvCustom_floatInput.form-floating > .form-select ~ label span {
  font-size: 12px;
  font-weight: 400;
  color: var(--black-color);
  opacity: 0.7;
}

.anvCustom_floatInput.form-floating > .form-control:focus ~ label span,
.anvCustom_floatInput.form-floating > .form-select:focus ~ label span {
  color: var(--secondary-color);
  font-size: 12px;
  opacity: 1;
  font-weight: 500;
}

.anvCustom_floatInput.form-floating.anvInputError
  > .form-control:focus
  ~ label
  span,
.anvCustom_floatInput.form-floating.anvInputError
  > .form-select:focus
  ~ label
  span {
  color: var(--invailid-color);
}

.anvCustom_floatInput.form-floating > .form-control:focus ~ label,
.anvCustom_floatInput.form-floating > .form-select:focus ~ label,
.anvCustom_floatInput.form-floating
  > .form-control:not(:placeholder-shown)
  ~ label,
.anvCustom_floatInput.form-floating
  > .form-select:not(:placeholder-shown)
  ~ label,
.anvCustom_floatInput.form-floating > .form-select ~ label {
  opacity: 1;
}

.anvCustom_floatInput.form-floating label {
  padding: 1px 2px;
  height: auto;
  margin: 0;
  transition: all 0.16s;
  transform: scale(1) translateY(0) translateX(0);
  top: 20px;
  left: 13px;
  background: var(--common-white);
  width: calc(100% - 16px);
  border: 0;
  z-index: 5;
}

.anvCustom_floatInput.form-floating label span {
  color: #475f7b;
  line-height: 14px;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  gap: 1px;
}

.requiredLable {
  color: #f00 !important;
  opacity: 0.7;
}

/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  overflow: hidden;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}

/* checkbox aspect */
[type="checkbox"] + label:before,
[type="checkbox"] + label:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

/* Unchecked styles */
[type="checkbox"]:not(:checked) + label:before {
  top: 0px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--secondary-color);
}

[type="checkbox"]:not(:checked) + label:after {
  top: 0px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--secondary-color);
  z-index: 0;
}

/* Checked styles */
[type="checkbox"]:checked + label:before {
  top: 2px;
  left: 3px;
  width: 6px;
  height: 12px;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 2px solid var(--common-white);
  border-bottom: 2px solid var(--common-white);
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%;
  z-index: 9;
}

[type="checkbox"]:checked + label:after {
  top: 0px;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--secondary-color);
  background: var(--secondary-color);
  z-index: 0;
}

/* disabled checkbox */
/* [type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  border-right-color: #444;
  border-bottom-color: #444;
}

[type="checkbox"]:disabled:not(:checked) + label::after,
[type="checkbox"]:disabled:checked + label::after {
  border-color: #444;
} */
[type="checkbox"]:disabled + label {
  color: #555;
  cursor: auto;
}

[type="checkbox"]:disabled:not(:checked) + label:hover:before {
  border-color: var(--secondary-color);
}

/* Add Party Off Canvas */

.gts_clasFrs {
  font-size: 10px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

._ptyDetail_bx_ajn {
  padding: 3px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

._lrdMax {
  max-width: 580px;
  width: 100%;
}

._sameWid {
  max-width: 111px;
  width: 100%;
}

._csgAdd_bx {
  display: flex;
  grid-column-gap: 16px;
  flex-wrap: wrap;
  margin-top: 8px;
}

._csgAdd_bx .halfDiv {
  display: flex;
  flex-direction: column;
}

._unit_detailBxs {
  border-radius: 4px;
  border: 1px solid var(--light-gray);
  background: var(--common-white);
  padding: 10px 16px;
  min-height: 120px;
  height: 100%;
}

._unit_detailBxs h6 {
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin: 0;
}

._unit_detailBxs p {
  color: #494949;
  font-size: 10px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
}

.addConsignee_lBtn {
  width: 100%;
  height: 100%;
  min-height: 120px;
  border-radius: 4px;
  border: 1px dashed var(--secondary-color);
  background: var(--common-white);
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.28px;
}

._materialTable table {
  border-color: transparent;
}

._materialTable table thead tr {
  border: 1px solid #dbe5f3;
}

._materialTable table thead tr th {
  background-color: var(--offWhite);
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  padding: 8px 10px;
}

._materialTable table thead tr th:first-child {
  padding-left: 25px;
}

._materialTable table thead tr th:last-child {
  padding-left: 0px;
}

._materialTable table thead tr th:nth-child(3) {
  width: 10%;
}

._materialTable table thead tr th:nth-last-child(4),
._materialTable table thead tr th:nth-last-child(3) {
  width: 10%;
}

._materialTable table tbody tr td {
  background-color: var(--common-white);
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  padding: 12px 10px 8px;
  vertical-align: baseline;
}

._materialTable table tbody tr {
  border: 1px solid #e1e1e1;
}

._materialTable table tbody tr:last-child {
  border: 1px solid #dbe5f3;
}

._materialTable table tbody tr:last-child td {
  background-color: var(--offWhite);
}

._materialTable table tbody tr td:first-child {
  padding-left: 25px;
}

._dltBtn {
  background-color: transparent;
  border: none;
}

._materialTable table tbody tr td:last-child {
  padding-left: 0px;
}

._materialTable .mapList_views {
  max-height: 160px;
}

.addMst_btn {
  color: var(--theme-color);
  background: transparent;
  font-weight: 500;
  line-height: 27px;
  border: none;
}

.mt-n1 {
  margin-top: -0.5em;
}

/* ************************ Attachement Css Start  *********************** */

.attachFilelabel {
  width: 100%;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;
  min-height: 44px;
}

.attachFilelabel i,
.attachFilelabel .title {
  color: var(--secondary-color);
  transition: 200ms color;
  font-size: 14px;
}

/* .attachFilelabel:hover {
  border: 1px solid var(--secondary-color);
}

.attachFilelabel:hover i,
.attachFilelabel:hover .title {
  color: var(--secondary-color);
} */

#file-input {
  display: none;
}

ul._attachedFile_bx {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

ul._attachedFile_bx li {
  width: 73px;
  height: 73px;
  position: relative;
}

ul._attachedFile_bx li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: 1px solid var(--secondary-color);
  border-radius: 3.9px;
}

ul._attachedFile_bx li img._pdfIcon {
  object-fit: none;
}

._delAttach {
  position: absolute;
  display: flex;
  top: -6px;
  right: -6px;
  cursor: pointer;
}

._withOutAttach_bx {
  margin: 0;
}

span.rm_rf_remove_Cls {
  color: var(--invailid-color);
  cursor: pointer;
}

._blsSummary {
  height: calc(100vh - 224px);
  overflow: auto;
}

/* *********************** Attachement Css End ************************ */

/* Trip Page */

._midCont_body {
  border-radius: 3px;
  border: 1px solid var(--light-gray);
  background: var(--common-white);
  padding: 20px 10px;
  /* overflow: hidden; */
}

._crtDisp_listBx {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bg-\[\#FBF9FB\] {
  background-color: #fbf9fb;
}

._fleX_inds {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
}

._fleX_inds ._divForth {
  flex: 0 0 calc(25% - 15px);
}

._fleX_inds ._divFiv {
  flex: 0 0 calc(50% - 15px);
}

._fleX_inds ._divsix {
  flex: 0 0 calc(33% - 15px);
}

._disPatch_heads {
  color: var(--saphire-blue);
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 16px;
}

._btmRow_cts {
  gap: 20px;
  margin-top: 25px;
  padding-bottom: 2em;
  position: relative;
  z-index: 1;
}

._verLine_cts {
  position: relative;
}

._verLine_cts:after {
  background: #d9e6ef;
  content: "";
  height: 100%;
  left: 2.05em;
  min-height: calc(100vh - 688px);
  position: absolute;
  top: 0;
  width: 1px;
  z-index: 0;
}

._addRouts_flex {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  align-items: center;
}

._cityInput_bx {
  flex: 0 0 354px;
  /* position: relative;
  z-index: 1; */
}

._addDel_bx {
  display: flex;
  gap: 12px;
  align-items: center;
}

._pickN_dropBtn {
  color: var(--buttonBg);
  font-size: 12.5px;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

._deleteBtn {
  color: #c31f1f;
  font-size: 12.5px;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
}

.accordionTable_view {
  margin-bottom: 22px;
  width: 100%;
}

.accordionTable_view .accordion-item {
  border: none;
  margin-bottom: 12px;
}

.accordionTable_view .accordion-header {
  align-items: center;
  display: flex;
  position: relative;
}

.PickNdrop_placeBx {
  background: var(--offWhite);
  border: 1px solid #d9e6ef;
  border-radius: 4px 4px 0 0;
  padding: 12px 21px;
  position: relative;
  width: 100%;
  z-index: 1;
}

._inrPd {
  align-items: center;
  display: flex;
  gap: 10px;
}

._inrPd h4 {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  margin: 0;
}

.accordionTable_view .accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0;
  width: auto;
}

.cstCevs {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.accordion-button:not(.collapsed) .cstCevs {
  transform: rotate(-180deg);
}

._spaceTble {
  padding-left: 2.5em;
}

.accordionTable_view .accordion-item:last-child {
  margin-bottom: 0;
}

.accordionTable_view .accordion-body {
  padding: 0;
  overflow: auto;
}

.accordionTable_view .ctm_table.cretTrip_table.table-hover {
  margin-bottom: 0 !important;
}

.accordionTable_view .accordion-button::after {
  opacity: 0;
  display: none;
}

._pdTransport_dl {
  align-items: center;
  display: flex;
  gap: 15px;
}

._pdTransport_dl h4 {
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  margin: 0;
}

._pdTransport_dl span {
  color: var(--thin-black-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  opacity: 0.7;
}

.closeTble_btn {
  align-items: center;
  background: transparent;
  display: flex;
  height: 31px;
  padding: 0;
  position: absolute;
  border: none;
  right: -34px;
}

.accordiTr {
  background: var(--offWhite);
  border: 1px solid #d9e6ef;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

.accordiTr th {
  background: transparent !important;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  padding: 7px 10px;
  vertical-align: middle;
  width: auto;
}

.accordiTr th:first-child {
  width: 10% !important;
  padding: 7px 10px 7px 1.6em;
}

.ctmTable tbody tr {
  border: 1px solid var(--box-border-color);
}

.ctmTable td {
  font-size: 14px;
  line-height: 20px;
  padding: 14px 10px;
  vertical-align: middle;
}

.ctmTable td:first-child {
  padding: 14px 10px 14px 1.6em;
}

.ctmTable tbody tr {
  border: 1px solid var(--box-border-color);
}

.sameSpan {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.fw-500 {
  font-weight: 500;
}

._invoicSpan {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: var(--secondary-color);
}

.lrDeatilsTrip {
  display: flex;
  gap: 9px;
  flex-wrap: wrap;
}

li._clk_li {
  border: transparent;
  background: transparent;
  padding: 0 5px;
  /* width: calc(100% - 8px);
  margin: 0 auto; */
}

li._clk_li .accordion-body {
  padding: 0;
}

._clk_li .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: #091d41a1;
  color: var(--common-white);
}

._avPointer {
  cursor: pointer;
}

/* Trip Page */

label.fix_width {
  width: 140px;
}

/* Lr Details */

.lrNavigate .nav-link {
  border: none !important;
  margin-bottom: 0 !important;
  min-height: 44px;
  position: relative;
}

.lrNavigate .nav-link::after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 8px 8px 0px 0px;
  background-color: transparent;
}

.lrNavigate .nav-link.active::after {
  background-color: var(--buttonBg);
}

.lrNavigate .nav-tabs {
  border-color: var(--light-gray);
  gap: 15px;
}

.lrNavigate .nav-link span {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
}

.lrNavigate .nav-link.active span {
  opacity: 1;
}

.common_offCanvas #nav-lrDetail .offcanvas-body {
  height: calc(100vh - 154px);
}

.common_offCanvas #nav-lrStatus .offcanvas-body {
  height: calc(100vh - 100px);
}

.common_offCanvas .offcanvas-footer ._submitOn_modal {
  background-color: var(--buttonBg);
  min-height: 38px;
}

.lrFlow_rls {
  background: var(--common-white);
  border: 1px solid var(--light-gray);
  margin-top: 12px;
}

.podFlow_rls {
  background: var(--common-white);
  border: 1px solid var(--light-gray);
  margin-top: 9px;
}

._inrLr_flow {
  padding: 22px 20px 24px;
}

._inrPod_flow {
  padding: 18px 20px 15px;
}

._inrLr_flow ul {
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  /* gap: 21px; */
}

.stepsBx {
  display: flex;
  gap: 11px;
  position: relative;
  min-height: 42px;
}

.lrsCommon .stepIcon svg {
  position: relative;
  z-index: 1;
  bottom: 4px;
}

.stepInfos {
  padding-bottom: 10px;
}

.stepInfos h4 {
  color: var(--thin-black-color);
  font-size: 13.899px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.278px;
  opacity: 0.9;
  margin: 0;
}

.stepInfos h4 span._stpDateTime {
  display: none;
}

.lrs_active .stepInfos h4 {
  font-weight: 500;
}

.lrs_active .stepInfos h4 span._stpDateTime {
  display: inline-block;
}

._editStep {
  margin-left: 8px;
  border: none;
  background: transparent;
  color: var(--buttonBg);
  font-weight: 500;
  display: none;
}

.lrs_active .stepInfos ._editStep {
  display: inline-block;
}

.lrsCommon .stepIcon svg circle {
  fill: #d9d9d9;
}

.lrsCommon .stepIcon svg path {
  stroke: #d9d9d9;
}

.lrsCommon.lrs_active .stepIcon svg circle {
  fill: var(--secondary-color);
}

.lrsCommon.lrs_active .stepIcon svg path {
  stroke: var(--common-white);
}

.lrsCommon .stepsBx::after {
  content: "";
  position: absolute;
  left: 8px;
  top: 0;
  height: 100%;
  width: 1px;
  background: #d9d9d9;
}

.lrsCommon:last-child .stepsBx::after {
  background: transparent;
}

._transitDetail {
  display: none;
  gap: 5px;
  align-items: center;
  margin-top: 4px;
}

.lrsCommon.lrs_active ._transitDetail {
  display: flex;
}

._stLr_truckNo {
  color: #000;
  font-size: 11.923px;
  font-weight: 500;
  line-height: 22.994px;
  letter-spacing: 0.238px;
}

._stmarket,
._stLr_truckType {
  border-radius: 1.788px;
  font-size: 10.731px;
  font-weight: 500;
  line-height: 10.731px;
  min-height: 18px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
}

._stmarket {
  color: var(--common-white);
  background: var(--tangerin);
}

._stLr_truckType {
  color: rgb(50, 50, 50, 0.7);
  background: #e7e7e7;
}

.lrsCommon:last-child .stepsBx {
  min-height: auto;
}

._stpsBtn_bx {
  min-height: 55px;
  border-top: 1px solid var(--light-gray);
  display: flex;
  align-items: flex-end;
  padding: 9px 18px;
}

._lrdSteps_btn,
._podSteps_btn {
  color: var(--common-white);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  min-height: 35px;
  padding: 5px 12px;
  border-radius: 4px;
  border: none;
  background-color: var(--buttonBg);
}

._lrdSteps_btn {
  min-width: 148px;
}

._podSteps_btn {
  min-width: 120px;
}

._podHead {
  display: flex;
  align-items: center;
  gap: 5px;
}

._pendingStat {
  border-radius: 1.833px;
  background: var(--tangerin);
  color: var(--common-white);
  font-size: 10.999px;
  font-weight: 500;
  line-height: 10.999px;
  min-height: 18px;
  padding: 0 5px;
  display: flex;
  align-items: center;
}

._podHead p {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

._lrInTransit_status,
._lrDelivered_status,
._lrPartyPoint_status,
._lrPending_status,
._lrBillRaised_status,
._lrCanceled_status {
  color: var(--common-white);
  font-size: 11px;
  font-weight: 500;
  padding: 3px 5px;
  line-height: normal;
  border-radius: 9px;
  text-wrap: nowrap;
}

/* _lrPending_status
_lrInTransit_status
_lrPartyPoint_status
_lrDelivered_status
_lrBillRaised_status */

._lrInTransit_status {
  background: #23b7e5;
  box-shadow: 0px 2px 3px 0px #23b7e566;
}

._lrDelivered_status {
  background-color: var(--approve-green);
  box-shadow: 0px 2px 3px 0px #36b37e66;
}

._lrBillRaised_status {
  background: #3652b3;
  box-shadow: 0px 2px 3px 0px #3651b366;
}

._lrPartyPoint_status {
  background: var(--tangerin);
  box-shadow: 0px 2px 3px 0px #ffc53d8a;
}

._lrPending_status {
  background: var(--warn-orange);
  box-shadow: 0px 2px 3px 0px #ff902b66;
}

._lrCanceled_status {
  background: var(--reject-red);
  box-shadow: 0px 2px 3px 0px #ff792b66;
}

.lrd-header .offcanvas-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

._flUpload {
  border-radius: 6.353px;
  border: 1.588px solid #0000001a;
  background: var(--common-white);
  box-shadow: 15.882px 6.353px 42.882px 6.353px #ffffff40;
  width: 57px;
  height: 54px;
}

._flUpload label {
  color: var(--secondary-color);
  font-size: 26.471px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.529px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Lr Details */

/* Add Vehicle */

.addPr_flBx .cstNew_tabs._truckSelection {
  padding: 14px 16px 0;
  border-bottom: none;
  grid-column-gap: 12px;
  grid-row-gap: 5px;
}

._trkHead {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

._truckSelection li.nav-item {
  max-width: 78px;
  width: 100%;
}

.addPr_flBx ._truckSelection li.nav-item .nav-link {
  padding: 8px 0;
  opacity: 1;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  min-height: 73px;
  width: 100%;
  border: 1px solid #dad9d9;
  background: var(--common-white);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.addPr_flBx ._truckSelection li.nav-item .nav-link.active {
  color: var(--common-white);
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
}

.addPr_flBx ._truckSelection li.nav-item .nav-link::after {
  content: none;
}

.addPr_flBx ._truckSelection li.nav-item .nav-link::before {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  content: "";
  height: 37px;
  position: relative;
  /* top: 1em; */
  width: 100%;
}

.addPr_flBx ._truckSelection li.nav-item #Truck::before {
  background-image: url(../img/truck.png);
}

.addPr_flBx ._truckSelection li.nav-item #Truck.active::before {
  background-image: url(../img/truck_white.png);
}

.addPr_flBx ._truckSelection li.nav-item #LCV::before {
  background-image: url(../img/LCV.png);
}

.addPr_flBx ._truckSelection li.nav-item #LCV.active::before {
  background-image: url(../img/LCV_white.png);
}

.addPr_flBx ._truckSelection li.nav-item #Container::before {
  background-image: url(../img/Container.png);
}

.addPr_flBx ._truckSelection li.nav-item #Container.active::before {
  background-image: url(../img/Container_white.png);
}

.addPr_flBx ._truckSelection li.nav-item #Hyva::before {
  background-image: url(../img/Hyda.png);
}

.addPr_flBx ._truckSelection li.nav-item #Hyva.active::before {
  background-image: url(../img/Hyda_white.png);
}

.addPr_flBx ._truckSelection li.nav-item #Trailer::before {
  background-image: url(../img/Trailer.png);
}

.addPr_flBx ._truckSelection li.nav-item #Trailer.active::before {
  background-image: url(../img/Trailer_white.png);
}

.addPr_flBx ._truckSelection li.nav-item #Tanker::before {
  background-image: url(../img/Tanker.png);
}

.addPr_flBx ._truckSelection li.nav-item #Tanker.active::before {
  background-image: url(../img/Tanker_white.png);
}

/* Add Vehicle */

.faTrash_cls {
  margin-top: 10px;
  color: var(--invailid-color);
  cursor: pointer;
}

._relatePos {
  position: relative;
}

/* Trip Detail */

._revenueBx {
  max-width: 845px;
}

#revenuAccordion .accordion-button {
  flex-direction: row-reverse;
  padding: 10px 14px;
}

._revnHead {
  width: 100%;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--saphire-blue);
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.36px;
}

._revenueBx .accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
}

._revenueBx .accordion-button::after {
  border: 1px solid #515253;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-size: 15px;
  background-position: center;
}

._revenueBx .accordion-item:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* ._revenueBx .accordion-item:last-of-type {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
} */

._revenueBx .accordion-body {
  padding: 0;
}

.__frtTab_lets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--saphire-blue);
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.28px;
  min-height: 45px;
  border-top: 1px solid #dee2e6;
  padding: 3px 14px 3px 20px;
  cursor: pointer;
}

.__frtTab_lets:hover {
  background-color: #f1f5fa;
}

.__frtTab_lets span {
  opacity: 0.9;
}

.__lst_Tab_lets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--saphire-blue);
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.28px;
  min-height: 45px;
  border: 1px solid #dee2e6;
  background-color: #f2f9fd;
  border-top: none;
  padding: 3px 14px 3px 20px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.__lst_Tab_lets span {
  opacity: 0.9;
}

.__navTabContents {
  padding: 16px 16px 20px 20px;
  height: calc(100vh - 156px);
  overflow: auto;
}

.anv_tripTab_otr {
  border: 1px solid var(--box-border-color);
  border-radius: 3px;
  padding: 22px 16px 20px 10px;
}

.row.anvRG_gap {
  grid-row-gap: 1em;
}

.anvFrightUpp_tabBx_tp {
  align-items: center;
  background-color: var(--common-white);
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 1em;
}

.anvFrightUpp_tabBx_tp .anvFrightInden_detailBx_tp {
  grid-gap: 15px;
  /* align-items: center; */
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.sprt_dix {
  /* min-width: 190px; */
}

._positiveBal_btn,
._negativeBal_btn {
  color: var(--black-color);
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  /* min-height: 24px; */
  /* border: 1px solid; */
  border-radius: 4.7px;
  /* padding: 3px 5px; */
  margin-top: 3px;
  display: inline-block;
}

._positiveBal_btn {
  background-color: #f6fff5;
  border-color: #129b0f;
}

._positiveBal_btn ._blAmt {
  color: #129b0f;
  margin-left: 3px;
}

._negativeBal_btn {
  /* border-color: var(--dark-reject-red);
  background: #fff5f5; */
}

._negativeBal_btn ._blAmt {
  color: var(--dark-reject-red);
  margin-left: 3px;
}

._addDrv_btn {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  min-height: 24px;
  border-radius: 19px;
  border: 1px solid var(--default-blue);
  background: #f1f6ff;
  padding: 3px 9px;
}

.anvFrightUpp_tabBx_tp .anvFrightInden_detailBx_tp p {
  margin: 0;
  color: var(--saphire-blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.28px;
  opacity: 0.8;
}

.anvFrightUpp_tabBx_tp .anvFrightInden_detailBx_tp h2 {
  margin-bottom: 0em;
  color: var(--saphire-blue);
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.36px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.anvFrightUpp_tabBx_tp .anvFrightInden_detailBx_tp h3 {
  margin-bottom: 0em;
  color: var(--saphire-blue);
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.36px;
  display: flex;
  align-items: center;
  gap: 8px;
}



.anvFrightUpp_tabBx_tp .anvFrightInden_detailBx_tp h2 .tagBxs {
  align-items: center;
  background: #cbddff;
  border: 1px solid #408dfb;
  border-radius: 2.32px;
  color: var(--saphire-blue);
  display: inline-flex;
  font-size: 10px;
  line-height: normal;
  font-weight: 400;
  height: 17px;
  justify-content: center;
  min-width: 41px;
  /* width: 100%; */
}

.tagBxs {
  align-items: center;
  background: #cbddff;
  border: 1px solid #408dfb;
  border-radius: 2.32px;
  color: var(--saphire-blue);
  display: inline-flex;
  font-size: 10px;
  line-height: normal;
  font-weight: 400;
  height: 17px;
  justify-content: center;
  min-width: 41px;
  padding-inline: 3px;
  /* width: 100%; */
}

.anv_freightTable_otr_tpBx {
  grid-column-gap: 1.2%;
  display: flex;
  flex-wrap: wrap;
}

.anv_freightTable_detail_tpBx {
  width: 76%;
}

.container_table {
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  justify-content: center;
  padding: 0;
}

.anv_freightTable_detail_tpBx .anv_fr_detailHead h4 {
  border: 1px solid #d9e6ef;
  /* border-bottom: none; */
  border-radius: 3px 3px 0 0;
  gap: 10px;
  padding: 0.72em 15px 0.72em 15px;
  align-items: center;
  background-color: var(--offWhite);
  color: #000;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  margin: 0;
}

._trpTable_xd .table_head_style tr {
  background-color: var(--offWhite);
  border: 1px solid #d9e6ef;
  box-shadow: 0px 1px 3px 0px #00000014;
}

._trpTable_xd tbody tr {
  border: 1px solid #d9e6ef;
}

._trpTable_xd tbody tr td,
._trpTable_xd tfoot tr td {
  font-size: 14px;
  padding: 0.5rem 0.5rem 0.5rem 10px;
  color: #333;
}

._trpTable_xd tfoot tr {
  background-color: var(--offWhite);
  border: 1px solid #d9e6ef;
}

._muliAddbtns_bx {
  display: flex;
  gap: 9px;
}

.addAble_btn {
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4.75px;
  display: flex;
  gap: 4.75px;
  padding: 2px 9px 2px 2px;
}

._otrIcon_imb {
  align-items: center;
  background: #ddd;
  border-radius: 4.75px;
  color: #000;
  display: flex;
  height: 33px;
  justify-content: center;
  width: 33px;
}

.addFreight {
  background-color: #f6fff5;
  border-color: #129b0f;
}

.addClaim {
  background-color: #f1f6ff;
  border-color: var(--secondary-color);
}

.addFuel {
  background-color: #fff3ec;
  border-color: #e46923;
}

.addFreight ._otrIcon_imb {
  background: #129b0f;
}

.addClaim ._otrIcon_imb {
  background: var(--secondary-color);
}

.addFuel ._otrIcon_imb {
  background: #e46923;
}

._trpTable_xd tfoot ._totalAtm {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.table_head_style th:nth-last-child(2),
.table_head_style th:nth-last-child(2) .dFsx,
._trpTable_xd tbody tr td:nth-last-child(2),
._trpTable_xd tbody tr td:last-child,
._trpTable_xd tfoot tr td:nth-last-child(2) {
  /* text-align: right; */
  /* justify-content: flex-end; */
}

.actionIbtn {
  border: none;
  background-color: transparent;
}

.anvAttach_file {
  color: var(--secondary-color);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

._trpTable_xd tr th:first-child,
._trpTable_xd tr td:first-child {
  padding-left: 20px;
}

.rowGaper4 {
  gap: 16px;
}

.addOthrExp {
  background-color: #f3f7ff;
  border-color: #5070ae;
}

.addOthrExp ._otrIcon_imb {
  background: var(--saphire-blue);
}

.anvFreight_smry_tpBx {
  width: 22.8%;
}

.anvFreight_smrySticky {
  position: sticky;
  top: 0;
}

.anvFreight_smry_tpBx h2 {
  border: 1px solid #d9e6ef;
  border-radius: 3px 3px 0 0;
  justify-content: space-between;
  padding: 4px 20px;
}

.anvFreight_smry_tpBx h2,
.anv_freightTable_detail_tpBx .anv_fr_detailHead h4 {
  align-items: center;
  background-color: var(--offWhite);
  color: #000;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  margin: 0;
}

.anvFreight_smry_tpBx .anvFr_sumPara_flex {
  align-items: center;
  border: 1px solid var(--box-border-color);
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-top: -1px;
  padding: 0.42em 20px;
}

.anvFreight_smry_tpBx .anvFr_sumPara_flex p {
  color: #000;
  margin: 0;
}

.anvFr_sumPara_flex.to_inversTab {
  background-color: var(--offWhite);
  color: #000;
  padding: 0.72em 20px;
}

.anvFr_sumPara_flex.to_inversTab p._balaCe {
  color: var(--default-blue);
}

._trpDetail_bx .addPr_flBx .cstNew_tabs {
  padding: 0 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 109px;
  z-index: 9;
}

/* Trip Detail */

._receivedStat {
  border-radius: 1.833px;
  background: var(--approve-green);
  color: var(--common-white);
  font-size: 10.999px;
  font-weight: 500;
  line-height: 10.999px;
  min-height: 18px;
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.hgt_wdt_avt img {
  width: 100%;
  border-radius: 8.24px;
  height: 100%;
  object-fit: cover;
}

.hgt_wdt_avt {
  width: 74.12px;
  height: 70px;
  border-radius: 8.24px;
}

.flex_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 15px 15px;
  margin-bottom: 7px;
}

.reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid {
  border-radius: 50%;
}

._othrExp_recBx .checkBx_lv {
  padding: 0;
  gap: 24px;
}

._bottomPLine {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
  margin-bottom: 2px;
  opacity: 0.7;
  margin-left: 2px;
}

._anvSwitcherNew.form-check {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 12px;
  align-items: center;
}

._anvSwitcherNew .form-check-input {
  cursor: pointer !important;
  width: 36px !important;
  height: 18px;
  display: block !important;
  position: relative !important;
  left: 0 !important;
  float: none !important;
  margin: 0 !important;
}

._anvSwitcherNew .form-check-input:focus {
  border-color: #dee2e6;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
}

._anvSwitcherNew .form-check-input:checked {
  background-color: #1976d233;
  border-color: #1976d233;
  background-image: url(../img/checkEllipse.svg);
}

._anvSwitcherNew .form-check-label {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  cursor: pointer;
}

._btmSmall_txt {
  color: #000;
  font-size: 12px;
  display: block;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

/* Transactions Page */

._trancMain_bx {
  padding: 22px 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

._trancHead {
  color: var(--black-color);
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
  opacity: 0.8;
}

.branch_inner {
  display: flex;
  padding: 22px 20px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--box-border-color);
  margin-bottom: 16px;
  text-decoration: none;
}

.branch_left {
  display: flex;
  flex-grow: 1;
}

.branch_left h6 {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 5px;
  margin-top: 0px;
  color: #475f7b;
}

.branch_left span {
  font-size: 14px;
  display: block;
  font-weight: 500;
}

._minusRs {
  color: var(--invailid-color);
}

._plusRs {
  color: var(--success-green);
}

.branch_right img {
  width: 28px;
  margin-top: 10px;
}

.cashInHnad_s .col-xl-3 {
  padding: 0 8px;
}

._trnsBr_flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 16px;
  gap: 1em;
  border-bottom: 1px solid #dedede;
}

._rightBrch_filts {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

._envlBtn {
  display: flex;
  align-items: center;
}

._envlBtn {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  font-size: 18px;
}

._leftBrch_filts h4 {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0px;
  color: #475f7b;
}

._brchsBalance {
  display: flex;
  gap: 15px;
}

._brchsBalance span {
  font-size: 14px;
  display: block;
  font-weight: 500;
}

._transactionTabs_ui .nav-tabs {
  border-bottom: none;
}

._transactionTabs_ui .nav-tabs .nav-link {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 500;
  border: none;
  position: relative;
}

._transactionTabs_ui .nav-tabs .nav-link span {
  opacity: 0.7;
}

._transactionTabs_ui .nav-tabs .nav-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 4px 4px 0px 0px;
  background-color: transparent;
}

._transactionTabs_ui .nav-tabs .nav-link.active:after {
  background-color: var(--success-blue);
}

._transactionTable table {
  border-color: transparent;
}

._transactionTable table thead tr {
  border: 1px solid #dbe5f3;
  position: sticky;
  top: -1px;
  box-shadow: 0px 1px 3px 0px #00000014;
  z-index: 1;
}

._minTh_wid {
  min-width: 100px;
  width: 100%;
}

._transactionTable table thead tr th {
  background-color: var(--offWhite);
  color: #475f7b;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  padding: 8px 10px;
}

._transactionTable table thead tr th:first-child {
  padding-left: 25px;
}

._transactionTable table tbody tr td {
  background-color: var(--common-white);
  color: #475f7b;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding: 12px 10px 8px;
  vertical-align: baseline;
}

._transactionTable table tbody tr {
  border: 1px solid #e1e1e1;
}

._transactionTable table tbody tr:last-child {
  border: 1px solid #dbe5f3;
}

._transactionTable table tbody tr td:first-child {
  padding-left: 25px;
}

._transactionTable {
  height: calc(100vh - 156px);
  overflow: auto;
}

._transactionTable::-webkit-scrollbar,
.indentSide_bx::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
._transactionTable::-webkit-scrollbar-track,
.indentSide_bx::-webkit-scrollbar-track {
  background: #f7f7fe;
}

/* Handle */
._transactionTable::-webkit-scrollbar-thumb,
.indentSide_bx::-webkit-scrollbar-thumb {
  background: #b2b0bf;
  border-radius: 10px;
}

/* Handle on hover */
._transactionTable::-webkit-scrollbar-thumb:hover,
.indentSide_bx::-webkit-scrollbar-thumb:hover {
  background: #9c99aa;
}

/* Transactions Page */

/* Invoice */

.contentHead_new {
  border: 1px solid var(--light-gray);
  min-height: 46px;
  padding: 0 15px 0 26px;
  display: flex;
  align-items: center;
  gap: 28px;
  position: sticky;
  background: var(--common-white);
  z-index: 10;
  top: 55px;
}

.contentHead_new h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color);
  opacity: 0.8;
  position: relative;
}

._stepedBxs {
  display: flex;
  align-items: center;
  gap: 20px;
}

._seperateCheckes {
  display: flex;
  align-items: center;
  gap: 8px;
}

._seperateCheckes.activated svg circle {
  fill: var(--theme-green-color);
}

._seperateCheckes.activated svg path {
  stroke: var(--common-white);
}

._seperateCheckes span {
  color: var(--thin-black-color);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  opacity: 0.9;
}

._seperateCheckes.activated span {
  font-weight: 500;
}

._billRaiseBody {
  background: #f3f8ff;
  /* min-height: calc(100vh - 159px); */
}

._billRaiseFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  background: var(--common-white);
  min-height: 58px;
  padding: 0 25px 0 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - 201px);
  transition: all 0.25s linear;
}

.anvToggled ._billRaiseFooter {
  width: calc(100% - 54px);
}

.prevDiscard_bx {
  display: flex;
  gap: 9px;
}

._previousBtn,
._discardBtn {
  min-height: 36px;
  min-width: 90px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: var(--black-color);
}

._previousBtn span,
._discardBtn span {
  opacity: 0.8;
}

._previousBtn {
  background-color: #d1e4f6;
}

._discardBtn {
  background-color: #eaeaea;
}

._stepSuccessBtn {
  background-color: var(--success-blue);
  min-width: 149px;
  min-height: 36px;
  border: none;
  border-radius: 4px;
  color: var(--common-white);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.billDetial_modBxs {
  height: 100%;
  display: flex;
  align-items: center;
  padding-block: 1em;
  min-height: calc(100vh - 159px);
  padding-bottom: 7em;
}

.custom-dropdown-menu.cus-topDrop {
  top: unset;
  bottom: 58px;
}

.billDetail_mdModal {
  max-width: 497px;
  width: 100%;
  margin: 0 auto;
  background: var(--common-white);
}

.billDetail_mdModal .pr_procssBx {
  border-bottom: none;
  margin-bottom: 0;
  padding: 50px 20px 50px 26px;
  border-radius: 7px;
}

._gstChr_div {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

._gstChr_dualChecker {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

._gstChr_dualChecker .form-check-input ~ label {
  opacity: 1;
}

.billDetail_smModal {
  max-width: 453px;
  width: 100%;
  margin: 0 auto;
  background: var(--common-white);
  border-radius: 7px;
}

._invoiceSummary {
  border-radius: 7px;
  padding: 46px 20px 50px;
}

._invoiceSummary h2 {
  color: var(--black-color);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}

._alInvoiceSumry {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

._invsSum_fx {
  display: flex;
  justify-content: space-between;
}

._invsSum_fx p {
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 0;
}

._blackText {
  color: var(--black-color);
}

._boldBlack {
  color: var(--black-color);
  font-weight: 700;
}

.remainBal {
  color: var(--secondary-color);
  font-weight: 700;
}

/* Invoice */

/* Consignor Freight */

._actionBtn {
  background-color: transparent;
  border: none;
}

._cnFr_listsBx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;
}

._flxRight {
  display: flex;
  gap: 11px;
}

._numEdits_btn {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #d9e6ef;
  background: var(--offWhite);
  min-height: 38px;
  min-width: 109px;
  padding-inline: 10px;
}

._numEdits_btn span {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  opacity: 0.9;
}

.anv_freightTable_detail_tpBx .accordionTable_view {
  margin-bottom: 0;
}

._anvfrg_table tfoot tr {
  border: 1px solid var(--box-border-color);
}

._totBold {
  font-weight: 500;
}

/* Consignor Freight */

/* Chart Of Account */

._chrt_mainBg {
  min-height: calc(100vh - 109px);
  background-color: var(--light-blue);
  padding: 16px 20px;
}

._allColapse_bx {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}

.otrCollapse_bx {
  flex: 0 0 270px;
  box-shadow: -1px -1px 20px rgba(0, 0, 0, 0.12),
    -1px -1px 20px rgba(0, 0, 0, 0.03);
}

.tileTitle {
  padding: 9px 20px;
  background: #f7f7fb;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #464646;
  border-bottom: 1px solid #e0e0e0;
}

.tileData {
  background: var(--common-white);
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.tileData a {
  display: flex;
  padding-left: 7px;
  font-size: 13.5px;
  color: #464646 !important;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.tileData a span svg path {
  stroke: #3f51b5;
}

.openCollaps {
  display: flex;
}

.tileData._toggleData .openCollaps {
  display: none;
}

.closeCollaps {
  display: none;
}

.tileData._toggleData .closeCollaps {
  display: flex;
}

.tileData._toggleData ul.subCategory {
  display: block;
}

ul.subCategory {
  margin: 0;
  display: none;
}

.subCategory li span,
.subCategory_item {
  font-size: 13.5px;
  color: #464646;
  margin-bottom: 0;
}

.subCategory li {
  padding: 4px 0;
}

.tileData a span {
  font-size: 20px;
  height: 19px;
}

.subCategory_item {
  padding-left: 18px;
}

.subCategory .li_cont {
  font-size: 13px;
  color: #464646;
  display: flex;
  justify-content: space-between;
  padding-right: 2px;
}

.delete_sub_category_item button {
  background: transparent;
  border: none;
}

.edit_sub_category_item,
.delete_sub_category_item {
  cursor: pointer;
  padding-left: 6px;
}

._smallLab label.anvLable {
  font-size: 12px;
  line-height: 20px;
}

/* Chart Of Account */

/* Trip Detail Page */

._td_rightPt {
  display: flex;
  gap: 18px;
  align-items: center;
  flex-wrap: wrap;
}

._atPr_point {
  background-color: var(--theme-green-color);
  border: none;
  border-radius: 4px;
  color: var(--common-white);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  min-width: 144px;
  min-height: 40px;
}

._td_lrsCommon {
  display: flex;
}

._td_stepsBx {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
}

._td_stepIcon {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}

._td_stepIcon svg {
  flex: 0 0 21px;
}

._td_stepIcon::after,
._td_stepIcon::before {
  position: relative;
  display: block;
  content: "";
  height: 1px;
  background-color: #828282;
}

._td_stepIcon::after {
  width: calc(100% - 10.5px);
  flex: calc(100% - 10.5px);
}

._td_stepIcon::before {
  width: calc(100% - 10.5px);
  flex: calc(100% - 10.5px);
}

._td_stepsBx:first-child ._td_stepIcon::before {
  opacity: 0;
}

._td_stepsBx:last-child ._td_stepIcon::after {
  opacity: 0;
}

._td_stepInfos {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 4px;
  padding: 0 12px;
}

._td_stepInfos p {
  color: var(--thin-black-color);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  opacity: 0.9;
  margin-bottom: 0;
}

._td_stepInfos span {
  color: var(--thin-black-color);
  font-weight: 500;
  font-size: 10px;
  opacity: 0.9;
  line-height: normal;
  letter-spacing: 0.2px;
}

._td_stepsBx_active ._td_stepIcon svg circle {
  fill: var(--theme-green-color);
}

._td_stepsBx_active ._td_stepIcon svg path {
  stroke: var(--common-white);
}

._td_stepsBx_active ._td_stepInfos p {
  font-weight: 500;
}

/* Trip Detail Page */

.attechFile_head {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

/* Indent */

._indentView_flx {
  display: flex;
}

.indentSide_bx {
  position: sticky;
  top: 109px;
  width: 201px;
  height: calc(100vh - 109px);
  border: 1px solid var(--light-gray);
  background: var(--common-white);
  padding: 14px 8px;
  overflow: auto;
}

._indtsLs_flx {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#myIndentTab {
  flex-direction: column;
  border: none;
  margin-top: 6px;
}

#myIndentTab .nav-link {
  width: 100%;
  margin-bottom: 0;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 9px;
}

#myIndentTab .nav-link.active {
  background-color: var(--success-blue);
}

#myIndentTab .nav-link span {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.8;
}

#myIndentTab .nav-link.active span {
  color: var(--common-white);
  opacity: 1;
}

._indtsList h5 {
  color: var(--black-color);
  font-size: 10px;
  opacity: 0.6;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0px;
  padding-left: 10px;
}

._bidsLs_flx {
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

._bidsList {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

._bidsList .form-check-input {
  margin-top: 0;
  width: 14px;
  height: 14px;
}

._bidsList label {
  display: flex !important;
  justify-content: space-between;
  padding-left: 6px !important;
  width: calc(100% - 14px);
}

._bidsList label span {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.8;
}

._bidsList [type="checkbox"]:checked + label:before {
  width: 4px;
  height: 8px;
  border-right: 1px solid var(--common-white);
  border-bottom: 1px solid var(--common-white);
}

._bidsList [type="checkbox"]:checked + label:after,
._bidsList [type="checkbox"]:not(:checked) + label:after,
._bidsList [type="checkbox"]:not(:checked) + label:before {
  width: 15px;
  height: 15px;
}

._indTabl_right {
  width: calc(100% - 201px);
}

._indentTable {
  height: calc(100vh - 109px);
  overflow: auto;
  background: #fafafa;
}

._indentTable table {
  border: 1px solid transparent;
  /* border-collapse: separate; */
}

._indentTable table:first-child thead tr {
  opacity: 1;
  position: sticky;
  top: -1px;
  z-index: 1;
  box-shadow: 0px 1px 4px 0px #0000001a;
}

._indentTable table thead tr {
  opacity: 0;
}

._indentTable table thead tr {
  background-color: #f7f7fe;
}

._indentTable table:first-child thead tr th {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.8;
  text-transform: capitalize;
  padding: 9px 16px;
}

._indentTable table thead tr th {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0px 16px;
  line-height: 8px;
}

._indentTable table tbody tr td {
  padding: 9px 16px;
  font-size: 14px;
  border-top: 1px solid var(--light-gray);
  /* border-bottom: 1px solid var(--light-gray); */
  background-color: var(--common-white);
}

._indentTable._planLTable table tbody tr:nth-last-child(2) td {
  padding: 5px 16px;
}

._indentTable._planLTable
  table
  tbody
  tr:nth-last-child(2)
  td
  .custom-select-container
  input {
  min-height: 33px;
  padding: 4px 28px 4px 12px;
}

._indentTable._planLTable
  table
  tbody
  tr:nth-last-child(2)
  td
  .custom-select-container:after {
  top: 16px;
  right: 15px;
}

._indentTable._planLTable table tbody tr:last-child td {
  padding: 5px 16px;
  background: #fafafa;
}

._indentTable._planLTable tbody.accordion-item:last-child tr:last-child td {
  padding: 0;
  border: none;
}

._indentTable table tbody tr td p {
  margin-bottom: 0;
}

._indentTable._disPatchView table tbody tr:last-child td {
  padding: 5px 16px;
}

._indentTable table tbody tr:first-child td {
  padding: 9px 16px;
}

._indentTable._disPatchView table tbody:last-child tr:last-child td {
  padding: 0;
  border: none;
}

._separateBxs {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  align-items: center;
}

._disPatch_ind {
  background-color: var(--success-blue);
  color: var(--common-white);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border: none;
  min-height: 32px;
  border-radius: 4px;
  padding-inline: 15px;
}

.activeStatus {
  color: var(--common-white);
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  letter-spacing: -0.22px;
  border-radius: 9px;
  background: var(--warn-orange);
  box-shadow: 0px 2px 3px 0px rgba(255, 144, 43, 0.4);
  min-width: 53px;
  padding: 2.5px 5px;
}

._bidBtn_fx {
  display: flex;
  min-height: 32px;
  align-items: center;
  border-radius: 4px;
  border: 0.514px solid #e4e4e7;
  background: #f7f7fe;
  gap: 12px;
  padding: 0 10px 0 8px;
}

._bidTxt {
  color: var(--black-color);
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
}

._poDetails p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

._bidCount {
  border-radius: 4px;
  border: 0.514px solid #e4e4e7;
  background: #ff0018;
  color: var(--common-white);
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  min-width: 31px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 5px;
}

._bidC_para {
  border-radius: 9px;
  border: 1px solid #e4e4e7;
  background: #f7f7fe;
  min-height: 18px;
  min-width: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 4px;
}

._bidC_para span {
  color: var(--black-color);
  text-align: center;
  font-size: 11px;
  opacity: 0.7;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.22px;
}

._indentTable._planLTable table tbody tr.collapseRow td {
  padding: 0;
  border-bottom: 1px solid transparent;
}

._indentTable._planLTable table tbody tr.collapseRow .accordion-body {
  padding: 10px;
  border-bottom: 1px solid var(--light-gray);
}

._indentTable._planLTable .accordion-button._plnChev_btn svg {
  transition: transform 0.2s ease-in-out;
}

._indentTable._planLTable .accordion-button._plnChev_btn:not(.collapsed) svg {
  transform: rotate(-180deg);
}

._indentTable._planLTable .accordion-button._plnChev_btn::after {
  display: none;
}

.accordion-button._plnChev_btn {
  background-color: #eaf2ff;
  border-radius: 3.5px;
  color: #7db1ff;
  padding: 0;
  justify-content: center;
  width: 30px;
  height: 27px;
  font-size: 18px;
  box-shadow: none !important;
}

.accordion-button._plnChev_btn:not(.collapsed) {
  background-color: var(--theme-color);
  color: var(--common-white);
}

._innrPO_trs {
  display: flex;
  border-radius: 5px;
  border: 1px solid #e8eaed;
  background: #fbfbfb;
  padding: 12px 20px 10px;
  gap: 1em;
  margin-top: -1px;
}

._poDetails {
  max-width: 180px;
  width: 100%;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
}

._poDetails span {
  font-size: 10px;
}

._sepBid_fx {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
}

/* Indent */

/* Add Indent */

._sltIndent_bx {
  background: var(--common-white);
  padding: 26px 7px;
  border-bottom: 1px solid var(--light-gray);
  border-top: 1px solid var(--light-gray);
}

._sltIndent_bx ._verLine_cts:after {
  min-height: 62px;
}

._indTable_bx table {
  border: 1px solid var(--box-border-color);
  width: calc(100% - 35px);
  position: relative;
  z-index: 9;
  margin-bottom: 0.68em;
}

._indTable_bx table thead tr {
  background-color: var(--offWhite);
  border: 1px solid #d9e6ef;
}

._indTable_bx table th {
  padding: 7px 5px;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

._indTable_bx table th:first-child {
  padding-left: 12px;
}

._indTable_bx table tbody tr {
  background-color: var(--common-white);
  border: 1px solid var(--box-border-color);
}

._indTable_bx table td {
  padding: 15px 5px;
  color: var(--black-color);
  font-size: 14px;
  vertical-align: top;
  background-color: var(--common-white);
}

._indTable_bx table td:first-child {
  padding-left: 12px;
}

._indTable_bx table td:last-child {
  padding-right: 12px;
}

._frtLocation_bx {
  border: 1px solid var(--box-border-color);
  border-radius: 4px;
  padding: 10px 10px 12px;
  display: flex;
  justify-content: space-between;
}

._frtLocation_bx p {
  color: #494949;
  font-size: 10px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-bottom: 0;
}

._frtLocation_bx h6 {
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.28px;
  margin-bottom: 2px;
}

._sltHead {
  padding: 0 10px;
  margin-bottom: 1em;
}

._sltHead h4 {
  color: var(--saphire-blue);
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.32px;
}

._indTable_bx {
  position: relative;
}

.delIndentLoc_bxBtn {
  position: absolute;
  top: 40px;
  right: 0;
  border: none;
  background-color: transparent;
}

._addLocPo_btn {
  background-color: var(--secondary-color);
  border: none;
  min-height: 34px;
  color: var(--common-white);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 16px;
  border-radius: 4px;
}

._tblText_inDent {
  color: var(--black-color);
  opacity: 0.8;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

._lgtText_inDent {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.__locationWid_bx {
  max-width: 120px;
  width: 100%;
  min-width: 120px;
}

._dateNtime._pDate {
  min-width: 98px;
}

._poDetail_bx {
  background-color: var(--common-white);
  border-radius: 8px;
  border: 1px solid #0000001a;
  padding: 13px 14px 13px;
  position: relative;
}

._poDetail_bx ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

._poDetail_bx ul li {
  display: flex;
  font-size: 14px;
}

.__pdxTx {
  color: #afafaf;
  margin-bottom: 9px;
  line-height: normal;
  flex: 0 0 115px;
}

.__pdseTx {
  color: var(--black-color);
  line-height: normal;
  margin-bottom: 9px;
  flex: 0 0 calc(100% - 115px);
}

._fstAddress_tx,
._scdAddress_tx {
  color: var(--thin-black-color);
  line-height: 27px;
  letter-spacing: 0.28px;
}

._scdAddress_tx {
  opacity: 0.7;
}

.__delM_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  border: 0;
  background: transparent;
}

.__edit_btn {
  position: absolute;
  top: 10px;
  right: 30px;
  padding: 0;
  border: 0;
  background: transparent;
}

.__flxseBx,
.__numPercen_btBx {
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
}

.__flxseBx h6 {
  flex: 0 0 100%;
  color: var(--black-color);
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.9;
  margin-bottom: 8px;
}

._flxOtr {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex: 0 0 100%;
  margin-bottom: 15px;
}

.__numPercen_btBx {
  gap: 4px;
  margin-top: -10px;
}

.__numPercen_btBx button {
  border-radius: 4px;
  border: 1px solid #e0e9ff;
  background: #eef3ff;
  flex: 0 0 53px;
  min-height: 33px;
  color: var(--black-color);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  transition: all 0.25s;
}

.__numPercen_btBx button:hover {
  background-color: var(--success-blue);
  border: var(--success-blue);
  color: var(--common-white);
}

.__numPercen_btBx button.active {
  background-color: var(--success-blue);
  border: var(--success-blue);
  color: var(--common-white);
}

.__numPercen_btBx input {
  flex: 0 0 53px;
  min-height: 33px;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #e0e9ff;
  background: var(--common-white);
  padding: 0 10px;
}

.__numPercen_btBx input:focus {
  border-color: var(--success-blue);
}

.__numPercen_btBx input:focus-visible {
  outline: 1px solid var(--success-blue);
}

/* Add Indent */

/* Payment Approval */

._payMentApp_filts {
  padding: 20px 20px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

._fixFivBxs {
  flex: 0 0 calc(20% - 20px);
}

.statusDrop_dwn .dropdown-toggle {
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: var(--common-white);
  min-height: 35px;
  padding: 7px 9px;
  min-width: 104px;
}

.statusDrop_dwn .dropdown-toggle:active {
  background-color: var(--common-white);
  border: 1px solid #e6e6e6;
}

._drpStatus {
  color: #333956;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
}

._dotState {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.statusDrop_dwn .dropdown-toggle::after {
  opacity: 0;
  display: none;
}

._pending ._dotState {
  background-color: #e58989;
}

._approved ._dotState {
  background-color: var(--buttonBg);
}

._hold ._dotState {
  background-color: var(--warn-orange);
}

.statusDrop_dwn .dropdown-item {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  border: 1px solid transparent;
  transition: all 0.25s;
  border-bottom: 1px solid var(--box-border-color);
}

.statusDrop_dwn .dropdown-item:hover {
  background-color: #eef3ff;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
}

.statusDrop_dwn ul.dropdown-menu {
  border: 1px solid #e8eaed;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 6%);
}

/* Payment Approval */

._paymentStat_bxs {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

._paymentStat {
  flex: 0 0 calc(50% - 4px);
  border: none;
  color: var(--common-white);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  min-height: 35px;
  border-radius: 4px;
}

._paymentStat_completed {
  background-color: var(--buttonBg);
}

._paymentStat_hold {
  background-color: var(--warn-orange);
}

._paymentStat_reject {
  background-color: var(--reject-red);
}

._paymentStat_approved {
  background-color: var(--approve-green);
}

.signDropdown {
  position: relative;
  display: inline-block;
}

.signDropdown:hover .dropdownContent {
  display: block;
}

.dropbtn11 {
  /* height: 40px; */
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  width: 30px;
  background: none;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: var(--common-white);
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 4;
}

.dropdownContent ._samAnchor {
  /* color: black; */
  padding: 8px 16px;
  font-size: 13px;
  font-weight: 400;
  font-family: "Roboto", sans-serif !important;
  text-decoration: none;
  display: flex;
  gap: 5px;
  align-items: end;
}

.dropdownContent ._samAnchor:hover {
  background-color: #f1f1f1;
}

._samAnchor {
  color: var(--black-color);
}

._samAnchor .viewIcon_avt,
._samAnchor .editIcon_avt {
  color: var(--secondary-color);
}

.viewIcon_avt {
  font-size: 18px;
}

.editIcon_avt {
  font-size: 18px;
}

._delM_btn {
  color: #c31f1f;
  font-size: 13px;
}

.deleteIcon_avt {
  font-size: 18px;
}

.paginationFt_avt {
  display: flex;
  flex-direction: row;
  justify-content: end;
  position: sticky;
  bottom: 0;
  min-height: 58px;
  border: 1px solid var(--light-gray);
  background: var(--common-white);
  padding: 5px 22px;
}

.paginationFt_avt .pagination {
  margin-bottom: 0;
  align-items: center;
  gap: 5px;
}

.paginationFt_avt .pagination .page-item .page-link {
  border: 1px solid var(--light-gray);
  min-width: 25px;
  border-radius: 3px;
  background-color: var(--common-white);
  font-size: 14px;
  padding: 1px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black-color);
}

.paginationFt_avt .pagination .page-item.active .page-link {
  background-color: var(--warn-orange);
  border-color: var(--warn-orange);
  color: var(--common-white);
}

.paginationFt_avt .pagination .page-item.active .page-link:focus {
  box-shadow: none;
}

.paginationFt_avt .pagination .page-item.disabled .page-link {
  background: #ededed;
  color: #10101069;
}

._tabWith_outTab ._tab_htLane .anv_fr_detailHead {
  height: calc(100vh - 168px);
  overflow: auto;
}

._tabWith_outTab.partyTabs_cls ._tab_htLane .anv_fr_detailHead {
  height: calc(100vh - 267px);
  overflow: auto;
}

._tabWith_topTab ._tab_htLane .anv_fr_detailHead {
  height: calc(100vh - 213px);
  overflow: auto;
}

._tabWith_tab ._tab_htLane .anv_fr_detailHead {
  height: calc(100vh - 267px);
  overflow: auto;
}

._tabWith_invoiceTab ._tab_htLane .anv_fr_detailHead {
  height: calc(100vh - 249px);
  overflow: auto;
}

.anv_fr_detailHead::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.anv_fr_detailHead::-webkit-scrollbar-track {
  background: #f7f7fe;
}

/* Handle */
.anv_fr_detailHead::-webkit-scrollbar-thumb {
  background: #b2b0bf;
  border-radius: 10px;
}

/* Handle on hover */
.anv_fr_detailHead::-webkit-scrollbar-thumb:hover {
  background: #9c99aa;
}

._tab_htLane table thead tr {
  position: sticky;
  top: -1px;
  z-index: 1;
}

.green_Cls {
  color: rgb(28, 238, 28);
  font-size: 16px;
  font-weight: 600;
}

.red_Cls {
  color: var(--invailid-color);
  font-size: 16px;
  font-weight: 600;
}

.blurModal {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

/* bill raise with data table css */

.contentH_new {
  /* border: 1px solid var(--light-gray); */
  min-height: 46px;
  padding: 0 15px 0 10px;
  display: flex;
  align-items: center;
  gap: 28px;
  position: sticky;
  background: var(--common-white);
  z-index: 10;
  top: 55px;
}

.contentH_new h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color);
  opacity: 0.8;
  position: relative;
}

._divFix_wid._brxFlex .halfDiv {
  flex-basis: calc(50% - 5px);
}

/* / bill raise with data table css / */

.gapLoad_cls {
  margin: 20px 30px;
}

/* Thank You  Animation */

._checkAnimation {
  width: 225px;
  height: 225px;
  margin: 0 auto;
  position: relative;
  bottom: -14px;
}

._thkTx {
  text-align: center;
}

._thankYou_txt {
  font-size: 20px;
  line-height: normal;
  font-weight: 500;
  /* text-transform: capitalize; */
}

.modal.thankYouModal._dashCommon_Modal .modal-content {
  border-radius: 4px;
  /* min-height: 360px; */
}

._checkLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

/* Thank You  Animation */

._del_lrM_btn {
  color: #c31f1f;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

svg.del_lrCion {
  position: relative;
  top: 2px;
  font-size: 17px;
}

._invisibleSpan {
  display: block;
  width: 0;
  opacity: 0;
  overflow: hidden;
}

.headWith_back {
  gap: 8px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar-track {
  background: var(--light-gray);
}

/* Dispatch Table */

._trip_ind {
  border-radius: 4px;
  border: 0.514px solid #e4e4e7;
  background: #f7f7fe;
  display: flex;
  gap: 5px;
  font-size: 13px;
  padding: 4px 8px;
  align-items: center;
  text-decoration: none;
}

.trpCode {
  border-radius: 4px;
  border: 0.514px solid #e4e4e7;
  color: var(--common-white);
  background: var(--success-blue);
  font-weight: 400;
  line-height: normal;
  padding: 3px 5px;
}

._trckDetail {
  display: flex;
  align-items: center;
  gap: 12px;
}

._trckDetail p {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
}

._rsp_suplier {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
}

._disPatchView {
  height: calc(100vh - 213px);
}

._disPatchView tbody tr {
  cursor: pointer;
}

._disPatchView tbody tr:last-child {
  cursor: auto;
}

/* Dispatch Table */

/* Invoicing */

.addPr_flBx .cstNew_tabs.toggle_bill_invc {
  padding: 18px 16px;
  gap: 7px;
}

.addPr_flBx .cstNew_tabs.toggle_bill_invc li.nav-item .nav-link {
  border-radius: 6px;
  border: 1px solid #d8deff;
  font-weight: 400;
  background: var(--common-white);
  opacity: 1;
}

.addPr_flBx .cstNew_tabs.toggle_bill_invc li.nav-item .nav-link::after {
  height: 0px;
}

.addPr_flBx .cstNew_tabs.toggle_bill_invc li.nav-item .nav-link.active {
  border-color: var(--success-blue);
}

/* Invoicing */

.billDetal_viw {
  max-width: 453px;
  width: 100%;
  margin: 20px 20px;
  background: var(--common-white);
  border-radius: 7px;
  border: 1px solid #dbe5ff;
}

.bill_symry_details {
  padding: 0px 20px;
}

.active_label_masters_status {
  color: #28a745;
  /* Green color for active status */
  font-weight: 500;
  /* Make the text bold */
  margin-left: 8px;
  /* Add some space between the switch and the label */
}

.disabled_label_masters_status {
  color: #dc3545;
  /* Red color for disabled status */
  font-weight: 500;
  /* Make the text bold */
  margin-left: 8px;
  /* Add some space between the switch and the label */
}

.llt_permissions_dispatch {
  padding-bottom: 10px;
}

.llt_weight_input input {
  padding-right: 58px !important;
  padding-left: 10px !important;
}

/* Reports */

.__categories-container {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #d9d4d4;
}

._section-categories {
  flex: 0 0 33.33%;
  border-right: 1px solid #d9d4d4;
}

.__categories-titleBx {
  background-color: #f8f9fc;
  display: flex;
  font-size: 14px;
  padding: 15px 25px;
  height: 50px;
  border-top: 1px solid #d9d4d4;
  border-bottom: 1px solid #d9d4d4;
}

.__category-title {
  color: #687e96;
}

.__categoriesReport {
  padding: 12px 25px;
  font-size: 15px;
  transition: all 0.5s ease;
}

.__categoriesReport:hover {
  background: #f2f2f2;
  box-shadow: 0 1px 2px #00000026 !important;
  cursor: pointer;
}

.see-more-container {
  cursor: pointer;
  padding: 12px 25px;
  display: flex;
  align-items: center;
}

.see-more-container .see-more {
  font-size: 15px;
  color: #488dff;
}

.hollow-star {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.__categoriesReport:hover .hollow-star {
  opacity: 1;
}

/* Reports */

/* Quick Uploads */

#quickUploadAccordion .accordion-button:not(.collapsed) {
  background: var(--common-white);
}

#quickUploadAccordion .accordion-item {
  border-radius: 0;
}

#quickUploadAccordion .accordion-button:focus {
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

._quickCards {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  border-radius: 4px;
  background-color: var(--common-white);
  word-wrap: break-word;
  border: 1px solid #e2ebf7 !important;
  box-shadow: 0 0 3px rgba(31, 30, 47, 0.05);
  padding: 10px;
  margin-top: 15px;
}

.btnView {
  font-size: 12px;
  padding: 5px 9px;
  box-shadow: 0 5px 10px rgba(0, 130, 217, 0.2);
  border: none;
  background: var(--theme-color);
  border-radius: 3px;
  color: var(--common-white);
  display: inherit;
  width: 150px;
  margin: 0 auto;
}

._fasTruck {
  font-size: 40px;
  color: #475f7b;
}

.muted-text {
  color: #475f7b;
  margin-top: 5px;
  display: block;
  font-size: 12px;
}

#quickUploadAccordion .accordion-body {
  padding-top: 5px;
}

/* Quick Uploads */

span.warning_icn svg {
  width: 30px;
  height: 28px;
  color: #b4b408;
}

#item_group_hsn_view:disabled + label::after {
  background-color: #d6d6d6;
  border-color: var(--box-border-color);
}

#item_group_hsn_view:disabled + label::before {
  border-color: #6a6a6a;
  border-top: 0;
  border-left: 0;
}

.flxDpan {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Support & Help Page */

._suppBack_bg {
  background: var(--light-blue);
  padding: 36px 40px 0px;
  min-height: calc(100vh - 55px);
}

._suppHeading h4 {
  color: #1a1a1a;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 12px;
  line-height: normal;
}

._suppHeading p {
  font-size: 16px;
  color: #1a1a1a;
  margin-bottom: 24px;
}

.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
}

.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}

.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}

.ant-card-bordered {
  border: 1px solid var(--light-gray);
}

.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 8px;
  color: #000000d9;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
  background: var(--common-white);
  border-radius: 2px;
}

.ant-card-body {
  padding: 24px;
}

.ant-row-space-between {
  justify-content: space-between;
}

.grw_buss {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
}

.grw_buss h5 {
  font-size: 20px;
}

.list-style-none {
  list-style: none !important;
}

.grw_buss ul {
  padding: 0;
}

.grw_buss p {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  font-size: 14px;
  color: #1a1a1a;
}

.mr16 {
  margin-right: 16px !important;
}

._bookDemo_bx {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

._bookDemo_btn {
  color: var(--common-white);
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
  padding: 9px 16px;
  border-radius: 4px;
  font-weight: 500;
  outline: none;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  gap: 8px;
  font-size: 18px;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  min-width: 140px;
  position: relative;
}

._bookDemo_btn:hover {
  background-color: var(--secondary-color);
}

.playerOp1 {
  display: flex;
  flex-direction: column;
}

._inPlayer1 {
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 1;
  min-height: 156px;
}

._inPlayer1::before {
  position: absolute;
  top: 0px;
  left: 0px;
  content: "";
  z-index: 1;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-image: url(../img/youTube_videoThumbnail.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

._inPlayer1 .playIcon {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._inPlayer1::after {
  position: absolute;
  top: 0px;
  left: 0px;
  content: "";
  z-index: 1;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(21, 30, 40, 0.6);
}

.playerOp1 p {
  font-size: 16px;
  color: #858585;
  margin-top: 8px;
  margin-bottom: 0;
}

.mt16 {
  margin-top: 16px !important;
}

.mt32 {
  margin-top: 32px !important;
}

._crd_head {
  font-size: 18px;
  font-weight: 500;
  color: #1a1a1a;
}

.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

._filt_tabBtn {
  border: 1px solid #c5c5c7;
  background-color: inherit;
  padding: 6px 24px;
  font-weight: 500;
  font-size: 14px;
  color: #000000d9;
  border-radius: 17px;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}

._filt_tabActive {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--common-white);
}

.footerPosition {
  padding-bottom: 40px;
}

.footer-ctr {
  animation: 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s 1
    animate-opacity-and-move;
  display: flex;
  align-items: center;
  padding: 52px 0 16px;
}

.footer-ctr .promise-txt {
  margin-right: 33px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #1a1a1a;
}

.footer-ctr .content-ctr.safe {
  padding-right: 24px;
  margin-right: 24px;
  border-right: 1px solid #eaeaea;
}

.footer-ctr .content-ctr {
  display: flex;
  align-items: center;
}

.footer-ctr .content-ctr .img-ctr {
  height: 36px;
  margin-right: 8px;
  width: 36px;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-ctr .content-ctr.safe .img-ctr {
  background-image: url(../img/checkSafe.png);
}

.footer-ctr .content-ctr.bkp .img-ctr {
  background-image: url(../img/checkBackup.png);
}

.footer-ctr .content-ctr .text-ctr .mainTxt {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #5a5a5a;
}

.footer-ctr .content-ctr .text-ctr .sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #858585;
}

/* Support & Help Page */

._transactionTable ._dateNtime {
  min-width: 98px;
}

.img_fnex_src {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.pos-relative {
  position: relative;
}

img._pdfIcon_sigxe {
  width: 70px;
  height: 60px;
  padding: 3px;
  object-fit: cover;
  border: 1px solid var(--approve-green);
  border-radius: 3px;
}

._clDel_icon {
  position: absolute;
  right: 1px;
  top: 1px;
  display: flex;
  font-size: 18px;
  color: #000;
  cursor: pointer;
  background: #fff;
  width: 17px;
  height: 17px;
  border-radius: 50%;
}

._fltLabel.required-label::after {
  content: "*";
  color: var(--invailid-color);
  margin-left: 5px;
}

/* Address Book */

.gogLocation_btn {
  border: 1px solid var(--buttonBg);
  background-color: #1956f60d;
  border-radius: 3px;
  color: var(--black-color);
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 45px;
  padding: 5px 10px;
}

.valId {
  color: #f00;
  opacity: 0.9;
}

.addMap_body {
  padding: 22px 20px 30px;
}

/* Address Book */

.outOf_cks {
  font-size: 13px;
  color: #475f7b;
  padding-left: 10px;
}

/* Indent View */

.addPr_flBx._indentView .cstNew_tabs li.nav-item .nav-link {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 3px;
}

.addPr_flBx._indentView .cstNew_tabs li.nav-item .nav-link.active {
  color: var(--black-color);
}

._tbTitle {
  opacity: 0.7;
}

._bidTable {
  margin-top: -1px;
  height: calc(100vh - 145px);
  overflow: auto;
}

._bidTable table thead tr {
  border: 1px solid #e8eaed;
  background: #f7f7fe;
  position: sticky;
  top: -1px;
  z-index: 1;
  box-shadow: 0px 1px 4px 0px #00000011;
}

._bidTable table thead tr th {
  padding: 9px 10px 8px;
}

._bidTable table thead tr th:first-child {
  padding-left: 15px;
}

._bidTh_txt {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.8;
}

._actAction {
  opacity: 0;
}

._bidTable table tbody tr td {
  padding: 10px 10px 12px;
  background-color: var(--common-white);
}

._bidTable table tbody tr td:first-child {
  padding-left: 20px;
}

._bidTable table tbody tr {
  border-bottom: 2px solid var(--light-blue);
}

._bidR_bxs {
  display: flex;
  gap: 9px;
  align-items: center;
  max-width: 280px;
  min-width: 280px;
  cursor: pointer;
}

.__bidR_usrBx {
  position: relative;
  display: flex;
  justify-content: center;
}

._bidR_verifyIcon {
  position: absolute;
  top: -5px;
  left: -5px;
}

.__bidR_usrBx img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

._ratingBx {
  display: flex;
  gap: 2px;
  align-items: center;
  border-radius: 8.969px;
  border: 0.854px solid #e9efff;
  background: var(--common-white);
  padding: 1px 7px 1px 4px;
  position: absolute;
  bottom: -6px;
}

._ratingBx svg {
  font-size: 12px;
  color: var(--brightYellow);
}

._ratingBx span {
  color: var(--black-color);
  font-size: 12px;
  opacity: 0.5;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}

.tl_txt,
.tsm_txt {
  margin: 0;
  color: var(--thin-black-color);
  line-height: normal;
  letter-spacing: 0.28px;
  opacity: 0.9;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tl_txt {
  font-size: 14px;
  font-weight: 500;
}

.tsm_txt {
  font-size: 12px;
  font-weight: 400;
}

.tsm_txt_gry {
  color: var(--black-color);
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  opacity: 0.5;
  margin-bottom: 1px;
}

._bidStatus_bxs {
  max-width: 100px;
  min-width: 100px;
}

._pending_bidSts,
._accepted_bidSts,
._rejected_bidSts,
._withDrawn_bidSts,
._dispatched_bidSts,
._closed_bidSts,
._expired_bidSts {
  color: var(--common-white);
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.22px;
  padding: 2.5px 8px 2.5px 8px;
  border-radius: 9px;
  display: inline-block;
}

._pending_bidSts {
  background-color: var(--warn-orange);
  box-shadow: 0px 2px 3px 0px rgba(255, 144, 43, 0.4);
}

._accepted_bidSts {
  background-color: var(--checked-green);
  box-shadow: 0px 2px 3px 0px rgba(0, 147, 76, 0.4);
}

._rejected_bidSts,
._withDrawn_bidSts,
._closed_bidSts,
._expired_bidSts {
  background-color: var(--dark-reject-red);
  box-shadow: 0px 2px 3px 0px rgba(214, 39, 39, 0.4);
}

._dispatched_bidSts {
  background-color: var(--buttonBg);
  box-shadow: 0px 2px 3px 0px rgba(25, 86, 246, 0.4);
}

._dobBid {
  max-width: 110px;
  min-width: 110px;
}

._rateBid {
  max-width: 180px;
  min-width: 180px;
}

._callBtn {
  border: 1px solid #f2f2f2;
  background-color: var(--common-white);
  min-width: 33px;
  max-width: 33px;
  width: 100%;
  height: 33px;
  font-size: 16px;
  color: var(--theme-color);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

._actionOtr_bx {
  max-width: 380px;
  min-width: 380px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

._reRemark_bx {
  max-width: 380px;
  min-width: 380px;
}

._actionInr_bx {
  display: flex;
  gap: 6px;
  align-items: center;
}

._acceptSts,
._rejectSts {
  width: 96px;
  height: 33px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  border-radius: 3px;
  border: none;
}

._acceptSts {
  background-color: var(--checked-green);
}

._rejectSts {
  background-color: var(--dark-reject-red);
}

/* Indent View */

/* Market Load */

._mrktLoad_mainBx {
  background-color: #fafafa;
  padding: 6px;
  min-height: calc(100vh - 109px);
  gap: 8px;
  display: flex;
}

._mrktFilt_mainBx {
  flex: 0 0 240px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 121px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

._mrktFilt_mainBx::-webkit-scrollbar {
  display: none;
}

._marktLoad_listBx {
  flex: 0 0 calc(100% - 248px);
  height: calc(100vh - 121px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

._mrktInfilts_bx {
  border-radius: 4px;
  border: 1px solid #e8eaed;
  background: var(--common-white);
  padding: 14px 10px 12px;
  display: flex;
  flex-direction: column;
  gap: 17px;
}

._optFilts p {
  color: var(--black-color);
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.6;
  margin-bottom: 5px;
}

._optFilts .custom-select-container input {
  font-size: 13px;
  min-height: 32px;
  padding: 4px 28px 4px 12px;
}

._optFilts .custom-select-container .dropSearch_bx input {
  font-size: 14px;
  min-height: 34px;
  padding: 5px 10px;
}

._optFilts .custom-select-container:after {
  top: 14px;
  right: 14px;
}

._optFilts .addPr_flBx .cstNew_tabs._truckSelection {
  padding: 5px 0px 0px;
  gap: 5px;
}

._optFilts ._truckSelection li.nav-item {
  max-width: -webkit-fill-available;
  flex: 0 0 calc(33.33% - 4px);
}

._optFilts .addPr_flBx ._truckSelection li.nav-item .nav-link {
  font-size: 12px;
  min-height: 70px;
  font-weight: 400;
  position: relative;
}

._optFilts .addPr_flBx ._truckSelection li.nav-item .nav-link.active {
  border-color: var(--secondary-color);
  background-color: #f1f5ff;
  color: var(--black-color);
}

._optFilts .addPr_flBx ._truckSelection li.nav-item #LCV.active::before {
  background-image: url(../img/LCV.png);
}

._optFilts .addPr_flBx ._truckSelection li.nav-item #Truck.active::before {
  background-image: url(../img/truck.png);
}

._optFilts .addPr_flBx ._truckSelection li.nav-item #Container.active::before {
  background-image: url(../img/Container.png);
}

._optFilts .addPr_flBx ._truckSelection li.nav-item #Hyva.active::before {
  background-image: url(../img/Hyda.png);
}

._optFilts .addPr_flBx ._truckSelection li.nav-item #Trailer.active::before {
  background-image: url(../img/Trailer.png);
}

._optFilts .addPr_flBx ._truckSelection li.nav-item #Tanker.active::before {
  background-image: url(../img/Tanker.png);
}

._optFilts .addPr_flBx ._truckSelection li.nav-item .nav-link .font_icn_anj {
  display: none;
  right: -4px;
  top: -5px;
}

._optFilts
  .addPr_flBx
  ._truckSelection
  li.nav-item
  .nav-link.active
  .font_icn_anj {
  display: block;
}

._mrkt_searchBtn {
  color: #fff;
  text-align: center;
  background-color: var(--buttonBg);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  border: none;
  width: 100%;
  min-height: 39px;
  border-radius: 4px;
}

._favRouteBx {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

._favRoute_inTb {
  border-radius: 4px;
  border: 1px solid #e4e4e7;
  background: var(--common-white);
  min-height: 32px;
  display: flex;
  gap: 6px;
  padding: 3px 4px;
  align-items: center;
  cursor: pointer;
}

._favSave {
  flex: 0 0 27px;
}

._fr_txt {
  flex: 0 0 calc(100% - 33px);
}

._fr_txt span {
  color: var(--black-color);
  opacity: 0.8;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  display: block;
}

._mrkList_otrBx {
  border-radius: 4px;
  border: 1px solid #e8e7e7;
  background: var(--common-white);
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-row-gap: 9px;
  grid-column-gap: 12px;
  flex-wrap: wrap;
}

._rtrBxs {
  max-width: 300px;
  min-width: 300px;
}

._fleerBxs {
  max-width: 210px;
  min-width: 210px;
}

._materialBxs {
  max-width: 106px;
  min-width: 106px;
}

._payTerms_bxs {
  max-width: 155px;
  min-width: 155px;
}

._whtBxs {
  max-width: 100px;
  min-width: 100px;
}

._peDate {
  max-width: 120px;
  min-width: 120px;
}

._plc_Nbid {
  flex: 0 0 100%;
  border-radius: 8px;
  min-height: 69px;
  background: #f5f8ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 30px 9px 20px;
}

._placeBid_btn,
._updateBid_btn,
._withdrawBid_btn,
._markPlacedBid_btn {
  width: 100%;
  min-width: 125px;
  min-height: 32px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}

._placeBid_btn,
._updateBid_btn {
  background-color: var(--buttonBg);
  color: var(--common-white);
}


._placeBid_btn_book{
  /* width: 100%; */
  min-width: 125px;
  min-height: 32px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}
._placeBid_btn_book{
  background-color: var(--buttonBg);
  color: var(--common-white);
}
._withdrawBid_btn {
  background-color: var(--dark-reject-red);
  color: var(--common-white);
}

._markPlacedBid_btn {
  background-color: var(--checked-green);
  color: var(--common-white);
}

._plc_Nbid ._actionInr_bx {
  gap: 8px;
}

/* ._plc_Nbid ._callBtn{
  width: 39px;
  height: 39px;
  font-size: 19px;
} */

/* Market Load */

/* My Bids */

._myBid_listBx {
  height: calc(100vh - 157px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 1em 0;
}

._myBid_listBx ._mrkList_otrBx {
  max-width: 1326px;
  margin: 0 auto;
}

._myPlcBix {
  display: flex;
  align-items: center;
  gap: 0.6em;
}

/* My Bids */

/* My Connections */

._connectContainer {
  background-color: #fafafa;
  height: calc(100vh - 215px);
  overflow: auto;
}

.__conect_mainBg {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 12px;
  padding: 25px 12px;
  max-width: 1526px;
  margin: 0 auto;
}

.__inr_conectBx {
  padding: 12px 10px;
  border-radius: 4px;
  border: 1px solid #e8e7e7;
  background: var(--common-white);
  flex: 0 0 359px;
}

.__inr_conectBx ._bidR_bxs {
  border-radius: 8px;
  background: #f5f8ff;
  padding: 10px 19px 10px 20px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.__connDetail_main {
  display: flex;
  justify-content: space-between;
  padding: 10px 8px 18px 1px;
}

.__connDetail span {
  color: var(--black-color);
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  opacity: 0.5;
  display: block;
  margin-bottom: 1px;
}

.__connDetail p {
  color: var(--thin-black-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  opacity: 0.9;
  margin-bottom: 0;
}

._bottomBtn_bar {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

._viewPrf_btn {
  width: 100%;
  border: 1px solid var(--theme-color);
  border-radius: 3px;
  color: var(--success-blue);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  min-height: 33px;
  background-color: var(--common-white);
}

._rejctPrf_btn {
  width: 100%;
  border: 1px solid var(--light-invailid);
  border-radius: 3px;
  color: var(--light-invailid);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  min-height: 33px;
  background-color: var(--common-white);
}

._acceptPrf_btn {
  width: 100%;
  border: 1px solid var(--theme-color);
  border-radius: 3px;
  background-color: var(--success-blue);
  color: var(--common-white);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  min-height: 33px;
}

.one_3rdDiv {
  flex: 0 0 42%;
}

.twiceDiv {
  flex: 0 0 56%;
}

/* My Connections */

.green_status {
  color: #28a745;
  font-weight: 500;
}

.pac-container {
  width: 467px;
  position: absolute;
  left: 685px;
  top: 153px;
  display: block;
  z-index: 999999 !important;
}

.red_status {
  color: #dc3545;
  font-weight: 500;
}

/* common dropdown close icon */

.clear-icon2 {
  position: absolute;
  right: 40px;
  /* Adjust positioning as needed */
  top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.1);
  /* Subtle background */
  border-radius: 50%;
  /* Circular background */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth transitions */
}

.clear-icon2:hover {
  background-color: rgba(0, 0, 0, 0.2);
  /* Darker on hover */
  transform: scale(1.1);
  /* Slightly larger on hover */
}

.clear-icon2 svg {
  width: 14px;
  /* Icon size */
  height: 14px;
  /* Icon size */
  color: black;
  /* Icon color */
}

.vehicle_dots .custom-select-container input {
  padding-right: 68px;
}

/* common dropdown close icon */

.default_lrContrx {
  position: relative;
  top: -2px;
  background: #f8f8f8;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 15px;
  border: 1px solid #e8eaed;
  border-radius: 20px;
}

.truck_checkBv {
  display: flex;
  padding-top: 5px;
  gap: 13px;
}

#myConnectionTab {
  border: none;
  gap: 12px;
}

#myConnectionTab .nav-link {
  margin: 0;
  border-radius: 29.839px;
  border: 0.947px solid #e8eaed;
  background: var(--common-white);
  padding: 2px 15px 1.5px 16px;
}

#myConnectionTab .nav-link.active {
  border: 0.947px solid #d1e3ff;
  background: #e8f1ff;
}

#myConnectionTab .nav-link span {
  color: #323232;
  font-size: 13.262px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.265px;
  opacity: 0.8;
}

#myConnectionTab .nav-link.active span {
  color: var(--black-color);
  opacity: 0.8;
}

._starGrp_bx {
  display: flex;
  gap: 10px;
  color: #e7e7e7;
  font-size: 24px;
}

._starGrp_bx svg {
  cursor: pointer;
}

._rvBx_lstMain {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 18px;
  max-height: 424px;
  overflow: auto;
}

._rvBx_lst {
  border-radius: 5px;
  border: 1px solid #e8eaed;
  background: var(--common-white);
  padding: 15px 30px 15px 15px;
}

._rvBd_imgBx img {
  width: 36px;
}

._rv_bdrDetail h5 {
  color: #323232;
  font-size: 10.5px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.21px;
  opacity: 0.9;
  margin-bottom: 2px;
}

._rv_bdrDetail p {
  color: #323232;
  font-size: 9px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.18px;
  opacity: 0.9;
  margin-bottom: 4px;
}

._starFillGrp_bx {
  display: flex;
  gap: 4px;
  color: #fdcc01;
  font-size: 12px;
  align-items: center;
}

._rv_comments {
  margin-top: 8px;
}

._rv_comments p {
  color: #323232;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.24px;
  opacity: 0.9;
  margin-bottom: 0;
}

/* / my connection css / */

.flexDiv_connect {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 4px 0px;
  align-items: center;
}

/*  */

._addConsigneeBtn {
  border: 1px dashed var(--default-blue);
  background: var(--common-white);
  min-height: 110px;
  width: 100%;
  color: var(--default-blue);
  font-size: 14px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.28px;
}

.innerCi_int {
  position: relative;
}

.innerCi_int label {
  flex-direction: column;
  align-items: flex-start !important;
}

.innerCi_int .tin_fonts,
._sepRate_cont {
  color: #3c3c3c;
  font-size: 9px !important;
  line-height: 10px !important;
  font-weight: 400;
  letter-spacing: 0.16px;
}

.innerCi_int [type="checkbox"]:not(:checked) + label:before,
.innerCi_int [type="checkbox"]:not(:checked) + label:after,
.innerCi_int [type="checkbox"]:checked + label:after {
  top: 5px;
}

.innerCi_int [type="checkbox"]:checked + label:before {
  top: 8px;
}

._descipTed p {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  margin-bottom: 0;
}

._descipTed span {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.no_dataFount {
  font-size: small;
}

.addPr_flBx._vhlTab_bx
  .cstNew_tabs.toggle_bill_invc
  li.nav-item
  .nav-link._vhlTab_lst {
  text-align: left;
  padding: 13px 10px 8px 13px;
  width: 100%;
}

.addPr_flBx._vhlTab_bx
  .cstNew_tabs.toggle_bill_invc
  li.nav-item
  .nav-link._vhlTab_lst
  span {
  display: inline-block;
  margin-bottom: 4px;
}

._vhlTab_bx ._vhlTab_lst ._nwRate {
  color: var(--black-color);
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.9;
}

._vhlTab_bx ._vhlTab_li {
  min-width: 154px;
}

._fstLetter {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #ddd;
  font-size: 18px;
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

._fstLetter_listCs {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ddd;
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

/* Vehicle Documents */

.__bt_seprate {
  min-height: 59px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e8eaed;
  background: #fff;
  border-top: none;
  padding-inline: 19px;
}

.__addDetail_ {
  border-radius: 5px;
  border: 1px solid #c3dbff;
  background: #e4ecff;
  min-height: 31px;
  min-width: 108px;
}

.__addDetail_ span {
  color: #181818;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
}

._dtx_typeBx {
  display: flex;
  align-items: center;
}

._ntf_bx {
  position: relative;
}

.__ntfRl {
  border-radius: 1.603px;
  background: var(--dark-reject-red);
  width: 12px;
  height: 12px;
  position: absolute;
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -1.4px;
  right: -1.3px;
  padding-top: 2px;
}

.__dtfTx_tx {
  color: #1344c2;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  margin-left: 16px;
  margin-right: 8px;
}

.__vhlDetail_tabLx .anv_fr_detailHead {
  height: calc(100vh - 225px);
  overflow: auto;
}

.__vhlDetail_tabLx .table_head_style th:first-child {
  width: 13%;
}

.__vhlDetail_tabLx .table_head_style th:nth-child(2) {
  width: 26%;
}

/* Vehicle Documents */

.__edit_btn_po {
  /* position: absolute; */
  top: 10px;
  right: 30px;
  padding: 0;
  border: 0;
  background: transparent;
}

.__delM_btn_po {
  top: 10px;
  right: 30px;
  padding: 0;
  border: 0;
  background: transparent;
}

.po_button_align {
  display: flex;
  align-items: start;
  gap: 10px;
}

.curser_pointer {
  cursor: pointer;
}

._showAllRoute {
  text-decoration: none;
  color: #2a62ff;
  display: inline-block;
  margin-left: 2px;
  border: none;
  background-color: transparent;
}

/* market load css */

.head_load {
  display: flex;
  justify-content: space-between;
}

.icon_favorite {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  width: 100px;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.search_loads {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.connect_color,
.connect_out_color {
  position: relative;
}

.connect_color ._tglrBtn {
  padding: 8px 15px;
  border: 1px solid var(--buttonBg);
  background: #f1f5ff;
  border-radius: 30px;
  color: #646161;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 500;
}

.connect_out_color ._tglrBtn {
  padding: 8px 15px;
  border: 1px solid #efefef;
  background: #ffffff;
  border-radius: 30px;
  color: #646161;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 500;
}

img.font_icn_anj {
  position: absolute;
  width: 13px;
  right: 0;
  top: -3px;
  z-index: 9;
  cursor: pointer;
}

.connect_out_color img.font_icn_anj {
  display: none;
}

._ratingBx.new_starCls {
  background: #00934c !important;
  color: #ffffff !important;
}

._ratingBx.new_starCls span {
  opacity: 1;
  color: var(--common-white);
}

.NoPreviewAvl {
  padding: 5px;
  font-size: 16px;
  color: var(--thin-black-color);
  opacity: 0.5;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

._nwDix_sprt {
  border-radius: 8px;
  border: 1px solid #D9E6EF;
  background: #FAFDFF;
  padding: 4px 16px;
  min-height: 65px;
  min-width: 301px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

._anvFix_bs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.__vw_sxBtn {
  border-radius: 8px;
  background: var(--default-blue);
  color: var(--common-white);
  border: none;
  min-width: 103px;
  min-height: 41px;
  padding: 5px 8px;
}

.anv_freightTable_detail_tpBx .__nW_tblSpx .anv_fr_detailHead h4 {
  justify-content: space-between;
  border-radius: 0;
  font-size: 14px;
}

.anv_freightTable_detail_tpBx .__nW_tblSpx .anv_fr_detailHead h4:first-child {
  border-radius: 3px 3px 0 0;
}

.__rtAmt {
  color: var(--default-blue);
}

._nW_h4Inner {
  display: flex;
  align-items: center;
  gap: 15px;
}

.__nW_tblSpx .addAble_btn {
  font-size: 10px;
  padding: 1px 9px 1px 1px;
  min-width: 105px;
  gap: 6px;
}

.__nW_tblSpx ._otrIcon_imb {
  width: 25px;
  height: 23px;
}

.__nW_tblSpx ._otrIcon_imb img {
  width: 15px;
}

._addNewComm_btn_disable_ {
  background-color: #e8eaed;
  border: none;
  min-height: 34px;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 16px;
  cursor: pointer;
  border-radius: 4px;
}

._exceluploadBx {
  flex: 0 0 180px;
}