._anvCustom_dropDown_nW .btn-group {
    width: 100%;
}

._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle {
    justify-content: space-between;
    border: 1px solid #E8E7E7;
    color: #000000;
    font-size: 15px;
    min-height: 45px;
    border-radius: 3px;
    transition: 0s all ease-out;
    display: flex;
    align-items: center;
}

._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle:hover {
    border-color: var(--secondary-color);
}

._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle:focus {
    outline: 2px solid var(--secondary-color);
}

._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle:focus:hover {
    border-color: transparent;
}

._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle label {
    position: absolute;
    margin: 0;
    padding: 0 2px;
    top: -7px;
    left: 8px;
    background-color: #fff;
    /* transform: translate(-5px, -6px); */
}

._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle label span {
    color: #000000;
    line-height: 12px;
    opacity: 0.7;
    font-size: 12px;
    font-weight: 500;
    display: block;
}

/* ._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle:focus label {
    top: -6px;
    left: 7px;
} */

._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle:focus label span {
    color: var(--secondary-color);
    opacity: 1;
}

._anvCustom_dropDown_nW ul.dropdown-menu.show {
    width: 100%;
    padding: 4px 0;
    border-color: #e8eaed;
}

.dropSearch_bx {
    padding: 0 5px;
}

.dropSearch_bx input {
    width: 100%;
    border: 1px solid #e8eaed;
    border-radius: 3.62px;
    background-color: #ffffff;
    font-size: 15px;
    line-height: 17.58px;
    color: #000;
    min-height: 36px;
    color: #000;
    padding: 5px 10px;
}


.dropSearch_bx input:focus-visible {
    outline: 1px auto var(--secondary-color);
}

.dropSearch_bx input::placeholder {
    color: rgba(0, 0, 0, 1);
    opacity: 0.5;
}

.dropSearch_bx_account {
    padding: 0 5px;
}

.dropSearch_bx input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 1);
}

.dropSearch_bx_account input {
    width: 100%;
    border: 1px solid #e8eaed;
    border-radius: 3.62px;
    background-color: #ffffff;
    font-size: 15px;
    line-height: 17.58px;
    color: #000;
    min-height: 36px;
    color: #000;
    padding: 5px 10px;
}

.dropSearch_bx_account input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 1);
}

.dropSearch_bx_account input:focus-visible {
    outline: 1px auto var(--secondary-color);
}


.dropSearch_bx_account input::placeholder {
    color: rgba(0, 0, 0, 1);
    opacity: 0.5;
}



li.mapList_views ul {
    list-style: none;
    padding: 0;
    margin-top: 6px;
}

.add_rtBtn {
    padding: 0 5px;
}

.add_rtBtn button {
    background: var(--secondary-color);
    color: #fff;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9.8px 10px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 3.62px;
    border: 1px solid rgba(232, 234, 237, 1);
}

._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle .fixValue {
    left: 14px;
    position: absolute;
}

/* ._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle:focus .fixValue {
    left: 13px;
} */

._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle::after {
    color: rgb(0 0 0 / 60%);
    position: absolute;
    right: 17px;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-bottom: 0;
    border-left: 0.35em solid transparent;
}

/* ._anvCustom_dropDown_nW .btn-group .btn.dropdown-toggle:focus::after {
    right: 16px;
} */

._anvRo_locateBx {
    display: flex;
    padding: 12px 12px 8px 14px;
    border-bottom: 1px solid #e8eaed;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .26s;
    margin: 4px 5px;
}

._anvRo_locateBx_account {
    display: flex;
    padding: 9px 12px 9px 11px;
    border-bottom: 1px solid #e8eaed;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    align-items: center;
    justify-content: space-between;
    transition: all .26s;
    /* margin: 4px 5px; */
    font-size: 14px;
    background: var(--light-blue);
    color: #333333;
}

._anvRo_locateChildBx_account {
    display: flex;
    padding: 12px 12px 8px 14px;
    border-bottom: 1px solid #e8eaed;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .26s;
    margin: 4px 5px;
    font-size: 14px;
    line-height: 17.58px;
}


._anvRo_locateChildBx_account:hover {
    background-color: #eef3ff;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
}

._anvRo_locateChildBx_account._vhlActive_account:hover {
    background-color: var(--mid-gray-color);
    border-color: var(--mid-gray-color);
}

.anvLrc {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* max-width: 252px; */
}

.anvLrc_account {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.anvLrc span {
    font-size: 13px;
    line-height: 15.23px;
    color: rgba(0, 0, 0, 1);
    opacity: .56;
    margin-bottom: 3px;
}

.anvLrc a {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    line-height: 17.58px;
    text-decoration: none;
    font-weight: 400;
}

._editRts {
    background: transparent;
    border: none;
    padding: 2px;
    height: auto;
}

._vhlNumber {
    font-size: 13px;
    line-height: 15.23px;
    color: rgba(0, 0, 0, 1);
    opacity: .56;
    margin-bottom: 3px;
}

._vhlStatus {
    font-size: 13px;
    line-height: 15.23px;
    color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 70px;
}

._vhlOwnType {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    line-height: 17.58px;
}

._vhlDetails {
    padding: 10px 6px 8px 6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all .26s;
    border-bottom: 1px solid #ddd;
}

._anvRo_locateBx:hover {
    background-color: #EEF3FF;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
}

._anvRo_locateBx._vhlActive {
    border-radius: 4px;
}

/* ._anvRo_locateBx._vhlActive:hover {
    background-color: var(--mid-gray-color);
    border-color: var(--mid-gray-color);
} */

._anvRo_locateBx._vhlActive:hover {
    background-color: var(--theme-warning);
    border-color: var(--theme-warning);
}

._anvRo_locateBx._vhlActive .anvLrc a {
    color: #26263b;
}

._anvRo_locateBx._vhlActive .anvLrc a:hover {
    color: #26263b;
}

._vhlDetails:hover,
._vhlActive ._vhlDetails,
._vhlActive ._anvRo_locateBx {
    background-color: var(--secondary-color);
    border-bottom: transparent;
}

._vhlDetails:hover ._vhlNumber,
._vhlDetails:hover ._vhlStatus,
._vhlDetails:hover ._vhlOwnType,
._vhlActive ._vhlDetails ._vhlNumber,
._vhlActive ._vhlDetails ._vhlStatus,
._vhlActive ._vhlDetails ._vhlOwnType,
._vhlActive ._anvRo_locateBx .anvLrc span,
._vhlActive ._anvRo_locateBx .anvLrc a {
    color: #fff;
}

._vhlActive ._anvRo_locateBx ._editRts svg path {
    fill: white;
}

._vhlActive {
    background-color: var(--secondary-color);
    color: #fff;
}
._vhlActive_account {
    background-color: var(--mid-gray-color);
    border-radius: 4px;
}

.mapList_views {
    max-height: 230px;
    overflow: auto;
    padding: 0 !important;
}

.mapList_views::-webkit-scrollbar {
    width: 8px;
}

.mapList_views {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.mapList_views::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}

.mapList_views::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 1px solid var(--scrollbarBG);
}

.anvLrc_edit {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  
}