@media (min-width: 576px) {
  ._addAccount_modal .modal-dialog {
    max-width: 393px;
  }
}

._addAccount_modal .modal-dialog .modal-content {
  border: 1px solid var(--light-gray);
  border-radius: 4px;
}

._addAccount_modal .modal-title {
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  display: flex;
  gap: 8px;
  align-items: center;
}

._addAccount_modal .modal-title span {
  opacity: 0.8;
}

._switchBtn {
  background: transparent;
  border: none;
  margin-right: 5px;
}

._addAccount_modal .modal-header {
  justify-content: space-between;
  padding: 12px 16px 12px 22px;
  border-color: var(--light-gray);
  min-height: 54px;
}

._addAccount_modal .modal-footer {
  justify-content: flex-start;
  border-color: var(--light-gray);
}

._submitOn_modal {
  background-color: var(--warn-orange);
  color: #fff;
  border: none;
  width: 100%;
  margin: 0 !important;
  border-radius: 4px;
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 400;
  min-height: 45px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

._submitOn_modal:hover {
  color: #fff;
}

._submitOn_modal:disabled {
  pointer-events: none;
  background-color: var(--light-gray) !important;
  color: #000000b5;
}

._addAccount_modal .modal-body {
  padding: 15px 13px 28px;
}

._addUser_imgBx img {
  margin: 0 auto;
  display: block;
  width: 108px;
  border-radius: 50%;
}

._fileUpdate {
  text-align: center;
  margin: 12px auto 35px;
  max-width: 191px;
}

._fileUpdate input {
  display: none;
}

._fileUpdate label {
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px;
  color: var(--secondary-color);
}

._inputsInx_bx {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

._businessType_listOtr {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

._businessType_listBx {
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: 1px solid #dad9d9;
  border-radius: 4px;
  text-decoration: none;
  padding: 10px 15px;
  justify-content: space-between;
}

.checkSide img {
  display: none;
}

._trkSide {
  display: flex;
  gap: 12px;
  align-items: center;
  max-width: 242px;
}

._headH3 {
  color: #000000;
  font-size: 18px;
  margin: 0;
}

._trkSide p {
  font-size: 14px;
  line-height: 18px;
  color: #878787;
  margin: 0;
}

._trkSide img {
  width: 43px;
}

._trkSide ._headH3 {
  line-height: 30px;
}

.bxActive {
  outline: 1px solid var(--theme-color);
  background-color: #f3f7ff;
}

.bxActive .checkSide img {
  display: block;
}

.checkSide {
  margin-right: 6px;
}

._crtComp_modBx {
  padding: 5px 6px 0;
}

._stepNum {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  opacity: 0.6;
  line-height: 30px;
}

._gstFlx_bx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._gstFlx_bx span {
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 500;
  color: #000;
}

._inrInx {
  display: flex;
  gap: 9px;
  flex-wrap: wrap;
}

._divDivide_half {
  flex: 0 0 calc(50% - 4.5px);
}

._modBack_btn {
  border: none;
  background: transparent;
}

.anvTbl_list {
  width: 100%;
  margin: 0;
  border: none;
}

.anvTbl_list .backTabs {
  position: absolute;
  top: 1px;
}

.anvTbl_list .backTabs .nav-link {
  border: none;
  padding: 9px 4px 9px 10px;
  background-color: #fff;
  min-height: 53px;
}

.anvTbl_list .backTabs .nav-link.active {
  display: none;
}

.stepRedirect {
  width: 100%;
}

.stepRedirect ._submitOn_modal {
  position: relative;
  z-index: 9;
}

.stepRedirect ._submitOn_modal.active {
  z-index: 1;
}

li.finalSub_bx ._submitOn_modal {
  position: absolute;
  width: calc(100% - 24px);
  left: 12px;
  z-index: 1;
}

/* SM, MD, Xl, XXL, XXXL Modal */

._xxxlModal .modal-dialog {
  max-width: 1522px;
  padding: 0 12px;
}

._xxlModal .modal-dialog {
  max-width: 1260px;
  padding: 0 12px;
}

._xlModal .modal-dialog {
  max-width: 1054px;
  padding: 0 12px;
}

._lgModal .modal-dialog {
  max-width: 765px;
  padding: 0 12px;
}

._mdModal .modal-dialog {
  max-width: 535px;
  padding: 0 12px;
}

._smModal .modal-dialog {
  max-width: 410px;
  padding: 0 12px;
}

._poModal .modal-dialog {
  max-width: 608px;
  padding: 0 12px;
}

._addExpModal .modal-dialog {
  max-width: 608px;
  padding: 0 12px;
}

.modal._xlModal .modal-content,
.modal._xxlModal .modal-content,
.modal._xxxlModal .modal-content,
.modal._lgModal .modal-content,
.modal._mdModal .modal-content,
.modal._dashCommon_Modal .modal-content {
  border-radius: 0;
  border-color: var(--light-gray);
}

h3._cmmTitle {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.8;
}

.modal._dashCommon_Modal .modal-header span {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.8;
}

.modal._xlModal .modal-header,
.modal._lgModal .modal-header,
.modal._mdModal .modal-header,
.modal._xxlModal .modal-header,
.modal._xxxlModal .modal-header,
.modal._dashCommon_Modal .modal-header {
  padding: 16px 16px 16px 22px;
}

.modal._xlModal .modal-footer,
.modal._lgModal .modal-footer,
.modal._mdModal .modal-footer,
.modal._xxlModal .modal-footer,
.modal._dashCommon_Modal .modal-footer {
  justify-content: flex-start;
  padding: 12px 19px;
  gap: 12px;
}

.modal._printViewModal .modal-footer {
  justify-content: flex-end;
  padding: 12px 19px;
  gap: 12px;
}

.modal._xlModal .modal-footer button,
.modal._xxlModal .modal-footer button,
.modal._xxxlModal .modal-footer button,
.modal._lgModal .modal-footer button,
.modal._mdModal .modal-footer button,
.modal._dashCommon_Modal .modal-footer button {
  margin: 0;
  min-height: 38px;
}

.addCont_body {
  padding: 28px 22px 35px;
  display: flex;
  flex-direction: column;
  gap: 29px;
}

.addLr_body {
  padding: 20px 22px 32px;
}

.con_mHead h6 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.8;
  margin-bottom: 12px;
}

._acFlx_ {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

._fxs_cstBx {
  flex: 0 0 230px;
}

._conM_flx {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

._conM_flx .form-check {
  margin: 0;
}

._conM_flx .form-check-label {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

._conM_flx ._cstForm_check .form-check-label {
  display: flex;
  min-height: 20px;
  align-items: center;
}

._cngDetail {
  display: flex;
  flex-wrap: wrap;
  gap: 28px 9px;
}

._dualDivs {
  flex: 0 0 calc(50% - 5px);
}

._rtInn_bx {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

._cngH5_head {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
  line-height: normal;
  margin-bottom: 20px;
}

._dashCommon_Modal ._submitOn_modal {
  background-color: var(--warn-orange);
  font-size: 14px;
}

.lrSeries_body {
  padding: 16px 12px 2px 16px;
}

._dashCommon_Modal .switched {
  display: none;
}

._addAccount_modal .cross {
  display: none;
}

.lrSeries_head p {
  color: #848ba6;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.addNew_seriesBtn {
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background: #fff;
  width: 100%;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

._serFx {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondary-color);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.serierList_otrBx {
  margin-top: 9px;
}

._innrSeries_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 32px;
}

._innrSeries_list label p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  cursor: pointer;
}

.seriesInput {
  position: absolute;
  left: 3px;
}

input.seriesInput[type="radio"] {
  width: 19px;
  height: 19px;
}

.serierList_otrBx input:checked~._srActions_bx ._delSvg path {
  fill: #cb5454;
}

.serierList_otrBx {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 150px;
  overflow: auto;
  margin-right: -12px;
  padding-right: 12px;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  padding-bottom: 10px;
}

.serierList_otrBx::-webkit-scrollbar {
  width: 10px;
}

.serierList_otrBx::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  border-radius: 6px;
}

.serierList_otrBx::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 1px solid var(--scrollbarBG);
}

._srActions_bx {
  display: flex;
  align-items: center;
  gap: 12px;
}

._srEdit_btn,
._srDel_btn {
  border: none;
  background: transparent;
}

.addNewlrSeries_body {
  padding: 16px 16px 22px;
}

/* SM, MD, Xl, XXL, XXXL Modal */

/* Confirm Modal */

.confirmModal .modal-content {
  border: none;
}

.confirmModal .modal-header {
  border-bottom: none;
  padding-bottom: 8px;
  padding-top: 20px;
}

.confirmModal .modal-title {
  font-size: 18px;
  font-weight: 400;
  display: flex;
}

.confirmModal .modal-title img {
  width: 28px;
  margin-right: 6px;
}

.confirmModal .modal-body {
  padding: 0 22px 20px;
  font-size: 15px;
}

.confirmModal .modal-footer {
  justify-content: flex-start;
  padding: 20px;
  gap: 10px;
}

.confirmModal .modal-footer button {
  margin: 0;
}

._confirmPrx {
  margin: 0;
}

/* Confirm Modal */

/* LR Freight */

._lrFreightBody {
  padding: 20px 22px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

._lrxAll {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

._lrx_flx {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 0 3px;
}

.xl_size {
  flex: 0 0 calc(24% - 10px);
}

.fright_size {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(15% - 1px);
}

.md_size {
  flex: 0 0 calc(12% - 11px);
}

.lrfHead {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
}

.smCheck .anvLable {
  width: 30px;
  height: 30px;
}

.smCheck [type="checkbox"]:not(:checked)+label:before,
.smCheck [type="checkbox"]:not(:checked)+label:after,
.smCheck [type="checkbox"]:checked+label:after {
  top: 10px;
}

.smCheck [type="checkbox"]:checked+label:before {
  top: 13px;
}

/* LR Freight */

/* Create Route */

._fsFlex {
  display: flex;
  gap: 8px;
}

._del_pl {
  background: transparent;
  border: none;
}

._crtRoute_body {
  padding: 20px 22px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

._addmores_bx {
  display: flex;
  flex-direction: column;
  gap: 22px;
  flex: 0 0 100%;
  width: 100%;
}

.pdlLx_bx {
  display: flex;
  flex-direction: column;
  gap: 11px;
  align-items: flex-start;
}

._addMore_pl {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  border: none;
}

._btmLine {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
  margin: 3px 2px 0;
}

/* Create Route */

/* Select Existing LR */

._selectLrBody {
  padding: 18px 0 0;
}

._selectLr_table {
  height: calc(100vh - 264px);
  overflow: auto;
  padding: 0px 0px 10px 22px;
}

._sercFilts_bx {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 22px 20px;
  grid-row-gap: 1em;
}

.plan_search_header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: calc(100% - 150px);
}

.plan_search_header ._anvPlan_srOuter_bx {
  flex: 0 0 20%;
}

._selectLr_table table th {
  background-color: #f7f7fe;
  padding: 8px;
}

._selectLr_table table th,
._selectLr_table table td {
  padding: 8px;
  text-align: left;
  font-size: 14px;
}

._selectLr_table table {
  width: 100%;
  border: 1px solid var(--light-gray);
  border-bottom: 0;
}

._selectLr_table table tr {
  border-bottom: 1px solid var(--light-gray);
}

._selectLr_table table thead {
  position: sticky;
  top: -1px;
}

/* Select Existing LR */

/* Add Item */

.addItem_body {
  padding: 15px 15px 25px;
}

.colFlx {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

._inSil_flx {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}

._inSi_duoBx {
  flex: 0 0 calc(50% - 3.5px);
}

._btmInput_para {
  color: var(--black-color);
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  margin-top: 3px;
  margin-left: 4px;
  margin-bottom: 0;
}

._weightSpan {
  position: absolute;
  color: var(--buttonBg);
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  border-radius: 3.624px;
  border: 0.906px solid var(--buttonBg);
  background: #f1f5ff;
  max-width: 66px;
  width: 100%;
  min-height: 33px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 6px;
  top: 6px;
}

/* Add Item */

/* Vehicle Modal */

.addVehicle_body.addPr_flBx .cstNew_tabs._truckSelection {
  padding: 0;
  gap: 14px;
}

.addVehicle_body.addPr_flBx {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

._grpDivFx {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Vehicle Modal */

/* Add Claim Modal */

._dualCheckr_bx {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

._clmRadios {
  padding: 0 20px 34px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

._clmsFlex {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
}

.clmDual_fx {
  flex: 0 0 calc(50% - 5px);
}

._lrl_otrBx {
  border-radius: 4px;
  border: 1px solid var(--light-gray);
  background: #fff;
  padding: 12px 10px 15px;
  cursor: pointer;
}

._lrCl_offCavBody {
  border: 1px solid var(--light-gray);
  border-top: none;
  background: #fff;
  padding: 18px 14px 30px 16px;
}

._lr_cnpCode {
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
}

._lr_cnpCode h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.28px;
  margin-bottom: 0;
}

._lrl_cnp {
  color: var(--secondary-color);
}

._lrl_code {
  opacity: 0.7;
  color: #323232;
}

._lrlRout_ls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 8px;
}

._lrHead_fsx {
  flex: 0 0 calc(50% - 68px);
}

._lrHead_fsx h3 {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 0;
  letter-spacing: 0.32px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 4px;
}

._lrs_rSide h3 {
  text-align: right;
}

._lrHead_fsx p {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  padding-left: 4px;
  margin: 0;
}

._lrl_stats {
  color: #323232;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.28px;
}

._lrCl_offCavBody {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.selectedBxs {
  border-color: var(--secondary-color) !important;
}

._clPay_flex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}

._uploadBtn {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

._uploadBtn {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--buttonBg);
  background: #fff;
  min-height: 40px;
  color: var(--buttonBg);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  min-width: 114px;
  justify-content: center;
}

/* Add Claim Modal */

/* Add Total Freight Modal */

.addTotalFreight_body {
  padding: 16px 22px 32px;
}

._qntSelector {
  margin-bottom: 20px;
}

._amtBaar {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background: #f3f3f3;
  padding: 6px 12px 6px 10px;
  margin: 10px 0 17px;
}

._amtBaar p {
  margin-bottom: 0;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

._tdsLabel {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 9px;
  display: inline-block;
}

.add_inrFr {
  padding: 6px 20px 30px;
  max-height: calc(100vh - 220px);
  overflow: auto;
}

.addFreight_body {
  position: relative;
  min-height: 333px;
}

.add_inrFr .checkBx_lv {
  gap: 25px;
}

._allDflx {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

._payMode_hed {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 4px 0 2px;
}

._payMode_hed h4,
._attatchmentBxs h4 {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  flex: 0 0 100%;
}

._baseSpan {
  border-radius: 24px;
  border: 1px solid var(--secondary-color);
  background: #f9faff;
  color: var(--black-color);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  min-width: 81px;
  min-height: 30px;
  display: inline-flex;
  align-items: center;
  padding: 0 9px;
  justify-content: center;
}

._bspDisable {
  color: #666f90;
  background-color: #eaedff;
  border-color: #eaedff;
}

._attatchmentBxs {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

._athBxs input {
  display: none;
}

._athBxs label {
  cursor: pointer;
}

._balSuccess {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-success-green);
  background: var(--light-success-green-bg);
  min-height: 40px;
  padding: 0 16px;
}

._balSuccess p {
  margin: 0;
  color: var(--success-green-color);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

/* Add Total Freight Modal */

/* Add Bank Account */

._addBank_dBx {
  border: 1px solid var(--light-gray);
  background: #fff;
  padding: 18px 14px 30px 16px;
}

._compNy_nameBx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._compNy_nameBx h4 {
  color: var(--black-color);
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.8;
  margin: 0;
}

._bnkDetail_bx {
  padding: 20px 19px;
  border-radius: 4px;
  border: 1px solid var(--light-gray);
  background: #fff;
  cursor: pointer;
}

._bnkDetail_bx p {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}

._bnkDetail_bx h4 {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}

._bnkDetail_otrBx {
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-top: 10px;
}

.addBankAcc_body {
  padding: 6px 20px 30px;
  max-height: calc(100vh - 220px);
  overflow: auto;
}

.addBankAcc_body ._dualCheckr_bx {
  flex: 0 0 calc(50% - 8px);
}

/* Add Bank Account */

/* End Trip Modal */

.endTrip_bxs {
  background: #ededed;
  padding: 18px 12px;
}

._endBr_head {
  display: flex;
  justify-content: space-between;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #e8e7e7;
  background: #fff;
  padding: 9px 10px 9px 14px;
}

._endBrc_flx {
  display: flex;
  align-items: center;
  gap: 6px;
}

._endBrc_flx h4 {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.32px;
  margin-bottom: 0;
  opacity: 0.9;
}

._endBr_head p {
  color: #323232;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.28px;
  opacity: 0.7;
  margin-bottom: 0;
}

._transDetail_bx {
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #e8e7e7;
  border-top: none;
  background: #fff;
  padding: 5px 14px 8px;
}

._transDetail_bx h4 {
  color: #003c97;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.28px;
  margin-bottom: 0;
}

._trkDetail {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  margin-bottom: 4px;
}

._trkDetail ._trkNum {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.28px;
  margin-bottom: 0;
}

._trkMark {
  color: #fff !important;
  font-size: 11.6px !important;
  font-weight: 500;
  line-height: 11.6px;
  letter-spacing: 0.252px;
  /* background-color: var(--tangerin); */
  border-radius: 2px;
  min-height: 21px;
  padding-inline: 9px;
  opacity: 1 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

._trkTyre {
  background-color: #e7e7e7;
  border-radius: 2px;
  min-height: 21px;
  padding-inline: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._trkTyre span {
  color: #323232;
  font-size: 12.6px;
  font-weight: 500;
  line-height: 12.6px;
  opacity: 0.7;
  letter-spacing: 0.252px;
}

._routDetl {
  display: flex;
  gap: 11px;
}

._rct_st {
  color: #323232;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.32px;
}

._rtCity {
  font-weight: 600;
}

._rtState {
  opacity: 0.8;
}

._rtLocates {
  position: relative;
  width: 12px;
  top: 1px;
}

._rtLocates svg {
  position: relative;
  z-index: 1;
  background: #fff;
}

._rtLocates::after {
  content: "";
  position: absolute;
  /* border: 0.5px dashed red; */
  border-left: 0.5px dashed #d9d9d9;
  height: calc(100% - 0px);
  left: 5.3px;
  z-index: 0;
}

._routDetl:first-child ._rtLocates::after {
  bottom: 0;
  height: calc(100% - 10px);
}

._routDetl:last-child ._rtLocates::after {
  top: 0;
  height: calc(100% - 10px);
}

._endTr_body {
  padding: 22px 12px 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

._endTrp_totalIng {
  border-radius: 4px;
  border: 0.904px solid var(--border-success-green);
  background: var(--light-success-green-bg);
  display: flex;
  justify-content: space-between;
  padding: 8px 14px 9px;
}

._endTrp_totalIng p {
  color: var(--success-green-color);
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

/* End Trip Modal */

/* Make Truck Available Modal */

._transDetail_bx._mkTrkDetail_bx {
  border-radius: 4px;
  padding: 12px 14px 8px;
}

._trkStatus {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.28px;
  display: flex;
  gap: 7px;
  align-items: center;
}

._trkStatus span {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

._maintainance {
  background-color: #d85555;
}

/* Make Truck Available Modal */

/* Print LR/Bilty */

.bltyPrint_body {
  display: flex;
  flex-wrap: wrap;
}

._prtRight_selctors {
  flex: 0 0 384px;
  padding: 32px 24px 26px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.bltyPrt_viewPage_bx {
  flex: 0 0 calc(100% - 384px);
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  background: #f2f2f2;
  padding: 24px 15px 16px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}

/* .bltyPrt_viewPage_bx::-webkit-scrollbar {
  display: none;
} */

.dummyPrt_page {
  background: #fff;
  height: 800px;
  margin-bottom: 12px;
}

/* PRINT LR/Bilty */

/* CREATE TEMPLATE  */

.modal._dashCommon_Modal .modal-footer.footerRight {
  justify-content: flex-end;
}

._colsListing_bx._colsList_smHeight {
  max-height: calc(100vh - 310px);
}

._tempAdd_list {
  padding: 6px 8px;
  font-size: 14px;
  position: relative;
  background-color: #f9f9fb;
  border-radius: 4px;
}

._tempAdd_list label {
  display: flex !important;
  justify-content: space-between;
  padding-left: 24px !important;
}

._tempAdd_list [type="checkbox"]:checked+label:before {
  width: 6px;
  height: 10px;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}

._tempAdd_list [type="checkbox"]:checked+label:after,
._tempAdd_list [type="checkbox"]:not(:checked)+label:after,
._tempAdd_list [type="checkbox"]:not(:checked)+label:before {
  width: 18px;
  height: 18px;
}

/* CREATE TEMPLATE  */

/* PLACE BID MODAL */

._plcMod_body {
  padding: 10px;
}

._rte_bg {
  border-radius: 5px;
  border: 1px solid #e8e7e7;
  background: #f5f5f5;
  padding: 10px 6px;
}

.__materBxs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 14px;
}

.__materBxs ._materialBxs,
.__materBxs ._payTerms_bxs {
  max-width: 100px;
  min-width: 100px;
}

.__materBxs ._whtBxs {
  max-width: 70px;
  min-width: 70px;
}

._plbBg {
  background-color: var(--common-white);
  padding: 11px 17px 10px;
  border-radius: 8px;
  margin-top: 12px;
}

._plbBg ._bidR_bxs {
  max-width: 100%;
  min-width: 100%;
}

/* PLACE BID MODAL */

/* View Business Profile Modal */

._viewProfile_modal .modal-dialog {
  max-width: 650px;
  padding: 0 12px;
}

._viewProfile_modal .modal-footer {
  display: none;
}

.__vwPrf_body {
  padding: 20px 28px;
}

._sndRequestBtn,
._removRequestBtn {
  min-height: 35px;
  border-radius: 4px;
  min-width: 197px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

._sndRequestBtn {
  background-color: var(--buttonBg);
  border: 1px solid var(--buttonBg);
  color: var(--common-white);
}

._removRequestBtn {
  background-color: var(--common-white);
  border: 1px solid var(--light-invailid);
  color: var(--light-invailid);
}

._flxBidr {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

._cntDetail_fx {
  display: flex;
  gap: 9px;
}

._cntInner_bxs {
  border-radius: 7px;
  border: 1px solid #e8eaed;
  background: var(--common-white);
  min-width: 103px;
  padding: 10px 10px 12px;
}

._cntInner_bxs span {
  color: var(--thin-black-color);
  font-size: 18px;
  font-weight: 400;
  opacity: 0.8;
  line-height: normal;
  letter-spacing: 0.36px;
  display: block;
  margin-bottom: 5px;
}

._cntInner_bxs p {
  color: var(--black-color);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  opacity: 0.9;
  margin-bottom: 0;
}

._dtOtr_txtBx {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

._dtOtr_txtBx h4 {
  color: var(--thin-black-color);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  opacity: 0.9;
  margin-bottom: 8px;
}

._dtOtr_txtBx p {
  color: var(--thin-black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  opacity: 0.9;
  letter-spacing: 0.28px;
  margin-bottom: 0;
}

._joinDa_yr {
  color: var(--thin-black-color);
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.308px;
  opacity: 0.9;
  margin-top: 12px;
  display: block;
}

._derCr_blx {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4.4px;
  margin-top: 15px;
}

._derCr_blx span {
  border-radius: 24.276px;
  border: 0.771px solid #e8eaed;
  background: var(--common-white);
  display: flex;
  align-items: center;
  min-height: 24px;
  padding: 0 10px;
}

._derCr_blx span .__insRt {
  color: var(--thin-black-color);
  font-size: 10.789px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.216px;
  opacity: 0.8;
}

._derCr_blx a {
  color: var(--buttonBg);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  opacity: 0.86;
  text-decoration: none;
  margin-left: 3px;
}

/* View Business Profile Modal */

/* Save Connection */

.modal._saveConnectModal .modal-dialog {
  max-width: 290px;
}

.modal._saveConnectModal .modal-dialog .modal-content {
  border: none;
}

.modal._saveConnectModal .modal-header {
  padding: 12px 10px 12px 18px;
}

.modal._saveConnectModal .modal-header span {
  color: var(--saphire-blue);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.modal._saveConnectModal ._submitOn_modal {
  min-height: 38px;
  border-radius: 8px;
  background-color: var(--buttonBg);
  font-weight: 500;
  line-height: 27px;
}

.modal._saveConnectModal .modal-footer {
  padding: 8px 14px;
}

._addSrc_lBx {
  padding: 8px 12px 0px;
  border-bottom: 1px solid #f2f2ff;
}

._addSrc_lBx ._inpW_search._lrl_inpSrch .simpleFilter_input {
  height: 38px;
}

._addLst_nxs {
  padding: 6px 10px;
}

._addLst_nxs button {
  border: none;
  background-color: transparent;
  color: #0049d7;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.26px;
}

._connectList_bx {
  padding: 8px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100px;
  overflow: auto;
}

._connectList_bx [type="checkbox"]:not(:checked)+label,
._connectList_bx [type="checkbox"]:checked+label {
  display: flex;
  align-items: center;
}

._connectList_bx [type="checkbox"]:not(:checked)+label span,
._connectList_bx [type="checkbox"]:checked+label span {
  color: var(--thin-black-color);
  font-size: 13.7px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.26px;
}

/* Save Connection */

/* Write Review Modal */

._wrtReview_bx {
  padding: 14px 25px 15px 25px;
}

._wrtReview_bx .__bidR_usrBx img {
  width: 61px;
  height: 61px;
}

._wrtReview_bx ._ratingBx {
  padding: 1px 8px 1px 8px;
  border-radius: 11px;
}

._wrtReview_bx ._ratingBx svg {
  font-size: 14px;
}

._wrtReview_bx ._ratingBx span {
  font-size: 15px;
}

._wrtReview_bx .tl_txt {
  font-size: 17.8px;
}

._wrtReview_bx .tsm_txt {
  font-size: 15px;
}

._rvrModal .modal-footer button {
  flex: 0 0 calc(50% - 6px);
}

._wrtReview_bx ._fstLetter {
  width: 54px;
  height: 54px;
}

/* Write Review Modal */

/* ADD PO Modal */

._addPo_mbx {
  padding: 22px 15px 25px 20px;
}

/* ADD PO Modal */

/* Add Other Document Modal */

.__mid_sm_modal .modal-dialog {
  max-width: 432px;
  padding: 0 12px;
}

.__addotr_docsBx {
  padding: 20px 18px 25px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

/* Add Other Document Modal */

/* Favourite Route Modal */

._favRoute_Modal .modal-footer {
  display: none;
}

._favRoute_Modal .modal-dialog {
  max-width: 284px;
  padding: 0 12px;
}

._favRoute_body {
  padding: 15px 18px 20px;
  min-height: 200px;
  overflow: auto;
  max-height: 400px;
}

/* Favourite Route Modal */





/* Select Route Modal */


._rtFilts {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  padding: 14px 18px 16px;
}

._rtNrm_btn {
  color: #A0A0A0;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  background: #EEE;
  line-height: 27.863px;
  border: transparent;
  min-height: 41px;
  min-width: 106px;
}

._rtNrm_btn_active {
  background: #F5FBFF;
  color: var(--theme-color);
}

._rtF_inpt {
  flex: 0 0 100%;
  margin-top: 5px;
}

._rtF_inpt ._inpW_search svg.srcIcon {
  scale: 1.3;
  left: 20px;
}

._rtF_inpt ._inpW_search._lrl_inpSrch .simpleFilter_input {
  height: 50px;
  font-size: 16px;
  padding: 3px 12px 3px 42px;
}

.__mainRt_listBx {
  background: #f5f8fD;
  height: calc(100vh - 478px);
  min-height: 300px;
  overflow: auto;
}

.__inrtList p {
  color: #85888A;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.24px;
  margin: 0;
  padding: 10px 16px;
}

.__inrtList ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.__inrtList ul ._inrt_Li {
  background-color: var(--common-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 12px 16px;
  cursor: pointer;
}

.__inrtList ul ._inrt_Li span {
  color: #121212;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}

._not__selected {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #C0C0C0;
  background: var(--common-white);
}

.__inrtList ul ._inrt_Li .__selected {
  display: none;
}

.__inrtList ul ._inrt_Li._li_active ._not__selected {
  display: none;
}

.__inrtList ul ._inrt_Li._li_active .__selected {
  display: block;
}



/* Select Route Modal */



/* Choose Truck Type */


._trckTypo_body ._headH3 {
  font-size: 16px;
}

._addAccount_modal .modal-body._trckTypo_body {
  background-color: #f5f8fd;
  padding: 10px 10px 16px;
}

._addAccount_modal .modal-body._trckTypo_body ._crtComp_modBx {
  background-color: var(--common-white);
  border-radius: 8px;
  padding: 16px 14px 25px;
}

._addAccount_modal .modal-body._trckTypo_body ._crtComp_modBx .addVehicle_body.addPr_flBx {
  padding: 0;
}

._addAccount_modal .modal-body._trckTypo_body ._crtComp_modBx .addVehicle_body.addPr_flBx .cstNew_tabs._truckSelection {
  gap: 16px 12px;
}

._addAccount_modal .modal-body._trckTypo_body ._crtComp_modBx ._truckSelection li.nav-item {
  max-width: 76px;
}


/* Choose Truck Type */





/* Add Transaction Account Modal */


.addPr_flBx._addTrns_flx .cstNew_tabs._truckSelection {
  padding: 0px;
  gap: 12px;
}

.addPr_flBx._addTrns_flx ._truckSelection li.nav-item .nav-link::before {
  height: 0;
  display: none;
}

.addPr_flBx._addTrns_flx ._truckSelection li.nav-item .nav-link {
  min-height: 76px;
  background-color: #e5eeff;
  border-color: transparent;
  gap: 8px;
  justify-content: flex-end;
}

.addPr_flBx._addTrns_flx ._truckSelection li.nav-item .nav-link.active {
  border-color: var(--theme-color);
  color: var(--black-color);
}

._addMo_deBtn {
  border-radius: 4px;
  border: 1px solid var(--secondary-color);
  background: var(--common-white);
  color: var(--buttonBg);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  min-height: 32px;
  min-width: 118px;
  padding: 2px 5px;
}


/* Add Transaction Account Modal */




/* Add Expenses Modal */


.modal._dashCommon_Modal._addExpModal .modal-footer {
  /* justify-content: flex-end; */
  flex-direction: row-reverse;
}

.__addExp_body {
  padding: 18px 0px 22px;
}

/* ._adRk_bx {
  text-align: right;
  margin-top: -16px;
} */

.__adx_more {
  font-size: 14px;
  color: var(--black-color);
  font-weight: 400;
  line-height: normal;
  border-radius: 7px;
  border: 1px solid #E8EAED;
  background: #F2F7FF;
  min-height: 27px;
  min-width: 99px;
}


.totalExp_bal {
  border: 1px solid #e8eaed;
  background: #fdfdfd;
  text-align: right;
  padding: 6px 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.totalExp_bal h4,
._pMode_exbx p {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  margin-bottom: 0;
}

.pFlex_bd {
  display: flex;
  gap: 4.5px;
  margin-top: 10px;
}

.pFlex_bd button {
  border-radius: 15.611px;
  background: #e5eeff80;
  color: #252525;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.444px;
  letter-spacing: -0.312px;
  border: none;
  min-height: 31px;
  max-width: 84px;
  width: 100%;
}

.pFlex_bd button.active {
  background-color: var(--buttonBg);
  color: var(--common-white);
}

.__addExp_fileBx input {
  display: none;
}

.__addExp_fileBx label {
  border-radius: 3.97px;
  border: 0.992px solid #bfbfbf;
  background: #f0f0f0;
  color: #717171;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  min-height: 29px;
  min-width: 129px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  cursor: pointer;
}

.__gstToggle_bx {
  padding: 0 19px;
  margin-bottom: 10px;
}

.__gstToggle_bx .anvSwitch_frm.form-check label {
  font-size: 14px;
  color: var(--black-color);
}

.__catrTb_row {
  display: flex;
  gap: 9px;
  border: 1px solid #E8EAED;
  background: #FDFDFD;
  /* border-bottom: none; */
  padding: 0 19px;
}

.__catrTb_head {
  min-height: 41px;
  align-items: center;
}

.__catrTb_body {
  padding: 8px 6px 3px 19px;
  position: relative;
  border-top: none;
  border-bottom: none;
}

.__catrTb_body:last-child {
  padding-bottom: 9px;
}

.__catrTb_row h5 {
  color: var(--black-color);
font-size: 14px;
font-weight: 400;
line-height: normal;
margin-bottom: 0;
}

.__cat_childs {
  flex: 0 0 calc(50% - 3px);
  max-width: 264px;
  width: 100%;
}

._catGry_tableBx .custom-select-container input {
  font-size: 12px;
  min-height: 38px;
}

._catGry_tableBx .custom-dropdown-menu ._anvRo_locateBx  {
  font-size: 14px;
  padding: 5px 10px;
  min-height: 34px;
}

._catGry_tableBx .anvCustom_floatInput input.form-control, 
._catGry_tableBx .anvCustom_floatInput .form-select {
  font-size: 12px;
  min-height: 38px !important;
  height: 38px !important;
}

._catGry_tableBx  .anvCustom_floatInput.form-floating label,
._catGry_tableBx .custom-select-container ._fltLabel {
  visibility: hidden;
}


._withGst_catGry .__cat_childs {
  flex: 0 0 calc(26% - 3px);
  max-width: 235px;
}

._withGst_catGry .__cat_childs:first-child {
  flex: 0 0 calc(48% - 12px);
}

._delExp {
  width: 14px;
  margin-left: -4px;
  cursor: pointer;
}

/* Add Expenses Modal */