.custom-select-container {
    position: relative;
    width: 100%;
}

.custom-select-container:after {
    content: "";
    color: rgb(0 0 0 / 60%);
    position: absolute;
    top: 26px;
    right: 17px;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-bottom: 0;
    border-left: 0.35em solid transparent;
}

.custom-select-container input {
    width: 100%;
    padding: 6px 28px 6px 12px;
    box-sizing: border-box;
    min-height: 60px;
    font-size: 15px;
    border: 1px solid #E8E7E7;
    border-radius: 4px;
    transition: all .15s;
    cursor: pointer;
}

.custom-select-container .dropSearch_bx input {
    width: 100%;
    border: 1px solid #e8eaed;
    border-radius: 3.62px;
    background-color: #ffffff;
    font-size: 15px;
    line-height: 17.58px;
    color: #000;
    min-height: 36px;
    color: #000;
    padding: 5px 10px;
    cursor: auto;
}

.custom-select-container input:focus~label span {
    color: var(--secondary-color);
    opacity: 1;
    font-weight: 500;
}

.custom-select-container ._fltLabel {
    position: absolute;
    top: -6px;
    left: 8px;
    background: #fff;
    padding: 1px 2px;
}

.custom-select-container ._fltLabel span {
    color: #000000;
    line-height: 12px;
    opacity: 0.7;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    gap: 1px;
}

.custom-select-container input:hover {
    border-color: var(--secondary-color);
}

.custom-select-container input:focus {
    outline: 2px solid var(--secondary-color);
}

.custom-select-container input:focus:hover {
    border-color: transparent;
}

.custom-select-container input:disabled {
    background-color: #fcfcfc;
}

.custom-dropdown-menu {
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 4px 0;
    top: 104%;
    left: 0;
    right: 0;
    z-index: 1000;
    /* max-height: 200px; */
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #e8eaed;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 7%);
}
.custom-dropdown-menu-account {
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 4px 0;
    top: 104%;
    left: 0;
    right: 0;
    z-index: 1000;
    /* max-height: 200px; */
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #e8eaed;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 7%);
}

.custom-dropdown-menu li {
    /* padding: 10px; */
    cursor: pointer;
}

.custom-dropdown-menu ._anvRo_locateBx {
    padding: 6px 10px;
    min-height: 36px;
}

li._anvRo_locateBx._vhlActive ._vhlNumber,
li._anvRo_locateBx._vhlActive ._vhlStatus{
    color: #26263b;
}

li._anvRo_locateBx._vhlActiv ._vhlNumber,
li._anvRo_locateBx._vhlActiv ._vhlStatus{
    color: #26263b;
}

.custom-dropdown-menu li:hover {
    /* background-color: #f8f9fa; */
}

._vhlActive{
    background-color: var(--theme-warning);
    color: #0f0e0e;
}

._vhlActiv{
    background-color: var(--light-gray);
    color: #0f0e0e;
}

._addNew_cdmBtn {
    width: calc(100% - 6px);
    /* background-color: var(--buttonBg); */
    background-color: #fff;
    border: none;
    min-height: 34px;
    color: var(--buttonBg);
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 16px;
    margin: 0 auto;
    display: block;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 7px;
}

._lineDrp {
    border-top: 1px solid var(--light-gray);
}

.available_status, .ontrip_status {
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    margin-right: 5px;
    width: 8px;
}

.available_status {
    background-color: #04d900;
}

.custom-select-container.custom-select_eror input {
    border-color: var(--invailid-color);
}

.custom-select-container.custom-select_eror input:focus {
    outline: 2px solid var(--invailid-color);
    border-color: transparent;
}

.custom-select-container.custom-select_eror input:focus~label span {
    color: var(--invailid-color);
}

/* Custom Select */
.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

/* input {
    width: 100%;
    padding-right: 35px; 
    box-sizing: border-box;
} */

.clear-icon {
    position: absolute;
    right: 40px; /* Adjust positioning as needed */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.1); /* Subtle background */
    border-radius: 50%; /* Circular background */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

.clear-icon:hover {
    background-color: rgba(0, 0, 0, 0.2); /* Darker on hover */
    /* transform: scale(1.1); Slightly larger on hover */
}

.clear-icon svg {
    width: 14px; /* Icon size */
    height: 14px; /* Icon size */
    color: black; /* Icon color */
}

/* Custom Select */
