/* width */
.datagrid-table::-webkit-scrollbar,
._colsListing_bx::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
.datagrid-table::-webkit-scrollbar-track,
._colsListing_bx::-webkit-scrollbar-track {
    background: #F7F7FE;
}

/* Handle */
.datagrid-table::-webkit-scrollbar-thumb,
._colsListing_bx::-webkit-scrollbar-thumb {
    background: #B2B0BF;
    border-radius: 10px;
}

/* Handle on hover */
.datagrid-table::-webkit-scrollbar-thumb:hover,
._colsListing_bx::-webkit-scrollbar-thumb:hover {
    background: #9c99aa;
}


.simple-table {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #e8eaed;
    border-left: 1px solid #e8eaed;
    border-right: 1px solid #e8eaed;
    border-bottom: 0;
}

._allFilts {
    /* position: sticky; */
    /* top: 109px; */
    background: #fff;
    /* z-index: 9; */
}

.simple-table td {
    padding: 8px;
    text-align: left;
    font-size: 14px;
}

.simple-table th {
    padding: 5px 6px;
    text-align: left;
    font-size: 14px;
}

.simple-table td:first-child {
    padding-left: 20px;
}

.simple-table th:first-child {
    padding-left: 15px;
}

.srNo {
    width: 52px;
}

.simple-table td p {
    margin: 0;
}

.simple-table th {
    cursor: pointer;
    background-color: #F7F7FE;
}

.simple-table thead tr {
    position: sticky;
    top: -1px;
    z-index: 1;
    box-shadow: 0px 1px 4px 0px #0000001a;
}

.simple-table tr {
    border-bottom: 1px solid #E8EAED;
}

.filterOtrs {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--light-gray);
    padding: 4px 12px;
}

._tabUr_bx {
    display: flex;
    gap: 10px;
    width: calc(100% - 420px);
    margin: -5px 0;
}

.column-toggle-dropdown-toggle {
    padding: 0 8px;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    display: flex;
    gap: 8px;
    min-height: 33px;
    align-items: center;
    border: 1px solid #e5e7eb;
    background-color: #fff;
    border-radius: 4px;
}

.column-toggle-dropdown-menu {
    display: block;
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    padding: 5px 4px;
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
}

.column-toggle-dropdown-item {
    display: flex;
}

.column-toggle-dropdown-item input {
    position: relative !important;
    left: initial !important;
    display: flex;
    /* margin-right: 5px; */
}

.extra-padding{
    padding: 0px 10px;
}

.column-toggle-dropdown-menu input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ddd;
}


.column-toggle-dropdown,
.filter-toggle-dropdown {
    position: relative;
    display: inline-block;
    /* margin-right: 10px; */
}

.column-toggle-dropdown-toggle,
.filter-toggle-dropdown-toggle {
    /* padding: 8px; */
    cursor: pointer;
}

/* .column-toggle-dropdown-menu,
.filter-toggle-dropdown-menu {
    display: block;
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
} */

.column-toggle-dropdown-item,
.filter-toggle-dropdown-item {
    display: flex;
    /* margin-bottom: 5px; */
}

.column-toggle-dropdown-item input,
.filter-toggle-dropdown-item input {
    position: relative !important;
    left: initial !important;
    display: flex;
    /* margin-right: 5px; */
}

.column-filter {
    margin-bottom: 10px;
}

.column-filter label {
    margin-right: 10px;
}

.active-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* margin-bottom: 10px; */
}

.column-filter input[type="text"] {
    position: relative !important;
    left: initial !important;
    display: flex;
    margin-right: 5px;
    padding: 5px;
    border: 1px solid #ddd;
}

.column-filter select {
    padding: 5px;
    border: 1px solid #ddd;
}

.table-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 1em; */
}

.column-toggle-dropdown,
.filter-toggle-dropdown {
    position: relative;
    display: inline-block;
}

.filter-toggle-dropdown-menu {
    /* display: none; */
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(0px, 38px);
}

.column-toggle-dropdown-menu {
    /* display: none; */
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
    z-index: 1;
    border: 1px solid var(--light-gray);
    border-radius: 4px;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate(0px, 38px);
}

.column-toggle-dropdown:hover .column-toggle-dropdown-menu,
.filter-toggle-dropdown:hover .filter-toggle-dropdown-menu {
    display: block;
}

.column-toggle-dropdown-item,
.filter-toggle-dropdown-item {
    padding: 6px 8px;
    cursor: pointer;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
}

.filter-search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
}


.column-filter-dropdown {
    position: relative;
    flex: 0 0 100%;
    /* margin-bottom: 1em; */
}

.dropbtn {
    border: 1px solid var(--light-gray);
    width: 100%;
    font-size: 13.4px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 12px;
    min-height: 45px;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
}

.dropbtn:hover {
    border-color: var(--secondary-color);
}

.dropbtn:focus {
    border-color: transparent;
    outline: 2px solid var(--secondary-color) !important;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
    z-index: 1;
    border: 1px solid var(--light-gray);
    margin-top: 8px;
}

.column-filter-dropdown .dropdown-content {
    position: absolute;
    inset: 7px auto auto 0px;
    margin: 0px;
    transform: translate(0px, 38px);
    display: block;
    border-radius: 4px;
    width: 100%;
}

.dropdown-content input,
.dropdown-content select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.active-filters .column-filter-dropdown .dropdown-content {
    /* width: 200px;
    overflow: hidden; */
}

._srcField_bx {
    padding: 0 12px;
    display: flex;
    align-items: center;
    gap: 8px;
}

._srcField_bx svg {
    width: 18px;
    height: 18px;
}

._srcField_bx input.filter-column-search-input {
    border: 0;
    font-size: 14px;
    min-height: 41px;
    padding: 0;
}

._srcField_bx input.filter-column-search-input:focus {
    outline: 0;
}

._filtList_bx {
    padding: 0px;
    border-top: 1px solid var(--light-gray);
    max-height: 230px;
    overflow: auto;
}

._filtList_bx::-webkit-scrollbar {
    width: 11px;
}

._filtList_bx {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

._filtList_bx::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}

._filtList_bx::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 1px solid var(--scrollbarBG);
}

.active-filters .column-filter-dropdown .dropdown-content .active-filter-group label {
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 6px 8px;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
}

.active-filters .column-filter-dropdown .dropdown-content .active-filter-group label input {
    position: relative;
    left: initial;
    width: initial;
}

.active-filters .column-filter-dropdown .dropdown-content .active-filter-group label:hover {
    background: #f4f4f5;
}

.active-filters .column-filter-dropdown .dropdown-content label {
    display: flex;
}

.active-filters .column-filter-dropdown .dropdown-content .filter-column-search-input {
    position: relative;
    left: initial;
    width: initial;
}

._leftFilts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}

._allFilts_fxs {
    display: flex;
    align-items: end;
    gap: 5px;
    align-items: center;
}

._tableFilter_inrFx {
    padding: 15px 22px 15px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #fff;
    border: 1px solid var(--light-gray);
    border-top: none;
}

._mainData_tableOtr {
    min-height: calc(100vh - 167px);
}

.sticky_paginAtion {
    height: 58px;
    border-top: 1px solid var(--light-gray);
    display: flex;
    background-color: #fff;
    align-items: center;
    padding: 0 25px;
    position: sticky;
    bottom: 0;
}

._tbHead_btn {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 4px 6px;
    border-radius: 4px;
}

._tbHead_btn:hover {
    background-color: #ebebfb;
}

._colsListing_bx {
    height: calc(100vh - 460px);
    overflow: auto;
    max-height: calc(100vh - 250px);
    padding: 10px 7px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.column-toggle-search {
    padding: 12px 15px 0;
    width: 100%;
}

.column-toggle-search input {
    width: 100%;
    max-width: 100%;
}

._colsListing_bx .column-toggle-dropdown-item {
    background-color: #f9f9fb;
    gap: 6px;
    margin-bottom: 4px;
}

.datagrid-table {
    overflow: auto;
    max-height: calc(100vh - 210px);
    min-height: calc(100vh - 210px);
}

._thFlx {
    display: flex;
    justify-content: space-between;
    /* cursor: grab; */
}

._thFlx svg.drager {
    opacity: 0;
}

._thFlx:hover svg.drager {
    /* opacity: 1; */
}

._tbsX {
    display: flex;
    align-items: center;
    gap: 2px;
}

.placeholder {
    background-color: #f0f0f0;
    border: 2px dashed #ccc;
    min-height: 40px;
    margin: 4px 0;
}

._dateNtime {
    display: flex;
    flex-direction: column;
}

._lgtText {
    color: var(--black-color);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
}

._tblText {
    color: var(--black-color);
    opacity: 0.8;
}

.__locationWid_bx a {
    text-decoration: none;
}

._lrNums {
    width: 100%;
    min-width: 90px;
}

._locationWid_bx {
    max-width: 131px;
    width: 100%;
}

._locationWid_bx p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

._oneLine_tx {
    min-width: 120px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

._nxRev_btn {
    border: 1px solid var(--light-gray);
    width: 25px;
    height: 25px;
    border-radius: 3px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nxtPage_btn svg {
    transform: rotate(180deg);
}

._paginateBtn {
    border: 1px solid var(--light-gray);
    min-width: 25px;
    border-radius: 3px;
    background-color: #fff;
    font-size: 14px;
}

.pagination_ {
    display: flex;
    gap: 5px;
}

._paginateBtn.active {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #fff;
}

._nxRev_btn:disabled {
    background: #ededed;
}

._nxRev_btn:disabled svg path {
    stroke: #716e6e;
}

._mainPaginate_ft {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 58px;
    border: 1px solid var(--light-gray);
    background: #FFF;
    padding: 5px 22px;
}

._rcl_bx {
    display: flex;
    align-items: center;
    gap: 20px;
}

._rcl_bx p {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}

._mngBtn {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid var(--light-gray);
    background: #FFF;
    min-height: 32px;
    padding: 2px 10px;
}

._filtrFlx {
    flex: 0 0 100%;
}

._inpW_search {
    position: relative;
    display: flex;
    align-items: center;
}

._inpW_search svg.srcIcon {
    position: absolute;
    left: 10px;
}

._inpW_search .clear-icon {
    right: 9px;
}

.simpleFilter_input {
    height: 45px;
    padding: 3px 12px 3px 31px;
    background: #fff;
    max-width: 100%;
    width: 100%;
    font-size: 13.4px;
    border: 1px solid var(--light-gray);
    border-radius: 4px;
}


.simpleDate_picker:hover,
.simpleFilter_input:hover {
    border-color: var(--secondary-color);
}

.simpleDate_picker:focus,
.simpleFilter_input:focus {
    box-shadow: none;
    border-color: transparent;
    outline: 2px solid var(--secondary-color);
}

input.simpleDate_picker {
    height: 45px;
    padding: 0 8px 0 12px;
    background: #fff url(../../assets/img/calender_icon2.svg) no-repeat scroll calc(100% - 10px) 9px;
    background-size: 18px;
    max-width: 100%;
    width: 100%;
    font-size: 13.4px;
    border: 1px solid var(--light-gray);
    border-radius: 4px;
    position: relative;
}


.simpleFilter_input::placeholder {
    color: var(--black-color);
    opacity: 0.8;
}

.simpleFilter_input::-ms-input-placeholder {
    color: var(--black-color);
}

._drop_iconBr {
    position: absolute;
    right: 10px;
}

._float_lbl {
    position: absolute;
    top: -9px;
    left: 7px;
    background: #fff;
    padding: 1px 2px;
    font-size: 10px;
}

._float_lbl span {
    color: var(--black-color);
}

.dropbtn:focus ._float_lbl span {
    color: var(--secondary-color);
    font-weight: 500;
}

.active-filter-group {
    padding-right: 10px;
}

.active-filter-group input[type="checkbox"] {
    position: relative;
    left: 0;
    width: auto;
}

.active-filter-group label._allFl_list {
    width: 100%;
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #e8eaed;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    align-items: center;
    cursor: pointer;
    transition: all .26s;
    margin: 4px 5px;
    gap: 10px;
}

.active-filter-group label._allFl_list:hover {
    background-color: #EEF3FF;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
}

._allFl_list ._tblText {
    font-size: 15px;
    line-height: 17.58px;
}

._appliedFilts_bx {
    border-radius: 3.474px;
    border: 0.868px solid #E8F2FE;
    background: #E8F2FE;
    display: flex;
    gap: 5px;
    align-items: center;
    min-height: 33px;
    padding: 0 6px 0 10px;
}

._apl_text {
    color: var(--filter-bg-color);
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    text-wrap: nowrap;
}

._resetApl_btn {
    border-radius: 2.318px;
    background: var(--filter-bg-color);
    color: #fff;
    border: none;
    font-size: 13px;
    min-height: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 6px;
}

._inpW_search._lrl_inpSrch .simpleFilter_input {
    height: 33px;
}

._customCol_body {
    background: #F5F8FD;
    display: flex;
    padding: 10px;
    gap: 12px;
    flex-direction: row-reverse;
}

._cstColDiv1,
._cstColDiv2 {
    flex: 0 0 100%;
    background-color: #fff;
}

._cstColDiv_halfRg,
._cstColDiv2_visible {
    flex: 0 0 calc(50% - 6px);
}

._cstColDiv2 {
    display: none;
}

._cstColDiv2_visible {
    display: block;
}

._clstHead {
    color: var(--black-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.9;
    margin-bottom: 0;
    padding: 12px 20px 5px;
}

._cstFull_divShow {
    flex: 0 0 100%;
}

._cstFull_divHide {
    display: none;
}

._mainData_withUp_filts .datagrid-table {
    max-height: calc(100vh - 300px);
    min-height: calc(100vh - 300px);
}

._blRaise_fits {
    padding: 14px 20px;
    max-width: 900px;
    width: 100%;
}


._minTab_ur {
    min-height: 43px;
}

._billRaise_dataTable .datagrid-table {
    max-height: calc(100vh - 300px);
    min-height: calc(100vh - 300px);
}