/* Onboarding */


.anvOnboard_bg {
    background-color: rgba(241, 247, 255, 1);
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  ._midOb_bx {
    max-width: 380px;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
  }
  
  ._innrMid_bx {
    padding: 30px 38px 0px;
  }
  
  img._logoOn_board {
    display: block;
    margin: 0 auto;
    width: 75%;
  }
  
  ._midOd_head h3 {
    color: rgba(37, 37, 37, 1);
    font-size: 20px;
    line-height: 23.44px;
    margin: 0;
    text-align: center;
  }
  
  ._midOd_head {
    margin-top: 25px;
  }
  
  ._numInput_bx {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  ._numInput_bx input {
    width: 100%;
    border: 1px solid #dfdfdf;
    font-size: 14px;
    color: #000;
    border-radius: 4px;
    padding: 8px 8px 8px 80px;
    min-height: 45px;
    position: relative;
    z-index: 9;
    background: transparent;
  }
  
  ._numInput_bx input:hover {
    border-color: var(--theme-color);
  }
  
  ._numInput_bx input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
  
  ._numInput_bx input:focus {
    outline: 2px solid var(--theme-color);
    border-color: transparent;
  }
  
  ._numInput_bx label {
    position: absolute;
    left: 13px;
    display: flex;
    align-items: center;
    gap: 9px;
    color: #000;
    font-size: 14px;
  }
  
  ._subFrm_fx {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 25px;
  }
  
  ._submitOn_board {
    background-color: var(--warn-orange);
    color: #fff;
    border: none;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    line-height: 18.75px;
    font-weight: 500;
    min-height: 45px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  ._midBx_foot {
    border-top: 1px solid #f1f7ff;
    text-align: center;
    padding: 11px 2px;
  }
  
  ._midBx_foot p {
    font-size: 10px;
    line-height: 15px;
    margin: 0;
    color: #000;
  }
  
  ._midBx_foot p a {
    text-decoration: none;
    color: var(--theme-color);
  }
  
  ._midOd_head p {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: rgba(37, 37, 37, 1);
    margin-top: 5px;
    margin-bottom: 12px;
  }
  
  span._numberOtp {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: var(--theme-color);
    gap: 4px;
    font-weight: 600;
    margin-top: 4px;
  }
  
  
  p._otpMsg {
    margin-bottom: 0;
    margin-top: 13px;
    text-align: center;
    color: #8a8a8a;
    font-size: 14px;
  }
  
  ._baseInput_bx .form-control {
    font-size: 14px;
    color: #000;
    min-height: 46px;
    padding-left: 16px;
    transition: all .1s;
  }
  
  ._baseInput_bx .form-control:hover {
    border-color: var(--theme-color);
  }
  
  ._baseInput_bx .form-control:focus {
    box-shadow: none;
    border-color: transparent;
    outline: 2px solid var(--theme-color);
  }
  
  
  
  
  
  
  
  
  
  /* Onboarding */