.anv_dateTimePicker .react-datepicker-wrapper {
  width: 100%;
}

.anv_dateTimePicker .react-datepicker-wrapper input {
  min-height: 45px !important;
  height: 45px !important;
  padding: 5px 15px !important;
  border: 1px solid var(--box-border-color);
  font-size: 15px;
  border-radius: 3px;
  margin-bottom: 0 !important;
  width: 100%;
}

.anv_dateTimePicker.anvInputError .react-datepicker-wrapper input {
  border-color: var(--invailid-color);
}

.anv_dateTimePicker .react-datepicker-wrapper input:focus {
  box-shadow: none;
  outline: 2px solid var(--secondary-color);
}

.anv_dateTimePicker .react-datepicker-wrapper input:hover {
  border-color: var(--secondary-color) !important;
}

.anv_dateTimePicker .react-datepicker-wrapper input:focus:hover {
  border-color: transparent !important;
}

.anv_dateTimePicker .react-datepicker-wrapper input:focus ~ :after {
  color: var(--secondary-color);
}

.anv_dateTimePicker.anvInputError .react-datepicker-wrapper input:focus {
  box-shadow: none;
  outline: 2px solid var(--invailid-color) !important;
  border: none;
}

.anv_dateTimePicker.anvInputError .react-datepicker-wrapper input:hover {
  border-color: var(--invailid-color) !important;
}

.anv_dateTimePicker.anvInputError .react-datepicker-wrapper input:focus:hover {
  border-color: transparent !important;
}

/* 
.anv_dateTimePicker .react-datepicker__input-container::after {
    content: "Select Date";
    position: absolute;
    display: block;
    top: -8px;
    left: 8px;
    padding: 1px 2px;
    height: auto;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: #475f7b;
    line-height: 14px;
    background-color: #fff;
} */

.anv_dateTimePicker .react-datepicker__day--selected,
.anv_dateTimePicker .react-datepicker__day--in-selecting-range,
.anv_dateTimePicker .react-datepicker__day--in-range,
.anv_dateTimePicker .react-datepicker__month-text--selected,
.anv_dateTimePicker .react-datepicker__month-text--in-selecting-range,
.anv_dateTimePicker .react-datepicker__month-text--in-range,
.anv_dateTimePicker .react-datepicker__quarter-text--selected,
.anv_dateTimePicker .react-datepicker__quarter-text--in-selecting-range,
.anv_dateTimePicker .react-datepicker__quarter-text--in-range,
.anv_dateTimePicker .react-datepicker__year-text--selected,
.anv_dateTimePicker .react-datepicker__year-text--in-selecting-range,
.anv_dateTimePicker .react-datepicker__year-text--in-range,
.anv_dateTimePicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.anv_dateTimePicker .react-datepicker__day--selected:hover,
.anv_dateTimePicker .react-datepicker__day--in-selecting-range:hover,
.anv_dateTimePicker .react-datepicker__day--in-range:hover,
.anv_dateTimePicker .react-datepicker__month-text--selected:hover,
.anv_dateTimePicker .react-datepicker__month-text--in-selecting-range:hover,
.anv_dateTimePicker .react-datepicker__month-text--in-range:hover,
.anv_dateTimePicker .react-datepicker__quarter-text--selected:hover,
.anv_dateTimePicker .react-datepicker__quarter-text--in-selecting-range:hover,
.anv_dateTimePicker .react-datepicker__quarter-text--in-range:hover,
.anv_dateTimePicker .react-datepicker__year-text--selected:hover,
.anv_dateTimePicker .react-datepicker__year-text--in-selecting-range:hover,
.anv_dateTimePicker .react-datepicker__year-text--in-range:hover,
.anv_dateTimePicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: var(--buttonBg);
}

.anv_dateTimePicker .react-datepicker-popper {
  z-index: 9;
}

/* /// */

.react-datepicker__input-container::after {
  content: attr(data-label);
  /* position: absolute; */
  display: block;
  top: -8px;
  left: 8px;
  /* padding: 1px 2px; */
  height: auto;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: #475f7b;
  line-height: 14px;
  background-color: #fff;
}


.react-datepicker__input-container {
    position: unset;
}

.react-datepicker__input-container .react-datepicker-wrapper .react-datepicker__input-container {
    position: relative;
}

.anvDate_lable {
  position: relative;
  display: block;
  z-index: 1;
}

.anvDate_lable::after {
  content: attr(data-label);
  position: absolute;
  display: block;
  top: -8px;
  left: 8px;
  width: auto;
  padding: 1px 2px;
  height: auto;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: #475f7b;
  line-height: 14px;
  background-color: #fff;
}